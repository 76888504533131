import { Alert, Box, Button, IconButton, Typography } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';

import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Modal } from '../../../../Components';
import { apiPaths } from '../../../../../constants';
import { getAuthHeaders } from '../../../../../authentication';
import { ReactNoop } from '../../../../_react';
import { Company, Survey } from '../../../../types';

type Props = {
  onClose: () => void;
  onDone: () => void;
  open: boolean;
  survey: Survey;
  company: Company;
};

const AddFileModal = (props: Props) => {
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    setUploadFile(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    if (uploadFile) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [uploadFile]);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  const removeFile = () => {
    setUploadFile(null);
  };

  const handleClose = () => {
    setDisableSubmit(true);
    setUploadFile(null);
    setUploadError(false);
    props.onClose();
  };

  const fileDisplayProperties = uploadFile
    ? { name: acceptedFiles[0].name, size: acceptedFiles[0].size }
    : { name: '', size: '' };

  const modalTitle =
    props.survey.QlikReports?.length > 0
      ? 'Reupload Final Report'
      : 'Upload Final Report';
  const modalDescription =
    props.survey.QlikReports?.length > 0
      ? 'This report has already been submitted. By reuploading this report you will replace the current in the system.'
      : 'By submitting the final report, this user will then be able to review and download the survey results.';

  const acceptedFileItem = uploadFile && (
    <Box
      sx={{
        border: '1px solid rgba(50,50,50,0.7)',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        opacity: '0.8',
        marginTop: '2vh',
      }}
    >
      <InsertDriveFileIcon
        sx={{ color: 'grey', opacity: '0.7', mx: '1.25rem' }}
      />
      <div style={{ width: '85%' }}>
        <p
          style={{
            color: 'rgba(0,0,0,0.7)',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '200px',
          }}
        >
          {fileDisplayProperties.name}
        </p>
        <p>{((fileDisplayProperties.size as number) / 1000).toFixed()} KB</p>
      </div>
      <IconButton
        disabled={disableSubmit}
        sx={{
          position: 'relative',
          right: '5px',
          top: '-1.5rem',
        }}
        onClick={removeFile}
      >
        <CloseIcon
          sx={{
            position: 'relative',
            fontSize: '1.25rem',
          }}
        />
      </IconButton>
    </Box>
  );

  const handleSubmit = async () => {
    if (uploadFile === null) return;

    const formData = new FormData();
    formData.append('file', uploadFile);

    setDisableSubmit(true);
    setUploadError(false);

    axios({
      method: 'post',
      url: apiPaths.surveys.FINAL_REPORT(
        props.survey.identifier,
        props.company.identifier,
      ),
      headers: {
        'Content-Type': uploadFile.type,
        ...getAuthHeaders().headers,
      },
      data: formData, // --> the data to be sent as the request body
    })
      .then(() => {
        setUploadFile(null);
        setUploadError(false);
        props.onDone();
      })
      .catch(() => {
        setUploadError(true);
      })
      .finally(() => {
        setDisableSubmit(false);
        props.onClose();
      });
  };

  return (
    <Modal open={props.open} onClose={handleClose}>
      <Box {...getRootProps()} sx={{ maxWidth: '600px' }}>
        <Typography variant="h2" fontSize="1.625em" fontWeight="bold">
          {modalTitle}
        </Typography>
        <Alert
          severity="info"
          sx={{
            mt: '1.5625em',
            mb: '1.6875em',
          }}
        >
          {modalDescription}
        </Alert>
        <input {...getInputProps()} />
        {uploadError === true ? (
          <Alert
            severity="error"
            sx={{
              marginBottom: '2vh',
            }}
          >
            Upload Failed, please retry
          </Alert>
        ) : (
          <ReactNoop />
        )}
        <Box
          display="flex"
          sx={{
            flexDirection: 'column',
            textAlign: 'center',
            border: '1px dashed grey',
            borderRadius: '6px',
            paddingX: '10vh',
            paddingY: '5vh',
          }}
        >
          <CloudDownloadIcon
            sx={{
              color: 'rgb(112, 114, 180)',
              height: '3rem',
              fontSize: '64px',
              textAlign: 'center',
              mx: 'auto',
            }}
          />
          <p style={{ fontFamily: 'Cairo', color: 'gray' }}>
            Drag and drop or <span className="browse-files">browse</span>
          </p>
        </Box>
      </Box>
      <span>{acceptedFileItem}</span>
      <Box display="flex" justifyContent="right">
        <Button
          disabled={disableSubmit}
          onClick={handleClose}
          sx={{ mt: '1.5em', mr: '0.625em' }}
        >
          CANCEL
        </Button>
        <Button
          sx={{ mt: '1.5em' }}
          variant="contained"
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          Submit Final Report
        </Button>
      </Box>
    </Modal>
  );
};

export default AddFileModal;
