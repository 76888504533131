import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Product, SurveyWithProduct } from '../../../../../types';
import CloseIcon from '@mui/icons-material/Close';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useForm,
} from 'react-hook-form';
import { z } from 'zod';
import { useContext, useEffect } from 'react';
import { useActor } from '@xstate/react';
import { CompanyDetailContext } from '../..';

type ConfigureSurveyModalProps = {
  open: boolean;
  onClose: () => void;
  survey: SurveyWithProduct;
};

type ProductSelectProps = {
  products: Product[];
  value: string;
  onChange: () => void;
} & ControllerRenderProps<FieldValues, string>;

const ProductSelect = ({ products, ...rest }: ProductSelectProps) => {
  return (
    <FormControl
      fullWidth
      sx={{
        '& .MuiInputBase-root': {
          height: '2.375em',
        },
      }}
    >
      <Select id="productIdentifier" {...rest}>
        {products.map((product) => (
          <MenuItem key={product.identifier} value={product.identifier}>
            {product.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const schema = z.object({
  productIdentifier: z.string(),
  type: z.enum(['COMPANY', 'INDIVIDUAL']),
  status: z.enum(['VISIBLE', 'HIDDEN']),
});

export type FormValues = z.infer<typeof schema>;

const ConfigureSurveyModal = ({
  open,
  onClose,
  survey,
}: ConfigureSurveyModalProps) => {
  const context = useContext(CompanyDetailContext);
  const [state, send] = useActor(context.companyDetailService);
  const [surveyActorState, surveyActorSend] = useActor(
    state.context.surveyActor!,
  );
  const { handleSubmit, control } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      productIdentifier: survey.product?.identifier ?? '',
      type: survey.isAuthRequired ? 'INDIVIDUAL' : 'COMPANY',
      status: survey.isActive ? 'VISIBLE' : 'HIDDEN',
    },
  });

  useEffect(() => {
    const subscription = state.context.surveyActor!.subscribe((state) => {
      if (state.matches('submit_done')) {
        send({ type: 'RELOAD_COMPANY' });
        onClose();
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyActorState]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: '1.25em',
          top: '1.0625em',
          height: '1.1em',
          width: '1.1em',
          fontSize: '1em',
        }}
      >
        <CloseIcon sx={{ height: '1em', width: '1em', fontSize: '1em' }} />
      </IconButton>
      <DialogTitle
        sx={{ fontSize: '2em', fontWeight: 700, px: '50px', pt: '47px' }}
      >
        Configure Survey
      </DialogTitle>
      <Box
        component="form"
        onSubmit={handleSubmit((data) => {
          surveyActorSend({ type: 'SUBMIT', id: survey.identifier, data });
        })}
        sx={{ px: '50px' }}
      >
        <DialogContent sx={{ px: '50px', pb: '47px' }}>
          <Typography variant="h3" fontSize="1.625em" pb="27px">
            {survey.name}
          </Typography>

          <Controller
            name="productIdentifier"
            control={control}
            render={({ field }) => (
              <ProductSelect
                products={surveyActorState.context.products}
                {...field}
              />
            )}
          />
          <Typography variant="h3" fontSize="1.25em" pt="27px">
            Survey Type
          </Typography>
          <Typography variant="body1" fontSize="0.875em" pt="16px">
            <b>Company survey</b> data is aggregated inside a single Qlik
            report, and each user is not required to log in before completing
            the survey. <b>Individual surveys</b> send users an individual Qlik
            report, and each user is required to log in before completing their
            survey.
          </Typography>
          <FormControl>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="INDIVIDUAL"
                    control={<Radio />}
                    label="Individual"
                  />
                  <FormControlLabel
                    value="COMPANY"
                    control={<Radio />}
                    label="Company"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          <Typography variant="h3" fontSize="1.25em" pt="27px">
            Status
          </Typography>
          <Typography variant="body1" fontSize="0.875em" pt="16px">
            The <b>Visible</b> status allows the survey to be seen by a company
            admin. The <b>Hidden</b> status makes the survey not visible from a
            company admin, and only accessible to an HSI admin.
          </Typography>
          <FormControl>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="VISIBLE"
                    control={<Radio />}
                    label="Visible"
                  />
                  <FormControlLabel
                    value="HIDDEN"
                    control={<Radio />}
                    label="Hidden"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Stack direction="row" sx={{ pb: '47px' }}>
            <Button onClick={handleClose} sx={{ mt: '1.5em', mr: '0.625em' }}>
              CANCEL
            </Button>
            <Button sx={{ mt: '1.5em' }} variant="contained" type="submit">
              SAVE CHANGES
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfigureSurveyModal;
