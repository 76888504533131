import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useActor, useMachine } from '@xstate/react';
import { editPartnerMachine } from './machine';
import { Partner } from '../../../../types';
import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChangeEvent, useContext, useEffect } from 'react';
import { PartnerPageContext } from '../..';
import { PhoneInput } from '../../../../common';
import { isValidPhoneNumber } from 'libphonenumber-js';
import theme from '../../../../../theme';

/* 
  validatorjs library is not working properly for phone numbers when
  you don't supply a specific locale so I just used 
  Google's libphonenumber library
*/
const schema = z.object({
  name: z.string().min(1, 'Partner name is required'),
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  emailAddress: z.string().min(1, 'Email address is required').email(),
  phoneNumber: z
    .string()
    .nullable()
    .refine(
      (val) => (val === null ? true : isValidPhoneNumber(val)),
      'Invalid phone number',
    )
    .optional()
    .or(z.literal(''))
    .transform((val) => (val ? val : null)),
  products: z
    .array(
      z.object({
        identifier: z.string(),
      }),
    )
    .min(1, 'At least one product is required'),
  productOwner: z.object({
    identifier: z.string().min(1, 'Product owner is required'),
  }),
});

export type FormValues = z.infer<typeof schema>;

type EditPartnerModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  partner: Partner;
  onDone: () => void;
  onDelete: () => void;
};

const EditPartnerModal = (props: EditPartnerModalProps) => {
  const [state, send, service] = useMachine(editPartnerMachine(props.partner));
  const partner = props.partner;
  const partnerService = useContext(PartnerPageContext);
  const [partnerCurrent] = useActor(partnerService);
  const defaultValues = {
    name: partner.name,
    firstName: partner.user.firstName,
    lastName: partner.user.lastName,
    emailAddress: partner.user.emailAddress,
    phoneNumber: partner.user.phoneNumber,
    products:
      partner.products.map((product) => ({
        identifier: product.identifier,
      })) ?? [],
    productOwner: partner.products.map((product) => ({
      identifier: product.ProductOwner.identifier,
    }))?.[0] ?? {
      identifier: '',
    },
  };
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    getFieldState,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      if (state.matches('done')) {
        props.onDone();
        props.closeModal();
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.partner]);

  const handleCheck =
    (onChange: (items: { identifier: string }[]) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      let current = getValues().products;
      if (e.target.checked) {
        onChange([...current, { identifier: e.target.value }]);
      } else {
        current = current.filter((item) => item.identifier !== e.target.value);
        onChange(current);
      }
    };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.closeModal}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <IconButton
        onClick={props.closeModal}
        sx={{
          position: 'absolute',
          right: '1.25em',
          top: '1.0625em',
          height: '1.1em',
          width: '1.1em',
          fontSize: '1em',
        }}
      >
        <CloseIcon sx={{ height: '1em', width: '1em', fontSize: '1em' }} />
      </IconButton>
      <Box
        component="form"
        onSubmit={handleSubmit((data) => {
          send({
            type: 'UPDATE_PARTNER',
            data,
          });
        })}
        sx={{
          py: ['33px', '35px', '37px'],
          px: ['35px', '37px', '39px'],
          borderRadius: '0.25rem',
          fontSize: ['12px', '14px', '16px'],
          outline: 'none',
        }}
      >
        <DialogTitle
          variant="h2"
          fontSize="1.625em"
          fontWeight="bold"
          sx={{ p: 0 }}
        >
          Edit Partner
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          {state.context.errObj && (
            <Alert
              severity="error"
              sx={{
                mt: '1.5625em',
              }}
            >
              {state.context.errObj.message}
            </Alert>
          )}
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                size="small"
                label="Name of partner*"
                sx={{ mt: '2.1875em' }}
                fullWidth
                variant="outlined"
              />
            )}
          />
          <Typography
            variant="h2"
            fontSize="1.25em"
            mt="2.375em"
            fontWeight="bold"
          >
            Partner Representative
          </Typography>
          <Box sx={{ display: { sm: 'block', md: 'flex' } }}>
            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                  size="small"
                  label="First name*"
                  sx={{ mt: '2.1875em', mr: [0, 0, '1.1875em'] }}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName?.message}
                  size="small"
                  label="Last name*"
                  sx={{ mt: '2.1875em', ml: [0, 0, '1.1875em'] }}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Controller
            control={control}
            name="emailAddress"
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.emailAddress)}
                helperText={errors.emailAddress?.message}
                size="small"
                label="Email address*"
                type="email"
                sx={{ mt: '2.1875em' }}
                fullWidth
                variant="outlined"
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <PhoneInput
                isValid={!getFieldState('phoneNumber').invalid}
                containerStyle={{
                  marginTop: '2.1875em',
                  width: '100%',
                }}
                inputStyle={{ width: '100%' }}
                hasError={getFieldState('phoneNumber').invalid}
                errorMessage={errors.phoneNumber?.message}
                {...field}
              />
            )}
          />
          <Typography
            variant="h2"
            fontSize="1.25em"
            mt="2.375em"
            mb="0.6875em"
            fontWeight="bold"
          >
            Product Owner Access{' '}
          </Typography>
          <FormGroup>
            <Grid container spacing={9.2}>
              {partnerCurrent.context.productOwners.map((po) => (
                <Grid item key={po.identifier}>
                  <FormControlLabel
                    key={po.identifier}
                    control={
                      <Controller
                        control={control}
                        name="productOwner"
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            sx={{
                              borderRadius: '3px',
                              width: '16px',
                              height: '16px',
                              m: '9px 9px 9px 15px',
                              '&.Mui-disabled': {
                                color: 'rgba(0, 0, 0, 0)',
                                background: 'rgba(0, 0, 0, 0.6)',
                              },
                            }}
                            value={po.identifier}
                            checked={
                              po.identifier ===
                              getValues().productOwner.identifier
                            }
                            onChange={(e) => {
                              const checked = e.target.checked;
                              if (
                                checked &&
                                po.identifier !==
                                  getValues().productOwner.identifier
                              ) {
                                setValue('productOwner', {
                                  identifier: po.identifier,
                                });
                                setValue('products', []);
                              }

                              if (!checked) {
                                setValue('productOwner', {
                                  identifier: '',
                                });
                                setValue('products', []);
                              }
                            }}
                          />
                        )}
                      />
                    }
                    label={po.name}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
          <FormHelperText
            error={!!errors.productOwner?.identifier}
            sx={{ mb: '1.2em', ml: '1em' }}
          >
            {errors.productOwner?.identifier?.message}
          </FormHelperText>
          <Typography
            variant="h2"
            fontSize="1.25em"
            mt=".3em"
            mb="0.6875em"
            fontWeight="bold"
          >
            Product Type Access
          </Typography>
          <FormGroup sx={{ display: 'flex', height: '8em' }}>
            {partnerCurrent.context.products.map((product) => (
              <FormControlLabel
                key={product.identifier}
                control={
                  <Controller
                    control={control}
                    name="products"
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        sx={{
                          borderRadius: '3px',
                          width: '16px',
                          height: '16px',
                          m: '9px 9px 9px 15px',
                          '&.Mui-disabled': {
                            color: 'rgba(0, 0, 0, 0)',
                            background: 'rgba(0, 0, 0, 0.6)',
                          },
                        }}
                        value={product.identifier}
                        onChange={handleCheck(field.onChange)}
                        checked={
                          !!getValues()?.products?.find(
                            (prod) => prod.identifier === product.identifier,
                          )
                        }
                        disabled={
                          !getValues().productOwner ||
                          getValues().productOwner.identifier !==
                            product.ProductOwner.identifier
                        }
                      />
                    )}
                  />
                }
                label={product.name}
              />
            ))}
          </FormGroup>
          <FormHelperText
            error={!!errors.products}
            sx={{ mb: '2em', ml: '1em' }}
          >
            {errors.products?.message}
          </FormHelperText>
        </DialogContent>
        <Divider />
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Box display="flex" justifyContent="left">
            <Button
              onClick={props.onDelete}
              sx={{
                mt: '1.5em',
                mr: '0.625em',
                px: '1.5em',
                border: '1px solid',
                borderColor: theme.palette.error.main,
                color: theme.palette.error.main,
              }}
            >
              DELETE
            </Button>
          </Box>
          <Box display="flex" justifyContent="right">
            <Button
              onClick={props.closeModal}
              sx={{ mt: '1.5em', mr: '0.625em' }}
            >
              CANCEL
            </Button>
            <Button sx={{ mt: '1.5em' }} variant="contained" type="submit">
              SAVE CHANGES
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditPartnerModal;
