import { AppBar, Box, Stack, Typography } from '@mui/material';
import { Sidebar, productSelectMachine, formStateMachine } from './Sidebar';
import Content from './Content';
import {
  Navigate,
  Route,
  RouteProps,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Roles, routePaths } from '../../constants';
import { useJwtClaims } from '../hooks';
import CompanyDetail from './Admin/CompanyDetail';
import CompanyLoaded from './Admin/CompanyDetail/CompanyLoaded';
import CompanyTable from './Admin/CompanyTable';
import PartnerPage from './Partner';
import { PartnerList, PartnerProfile } from './Partner/components';
import PartnerDetails from './Partner/components/PartnerProfile/PartnerDetails';
import ProductOwnerPage from './ProductOwner';
import ResourcePage from './Resource';
import SurveyPage from './Survey';
import { SurveyList } from './Survey/components';
import UserProfilePage from './User/Profile';
import Companies from './Admin';
import { createContext, useMemo } from 'react';
import { InterpreterFrom } from 'xstate';
import { useInterpret } from '@xstate/react';
import { profileMachine } from './User/machine';

export const ProductSelectContext = createContext(
  {} as InterpreterFrom<typeof productSelectMachine>,
);
export const FormStateContext = createContext(
  {} as InterpreterFrom<typeof formStateMachine>,
);
export const UserProfileContext = createContext(
  {} as InterpreterFrom<typeof profileMachine>,
);

type AdminRouteProps = RouteProps & {
  childRoutes?: ReadonlyArray<AdminRouteProps>;
};

const MainLayout = () => {
  const { pathname } = useLocation();
  const claims = useJwtClaims();
  const isDefaultPage = useMemo(() => {
    const roleDefaultPage: Record<Roles, string> = {
      [Roles.GLOBAL_ADMIN]: routePaths.surveys.INDEX,
      [Roles.COMPANY_ADMIN]: routePaths.companies.TO_PROFILE(
        claims?.company_identifier as string,
      ),
      [Roles.PARTNER]: routePaths.surveys.INDEX,
      [Roles.PRODUCT_OWNER]: routePaths.surveys.INDEX,
      [Roles.SURVEY_USER]: '',
    };

    return pathname === roleDefaultPage[claims.role as Roles];
  }, [pathname, claims]);
  const appBarHeight = isDefaultPage ? '15.625em' : '3.75rem';
  const productSelectService = useInterpret(
    productSelectMachine(claims?.company_identifier as string),
  );
  const formStateService = useInterpret(formStateMachine);
  const userProfileService = useInterpret(profileMachine);
  const roleRoutes: Record<Roles, ReadonlyArray<AdminRouteProps>> = {
    [Roles.GLOBAL_ADMIN]: [
      {
        path: routePaths.COMPANIES,
        element: <Companies />,
        childRoutes: [
          {
            path: 'all',
            element: <CompanyTable />,
          },
          {
            path: ':identifier',
            element: <CompanyDetail />,
            childRoutes: [
              {
                index: true,
                element: <CompanyLoaded />,
              },
              {
                path: 'history',
                element: <CompanyLoaded inactive />,
              },
            ],
          },
        ],
      },
      {
        path: routePaths.PROFILE,
        element: <UserProfilePage />,
      },
      {
        path: routePaths.RESOURCES,
        element: <ResourcePage />,
      },
      {
        path: routePaths.PARTNERS.INDEX,
        element: <PartnerPage />,
        childRoutes: [
          {
            index: true,
            element: <PartnerList />,
          },
          {
            path: routePaths.PARTNERS.PROFILE,
            element: <PartnerProfile />,
            childRoutes: [
              {
                index: true,
                element: <PartnerDetails />,
              },
            ],
          },
        ],
      },
      {
        path: routePaths.surveys.INDEX,
        element: <SurveyPage />,
        childRoutes: [
          {
            index: true,
            element: <SurveyList />,
          },
        ],
      },
      {
        path: routePaths.PRODUCT_OWNERS,
        element: <ProductOwnerPage />,
      },
      {
        path: '*',
        element: <Navigate replace to={routePaths.surveys.INDEX} />,
      },
    ],
    [Roles.COMPANY_ADMIN]: [
      {
        path: routePaths.PROFILE,
        element: <UserProfilePage />,
      },
      {
        path: routePaths.RESOURCES,
        element: <ResourcePage />,
      },
      {
        path: routePaths.COMPANIES,
        element: <Companies />,
        childRoutes: [
          {
            path: 'all',
            element: <CompanyTable />,
          },
          {
            path: ':identifier',
            element: <CompanyDetail />,
            childRoutes: [
              {
                index: true,
                element: <CompanyLoaded />,
              },
              {
                path: 'history',
                element: <CompanyLoaded inactive />,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: (
          <Navigate
            replace
            to={routePaths.companies.TO_PROFILE(
              claims?.company_identifier as string,
            )}
          />
        ),
      },
    ],
    [Roles.PARTNER]: [
      {
        path: routePaths.surveys.INDEX,
        element: <SurveyPage />,
        childRoutes: [
          {
            index: true,
            element: <SurveyList />,
          },
        ],
      },
      {
        path: routePaths.COMPANIES,
        element: <Companies />,
        childRoutes: [
          {
            path: 'all',
            element: <CompanyTable />,
          },
          {
            path: ':identifier',
            element: <CompanyDetail />,
            childRoutes: [
              {
                index: true,
                element: <CompanyLoaded />,
              },
              {
                path: 'history',
                element: <CompanyLoaded inactive />,
              },
            ],
          },
        ],
      },
      {
        path: routePaths.RESOURCES,
        element: <ResourcePage />,
      },
      {
        path: routePaths.PROFILE,
        element: <UserProfilePage />,
      },
      {
        path: '*',
        element: <Navigate replace to={routePaths.surveys.INDEX} />,
      },
    ],
    [Roles.PRODUCT_OWNER]: [
      {
        path: routePaths.surveys.INDEX,
        element: <SurveyPage />,
        childRoutes: [
          {
            index: true,
            element: <SurveyList />,
          },
        ],
      },
      {
        path: routePaths.PARTNERS.INDEX,
        element: <PartnerPage />,
        childRoutes: [
          {
            index: true,
            element: <PartnerList />,
          },
          {
            path: routePaths.PARTNERS.PROFILE,
            element: <PartnerProfile />,
            childRoutes: [
              {
                index: true,
                element: <PartnerDetails />,
              },
            ],
          },
        ],
      },
      {
        path: routePaths.COMPANIES,
        element: <Companies />,
        childRoutes: [
          {
            path: 'all',
            element: <CompanyTable />,
          },
          {
            path: ':identifier',
            element: <CompanyDetail />,
            childRoutes: [
              {
                index: true,
                element: <CompanyLoaded />,
              },
              {
                path: 'history',
                element: <CompanyLoaded inactive />,
              },
            ],
          },
        ],
      },
      {
        path: routePaths.RESOURCES,
        element: <ResourcePage />,
      },
      {
        path: routePaths.PROFILE,
        element: <UserProfilePage />,
      },
      {
        path: '*',
        element: <Navigate replace to={routePaths.surveys.INDEX} />,
      },
    ],
    [Roles.SURVEY_USER]: [],
  } as const;

  const renderRoutes = (role: Roles) => {
    const routes = roleRoutes[role];

    const buildRoutes = (routes: ReadonlyArray<AdminRouteProps>) => (
      <>
        {routes.map(({ childRoutes, index, ...props }, idx) => {
          return index ? (
            <Route key={idx} index element={props.element} />
          ) : (
            <Route key={idx} {...props}>
              {childRoutes && buildRoutes(childRoutes)}
            </Route>
          );
        })}
      </>
    );

    return buildRoutes(routes);
  };

  return (
    <UserProfileContext.Provider value={userProfileService}>
      <ProductSelectContext.Provider value={productSelectService}>
        <FormStateContext.Provider value={formStateService}>
          <Box
            sx={{
              backgroundColor: '#E3E3E3',
              fontSize: ['12px', '14px', '16px'],
            }}
          >
            <AppBar
              position={isDefaultPage ? 'relative' : 'fixed'}
              sx={{
                display: 'inline-block',
                width: '100%',
                height: { appBarHeight },
                zIndex: 1,
              }}
            >
              <Box
                width="100%"
                height={appBarHeight}
                sx={{
                  backgroundImage: isDefaultPage
                    ? 'url(/banner.png)'
                    : 'linear-gradient(to right, rgba(90, 106, 190, 0.7), rgba(109, 101, 170, 0.7))',
                  backgroundSize: 'cover',
                }}
              >
                {isDefaultPage && (
                  <Stack
                    height={appBarHeight}
                    alignItems="center"
                    ml="250px"
                    justifyContent="center"
                    spacing={4}
                  >
                    <img
                      src="/hsi-logo-white-large.png"
                      alt="HSI logo white"
                      height="77px"
                      width="232px"
                      style={{ objectFit: 'contain' }}
                    />
                    <Typography
                      variant="h1"
                      color="white"
                      fontSize="42px"
                      fontWeight="bold"
                    >
                      Welcome to Health & Safety Index
                    </Typography>
                  </Stack>
                )}
              </Box>
            </AppBar>
            <Box display="flex">
              <Sidebar drawerOpen={true} setDrawerOpen={() => true} />
              <Content open={true}>
                <Routes>{renderRoutes(claims.role as Roles)}</Routes>
              </Content>
            </Box>
          </Box>
        </FormStateContext.Provider>
      </ProductSelectContext.Provider>
    </UserProfileContext.Provider>
  );
};

export default MainLayout;
