import axios from 'axios';
import * as constants from '../../../../../constants';
import { DoneInvokeEvent, createMachine, assign, MachineConfig } from 'xstate';
import { getAuthHeaders } from '../../../../../authentication';
import { Company } from '../../../../types';

export type ContextT = {
  identifier: string;
  companies: Company[];
  size: number;
  from: number;
  count: number;
  search: string;
  currentPage: number;
  rowsPerPage: number;
};
type GetCompaniesEventT = DoneInvokeEvent<{
  count: number;
  companies: Company[];
}>;

type EnterSearchEventT = {
  type: 'ENTER_SEARCH';
  data: string;
};
type ChangeCurrentPageT = {
  type: 'CHANGE_CURRENT_PAGE';
  data: number;
};
type ChangeRowsPerPageT = {
  type: 'CHANGE_ROWS_PER_PAGE';
  data: number;
};

type EventT =
  | GetCompaniesEventT
  | EnterSearchEventT
  | ChangeCurrentPageT
  | ChangeRowsPerPageT;

export const surveyCompaniesMachine = (identifier: string) =>
  createMachine<ContextT, EventT>(
    {
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: `surveyCompaniesMachine-${identifier}`,
      context: {
        identifier,
        companies: [],
        size: 0,
        from: 0,
        count: 0,
        search: '',
        rowsPerPage: 10,
        currentPage: 0,
      },
      initial: 'load_companies',
      states: {
        load_companies: {
          invoke: {
            src: 'getCompanies',
            onDone: {
              target: 'load_companies_done',
              actions: 'setCompanies',
            },
          },
        },
        load_companies_done: {
          on: {
            ENTER_SEARCH: {
              target: 'load_companies',
              actions: 'updateSearch',
            },
            CHANGE_CURRENT_PAGE: {
              target: 'load_companies',
              actions: 'setCurrentPage',
            },
            CHANGE_ROWS_PER_PAGE: {
              target: 'load_companies',
              actions: 'updateRowsPerPage',
            },
          },
        },
        load_companies_error: {},
      },
    },
    {
      services: {
        getCompanies: async (context: ContextT, _: EventT) => {
          const skip = context.currentPage * context.rowsPerPage;
          const result = await axios.get(
            constants.apiPaths.surveys.GET_COMPANIES(
              context.identifier,
              skip,
              context.rowsPerPage,
            ),
            {
              ...getAuthHeaders(),
              params: {
                term: context.search,
              },
            },
          );

          return result.data;
        },
      },
      actions: {
        setCompanies: assign((_, event: EventT) => {
          const {
            data: { count, companies },
          } = event as GetCompaniesEventT;
          return {
            companies,
            count,
          };
        }),
        updateSearch: assign((_, event: EventT) => {
          return {
            search: (event as EnterSearchEventT).data,
          };
        }),
        setCurrentPage: assign((_, event: EventT) => {
          return {
            currentPage: (event as ChangeCurrentPageT).data,
          };
        }),
        updateRowsPerPage: assign((_, event: EventT) => {
          return {
            rowsPerPage: (event as ChangeRowsPerPageT).data,
            currentPage: 0,
          };
        }),
      },
    },
  );

export type SurveyCompaniesMachine = MachineConfig<ContextT, any, EventT>;
