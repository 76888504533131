import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import axios, { AxiosError } from 'axios';
import * as constants from '../../../../constants';
import { getAuthHeaders } from '../../../../authentication';
import { FormValues } from '.';
import { HttpException, Nullable } from '../../../types';

export type ContextT = FormValues & {
  errObj: Nullable<HttpException>;
};

type AddCompanyT = {
  type: 'ADD_COMPANY';
  data: FormValues;
};
type AddCompanyErrorT = DoneInvokeEvent<HttpException>;

type EventT = AddCompanyT | AddCompanyErrorT;

export const addCompanyMachine = createMachine<ContextT, EventT>(
  {
    predictableActionArguments: true,
    initial: 'main',
    states: {
      main: {
        on: {
          ADD_COMPANY: {
            target: 'start',
          },
        },
      },
      start: {
        invoke: {
          src: 'addCompany',
          onDone: {
            target: 'done',
          },
          onError: {
            target: 'main',
            actions: 'setError',
          },
        },
      },
      done: {
        after: {
          0: { target: 'main' },
        },
      },
    },
  },
  {
    services: {
      addCompany: async (_: ContextT, e: EventT) => {
        try {
          const formData = (e as AddCompanyT).data;
          const result = await axios.post(
            constants.apiPaths.companies.CREATE(),
            {
              name: formData.name,
              primaryContact: {
                firstName: formData.firstName,
                lastName: formData.lastName,
                emailAddress: formData.emailAddress,
                phoneNumber: formData.phoneNumber,
              },
              products: formData.products,
            },
            getAuthHeaders(),
          );

          return result.data;
        } catch (error) {
          throw (error as AxiosError).response?.data;
        }
      },
    },
    actions: {
      setError: assign((_, e: EventT) => ({
        errObj: (e as AddCompanyErrorT).data,
      })),
    },
  },
);
