import React, { useContext } from 'react';
import {
  Typography,
  Box,
  Divider,
  Paper,
  Button,
  Snackbar,
  Alert,
  Stack,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from '@xstate/react';
import EditCompanyModal from '../../EditCompanyModal';
import DeleteCompanyModal from '../../DeleteCompanyModal';
import { useNavigate } from 'react-router-dom';
import { Roles, apiPaths, routePaths } from '../../../../../constants';
import SurveyTable from './SurveyTable';
import axios from 'axios';
import { CompanyDetailContext } from '..';
import ResetPasswordModal from './ResetPasswordModal';
import { useJwtClaims } from '../../../../hooks';

type CompanyLoadedProps = {
  inactive?: boolean;
};

export const CompanyLoaded = ({ inactive }: CompanyLoadedProps) => {
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] =
    React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [passwordResetOpen, setPasswordResetOpen] =
    React.useState<boolean>(false);
  const claims = useJwtClaims();

  const { companyDetailService: service } = useContext(CompanyDetailContext);
  const _activeCompany = useSelector(service, (state) => state.context.company);

  const resetPassword = () =>
    axios
      .post(apiPaths.auth.UPDATE_PASSWORD(), {
        email: _activeCompany?.primaryContact.emailAddress,
      })
      .then(() => setPasswordResetOpen(true));

  if (_activeCompany === undefined) return <></>;

  const GlobalAdminHeader = () => (
    <>
      <Snackbar
        open={passwordResetOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setPasswordResetOpen(false)}
      >
        <Alert
          onClose={() => setPasswordResetOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          An email with instructions has been sent to reset password.
        </Alert>
      </Snackbar>
      <EditCompanyModal
        isOpen={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
        company={_activeCompany}
        onDone={() => {
          service.send({ type: 'RELOAD_COMPANY' });
        }}
      />
      <ResetPasswordModal
        isOpen={resetPasswordModalOpen}
        closeModal={() => setResetPasswordModalOpen(false)}
        company={_activeCompany}
        onConfirm={() => resetPassword()}
      />
      <DeleteCompanyModal
        isOpen={deleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        company={_activeCompany}
        onDone={() => {
          navigate(routePaths.companies.ALL);
        }}
      />
      <Box
        sx={{
          display: [, , 'flex'],
          justifyContent: [, , 'space-between'],
          flexWrap: 'wrap',
        }}
      >
        <Box mt="0.5625rem">
          <Typography variant="h1" fontSize="2em" fontWeight="bold" noWrap>
            {_activeCompany.name}
          </Typography>
        </Box>
        {!inactive && (
          <Box
            mt="0.5625rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              onClick={() => setEditModalOpen(true)}
            >
              EDIT
            </Button>
            <Button
              startIcon={<LockIcon />}
              sx={{ ml: '1.0625rem' }}
              variant="contained"
              onClick={() => setResetPasswordModalOpen(true)}
            >
              RESET PASSWORD
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              sx={{ ml: '1.0625rem' }}
              variant="contained"
              color="error"
              onClick={() => setDeleteModalOpen(true)}
            >
              DELETE
            </Button>
          </Box>
        )}
      </Box>
      {!inactive && (
        <Paper
          elevation={3}
          sx={{
            mt: '1.4375rem',
            px: '3.125em',
            py: '1.0625em',
          }}
        >
          <Stack direction="row" spacing={4}>
            <Typography variant="h2" fontSize="1.5em" fontWeight="bold" py={1}>
              Company Info
            </Typography>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box py={1}>
              <Typography variant="h3" fontSize="0.875em" fontWeight="bold">
                Representative Name
              </Typography>
              <Typography fontSize="0.875em" data-testid="user-fullname">
                {`${_activeCompany.primaryContact.firstName} ${_activeCompany.primaryContact.lastName}`}
              </Typography>
            </Box>
            <Box py={1}>
              <Typography variant="h3" fontSize="0.875em" fontWeight="bold">
                Email Address
              </Typography>
              <Typography
                fontSize="0.875em"
                sx={{
                  maxWidth: '200px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {_activeCompany.primaryContact.emailAddress}
              </Typography>
            </Box>
            <Box py={1}>
              <Typography variant="h3" fontSize="0.875em" fontWeight="bold">
                Phone Number
              </Typography>
              <Typography fontSize="0.875em">
                {_activeCompany.primaryContact.phoneNumber ?? '-'}
              </Typography>
            </Box>
          </Stack>
        </Paper>
      )}
    </>
  );

  const CompanyAdminHeader = () => (
    <Box mt="0.5625rem">
      <Typography variant="h1" fontSize="2em" fontWeight="bold" noWrap>
        Surveys
      </Typography>
    </Box>
  );

  const DefaultHeader = () => (
    <>
      <Snackbar
        open={passwordResetOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setPasswordResetOpen(false)}
      >
        <Alert
          onClose={() => setPasswordResetOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          An email with instructions has been sent to reset password.
        </Alert>
      </Snackbar>
      <EditCompanyModal
        isOpen={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
        company={_activeCompany}
        onDone={() => {
          service.send({ type: 'RELOAD_COMPANY' });
        }}
      />
      <ResetPasswordModal
        isOpen={resetPasswordModalOpen}
        closeModal={() => setResetPasswordModalOpen(false)}
        company={_activeCompany}
        onConfirm={() => resetPassword()}
      />
      <DeleteCompanyModal
        isOpen={deleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        company={_activeCompany}
        onDone={() => {
          navigate(routePaths.companies.ALL);
        }}
      />
      <Box
        sx={{
          display: [, , 'flex'],
          justifyContent: [, , 'space-between'],
          flexWrap: 'wrap',
        }}
      >
        <Box mt="0.5625rem">
          <Typography variant="h1" fontSize="2em" fontWeight="bold" noWrap>
            {_activeCompany.name}
          </Typography>
        </Box>
      </Box>
      {!inactive && (
        <Paper
          elevation={3}
          sx={{
            mt: '1.4375rem',
            px: '3.125em',
            py: '1.0625em',
          }}
        >
          <Stack direction="row" spacing={4}>
            <Typography variant="h2" fontSize="1.5em" fontWeight="bold" py={1}>
              Company Info
            </Typography>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box py={1}>
              <Typography variant="h3" fontSize="0.875em" fontWeight="bold">
                Representative Name
              </Typography>
              <Typography fontSize="0.875em" data-testid="user-fullname">
                {`${_activeCompany.primaryContact.firstName} ${_activeCompany.primaryContact.lastName}`}
              </Typography>
            </Box>
            <Box py={1}>
              <Typography variant="h3" fontSize="0.875em" fontWeight="bold">
                Email Address
              </Typography>
              <Typography
                fontSize="0.875em"
                sx={{
                  maxWidth: '200px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {_activeCompany.primaryContact.emailAddress}
              </Typography>
            </Box>
            <Box py={1}>
              <Typography variant="h3" fontSize="0.875em" fontWeight="bold">
                Phone Number
              </Typography>
              <Typography fontSize="0.875em">
                {_activeCompany.primaryContact.phoneNumber ?? '-'}
              </Typography>
            </Box>
          </Stack>
        </Paper>
      )}
    </>
  );

  const tableHeader = () => {
    switch (claims.role) {
      case Roles.COMPANY_ADMIN:
        return <CompanyAdminHeader />;
      case Roles.GLOBAL_ADMIN:
        return <GlobalAdminHeader />;
      default:
        return <DefaultHeader />;
    }
  };

  return (
    <>
      {tableHeader()}
      <SurveyTable company={_activeCompany} inactive={Boolean(inactive)} />
    </>
  );
};

export default CompanyLoaded;
