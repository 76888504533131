import { useMemo } from 'react';
import useLocalStorage from './useLocalStorage';
import * as jose from 'jose';
import { TOKEN_STORAGE_KEY } from '../../constants';

const useJwtClaims = () => {
  const [value] = useLocalStorage(TOKEN_STORAGE_KEY);

  const decodedValue = useMemo(() => {
    return jose.decodeJwt(value);
  }, [value]);

  return decodedValue;
};

export default useJwtClaims;
