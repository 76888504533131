import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import * as F from 'fp-ts/lib/function';
import axios from 'axios';
import { Lens } from 'monocle-ts';

import * as constants from '../../../constants';

export type ContextT = {
  password: string;
  token: string;
};

const lens = Lens.fromPath<ContextT>();

type AddDataE = {
  type: 'ADD_DATA';
  data: string;
};
type SubmitData = {
  type: 'SUBMIT';
};
type Focus = {
  type: 'FOCUS';
};

type EventsT = AddDataE | SubmitData | Focus;

const update_password = (c: ContextT) =>
  axios.put(constants.apiPaths.auth.UPDATE_PASSWORD(), c);

const updatePasswordInContext = assign((c: ContextT, e: AddDataE) =>
  F.pipe(c, lens(['password']).set(e.data)),
);

export const resetPasswordMachine = (token: string) =>
  createMachine<ContextT, EventsT>({
    predictableActionArguments: true,
    id: 'passwordResetMachine',
    context: {
      password: '',
      token,
    },
    initial: 'main',
    states: {
      main: {
        on: {
          ADD_DATA: {
            actions: [updatePasswordInContext],
          },
          SUBMIT: {
            target: 'start',
          },
        },
      },
      start: {
        invoke: {
          src: update_password,
          onDone: {
            target: 'success',
          },
          onError: {
            target: 'error',
          },
        },
      },
      success: {},
      error: {
        on: {
          FOCUS: {
            target: 'main',
          },
        },
      },
    },
  });

export default resetPasswordMachine;
