import { createMachine } from 'xstate';
import axios from 'axios';
import * as constants from '../../../../../constants';
import { getAuthHeaders } from '../../../../../authentication';
import { Partner } from '../../../../types';

export type ContextT = {
  partner: Partner;
};

type ConfirmDeletePartnerE = {
  type: 'CONFIRM_DELETE_PARTNER';
};
type EventE = ConfirmDeletePartnerE;

const delete_partner = (c: ContextT, e: EventE) =>
  axios.delete(
    constants.apiPaths.partners.PARTNER(c.partner.identifier),
    getAuthHeaders(),
  );

export const deletePartnerMachine = (partner: Partner) =>
  createMachine<ContextT, EventE>({
    predictableActionArguments: true,
    id: `deleteMachine-${partner.identifier}`,
    context: {
      partner: partner,
    },
    initial: 'main',
    states: {
      main: {
        on: {
          CONFIRM_DELETE_PARTNER: {
            target: 'start',
          },
        },
      },
      start: {
        invoke: {
          src: delete_partner,
          onDone: {
            target: 'done',
          },
          onError: {
            target: 'error',
          },
        },
      },
      done: {
        type: 'final',
      },
      error: {},
    },
  });
