import React, { useContext } from 'react';
import {
  Box,
  Divider,
  Drawer,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import QuizIcon from '@mui/icons-material/Quiz';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderIcon from '@mui/icons-material/Folder';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { AuthenticationContext } from '../..';
import { useActor } from '@xstate/react';
import { useNavigate } from 'react-router-dom';
import { Roles, routePaths } from '../../../constants';
import { useJwtClaims } from '../../hooks';
import { Product } from '../../types';
import { titleCase } from '../../helpers/format';
import { FormStateContext, ProductSelectContext, UserProfileContext } from '..';

type ProductSelectProps = {
  selectedProductId: string;
  products: Product[];
  onChange: (data: string) => void;
};

const ProductSelect = ({
  products,
  selectedProductId,
  onChange,
}: ProductSelectProps) => {
  return (
    <FormControl
      sx={{
        '& .MuiInputBase-root': {
          height: '2.375em',
        },
        width: '80%',
        mb: '1.5rem',
      }}
    >
      <Select
        id="product"
        value={selectedProductId}
        onChange={(e) => onChange(e.target.value)}
      >
        {products.map((product) => (
          <MenuItem key={product.identifier} value={product.identifier}>
            {product.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

type SidebarItemType = {
  menuItem: {
    name: string;
    icon: JSX.Element;
    href: string;
  };
};

const SidebarItem = (props: SidebarItemType) => {
  const service = useContext(FormStateContext);
  const [current, send] = useActor(service);
  const navigate = useNavigate();

  const handleNavigation = (url: string) => {
    if (current.matches('is_editing')) {
      send({ type: 'SET_CONFIRMING', data: url });
      return;
    }

    return navigate(url);
  };

  return (
    <ListItem>
      <ListItemButton onClick={() => handleNavigation(props.menuItem.href)}>
        <ListItemIcon sx={{ minWidth: '3.5em' }}>
          {props.menuItem.icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            '& > span': {
              fontSize: '1em',
            },
          }}
        >
          {props.menuItem.name}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

type Props = {
  drawerOpen: boolean;
  setDrawerOpen: () => void;
};

const Sidebar = (props: Props) => {
  const services = useContext(AuthenticationContext);
  const [, send] = useActor(services.authenticationService);
  const navigate = useNavigate();
  const claims = useJwtClaims();
  const service = useContext(ProductSelectContext);
  const userProfileService = useContext(UserProfileContext);
  const [productSelectState, productSelectSend] = useActor(service);
  const [userProfileState] = useActor(userProfileService);
  const fullName = `${userProfileState.context.firstName} ${userProfileState.context.lastName}`;
  const initials = fullName
    .match(/\b\w/g)
    ?.reduce((result, current) => result + current.toUpperCase(), '');

  const role =
    claims.role === Roles.GLOBAL_ADMIN
      ? 'HSI Admin'
      : titleCase((claims?.role as string)?.replace('_', ' '));

  const globalAdminMenu = [
    {
      name: 'Surveys',
      icon: <QuizIcon />,
      href: routePaths.surveys.INDEX,
    },
    {
      name: 'Companies',
      icon: <BusinessIcon />,
      href: routePaths.companies.ALL,
    },
    {
      name: 'Partners',
      icon: <GroupsIcon />,
      href: routePaths.PARTNERS.INDEX,
    },
    {
      name: 'Product Owners',
      icon: <AssignmentIcon />,
      href: routePaths.PRODUCT_OWNERS,
    },
    {
      name: 'Resources',
      icon: <FolderIcon />,
      href: routePaths.RESOURCES,
    },
    {
      name: 'My Profile',
      icon: <PersonIcon />,
      href: routePaths.PROFILE,
    },
  ];
  const companyAdminMenu = [
    {
      name: 'Surveys',
      icon: <BusinessIcon />,
      href: routePaths.companies.TO_PROFILE(
        claims?.company_identifier as string,
      ),
    },
    {
      name: 'Resources',
      icon: <FolderIcon />,
      href: routePaths.RESOURCES,
    },
    {
      name: 'My Profile',
      icon: <PersonIcon />,
      href: routePaths.PROFILE,
    },
  ];
  const productOwnerMenu = [
    {
      name: 'Partners',
      icon: <GroupsIcon />,
      href: routePaths.PARTNERS.INDEX,
    },
    {
      name: 'Companies',
      icon: <BusinessIcon />,
      href: routePaths.companies.ALL,
    },
    {
      name: 'Surveys',
      icon: <QuizIcon />,
      href: routePaths.surveys.INDEX,
    },
    {
      name: 'Resources',
      icon: <FolderIcon />,
      href: routePaths.RESOURCES,
    },
    {
      name: 'My Profile',
      icon: <PersonIcon />,
      href: routePaths.PROFILE,
    },
  ];
  const partnerMenu = [
    {
      name: 'Surveys',
      icon: <QuizIcon />,
      href: routePaths.surveys.INDEX,
    },
    {
      name: 'Companies',
      icon: <BusinessIcon />,
      href: routePaths.companies.ALL,
    },
    {
      name: 'Resources',
      icon: <FolderIcon />,
      href: routePaths.RESOURCES,
    },
    {
      name: 'My Profile',
      icon: <PersonIcon />,
      href: routePaths.PROFILE,
    },
  ];

  const rolesMenu = {
    [Roles.GLOBAL_ADMIN]: globalAdminMenu,
    [Roles.COMPANY_ADMIN]: companyAdminMenu,
    [Roles.PRODUCT_OWNER]: productOwnerMenu,
    [Roles.PARTNER]: partnerMenu,
    [Roles.SURVEY_USER]: [],
  };

  return (
    <Drawer
      elevation={16}
      variant="persistent"
      anchor="left"
      open={props.drawerOpen}
      onClose={props.setDrawerOpen}
      sx={{
        width: '15em',
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: '15.625em',
          boxSizing: 'border-box',
          boxShadow: '9px 0px 20px -10px rgba(0,0,0,0.2)',
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Stack spacing={1} alignItems="center" my="2.1875em">
        {claims.role === Roles.COMPANY_ADMIN && (
          <ProductSelect
            selectedProductId={productSelectState.context.productId}
            products={productSelectState.context.products}
            onChange={(data) => {
              productSelectSend({
                type: 'SELECT_PRODUCT',
                data,
              });
            }}
          />
        )}
        <Box
          sx={{
            borderRadius: '50%',
            height: '101px',
            width: '101px',
            backgroundColor: 'primary.main',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            fontSize="2.0625em"
            color="white"
          >
            {initials}
          </Typography>
        </Box>
        <Typography variant="body1" fontSize="1.125em" fontWeight="bold">
          {fullName}
        </Typography>
        <Typography variant="body2">{role}</Typography>
      </Stack>
      <List>
        <Divider />
        {rolesMenu[claims.role as Roles].map((item) => (
          <React.Fragment key={item.name}>
            <SidebarItem menuItem={item} />
            <Divider />
          </React.Fragment>
        ))}
        <ListItem>
          <ListItemButton
            onClick={() => {
              navigate('/', { replace: true });
              send('LOGOUT');
            }}
          >
            <ListItemIcon sx={{ minWidth: '3.5em' }}>
              <PowerSettingsNewIcon />
            </ListItemIcon>
            <ListItemText
              sx={{
                '& > span': {
                  fontSize: '1em',
                },
              }}
            >
              Log Out
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
      </List>
      <Box width="100%" px={3} py={2} position="absolute" bottom={0}>
        <img
          src="/hsi-logo.png"
          alt="HSI Logo White"
          width="136px"
          height="57px"
        />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
