import * as t from 'io-ts';

const CompanyC = t.exact(
  t.type({
    identifier: t.string,
    name: t.string,
  }),
);

export const SurveyC = t.exact(
  t.type({
    identifier: t.string,
    name: t.string,
    formId: t.string,
    isAuthRequired: t.boolean,
    isActive: t.boolean,
    sentAt: t.union([t.string, t.null]),
    companies: t.union([t.array(CompanyC), t.null]),
  }),
);
export type SurveyC = t.TypeOf<typeof SurveyC>;
