import { Modal } from '../../../Components';
import { Box, Button, Typography } from '@mui/material';

type DeleteFileProps = {
  isOpen: boolean;
  closeModal: () => void;
  onDelete: () => void;
};

const DeleteFileModal = ({ isOpen, closeModal, onDelete }: DeleteFileProps) => {
  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box maxWidth="600px">
        <Typography variant="h2" fontSize="1.625em" fontWeight="bold" mb="29px">
          Delete File
        </Typography>
        <Typography variant="body1" fontSize="1em">
          Are you sure you want to remove this file? You will not be able to
          recover the file from this app and will need to upload it again.
        </Typography>

        <Box display="flex" justifyContent="right">
          <Button
            onClick={closeModal}
            color="error"
            sx={{ mt: '1.5em', mr: '19px' }}
          >
            NO, KEEP FILE
          </Button>
          <Button
            onClick={onDelete}
            color="error"
            sx={{ mt: '1.5em' }}
            variant="contained"
            type="submit"
          >
            YES, REMOVE FILE
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteFileModal;
