import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import axios from 'axios';

import * as constants from '../../../constants';
import { getAuthHeaders } from '../../../authentication';
import { FormValues } from './Profile';

export type ContextT = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string | null;
  image: string;
};

type SaveChangesT = {
  type: 'SAVE_CHANGES';
  data: FormValues;
};

type EventT = SaveChangesT | DoneInvokeEvent<FormValues>;

const get_profile = () =>
  axios
    .get(constants.apiPaths.profile.GET_PROFILE(), getAuthHeaders())
    .then((res) => res.data);

const update_profile = (context: ContextT, event: EventT) =>
  axios
    .put(
      constants.apiPaths.profile.PUT_PROFILE(),
      {
        firstName: event.data.firstName,
        lastName: event.data.lastName,
        emailAddress: event.data.emailAddress,
        phoneNumber: event.data.phoneNumber,
        image: context.image,
      },
      getAuthHeaders(),
    )
    .then((res) => res.data);

export const profileMachine = createMachine<ContextT, EventT>(
  {
    predictableActionArguments: true,
    id: 'profileMachine',
    context: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      image: '',
    },
    initial: 'init_profile',
    states: {
      init_profile: {
        invoke: {
          src: get_profile,
          onDone: {
            target: 'current_profile',
            actions: ['add_profile_to_context'],
          },
          onError: {
            target: 'error_with_profile_load',
          },
        },
      },
      error_with_profile_load: {
        on: {
          ENTER_DATA: {
            target: '',
            actions: ['update_context_on_data_entry'],
          },
          SAVE_CHANGES: {
            target: 'update_profile_call',
          },
          CANCEL: {
            // target: 'init_profile',
          },
        },
      },
      current_profile: {
        on: {
          SAVE_CHANGES: {
            target: 'update_profile_call',
          },
        },
      },
      update_profile_call: {
        invoke: {
          src: update_profile,
          onDone: {
            target: 'update_success',
            actions: ['add_profile_to_context'],
          },
          onError: {
            target: 'update_error',
            actions: ['add_profile_to_context'],
          },
        },
      },
      update_success: {
        on: {
          ENTER_DATA: {
            target: '',
            actions: ['update_context_on_data_entry'],
          },
          SAVE_CHANGES: {
            target: 'update_profile_call',
          },
          CANCEL: {
            // target: 'init_profile',
          },
        },
      },
      update_error: {
        on: {
          ENTER_DATA: {
            target: '',
            actions: ['update_context_on_data_entry'],
          },
          SAVE_CHANGES: {
            target: 'update_profile_call',
          },
          CANCEL: {
            // target: 'init_profile',
          },
        },
      },
    },
  },
  {
    actions: {
      add_profile_to_context: assign((context: ContextT, event: EventT) =>
        Object.assign(context, { ...event.data }),
      ),
    },
  },
);
