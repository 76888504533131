import { useInterpret, useSelector } from '@xstate/react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { loadCompanyMachine } from './machine';
import { UUID4R } from '../../../../refinements';
import { createContext, useEffect, useState } from 'react';
import { InterpreterFrom } from 'xstate';
import { Breadcrumbs as MuiBreadcrumbs, Link as MuiLink } from '@mui/material';
import { Roles, routePaths } from '../../../../constants';
import { useJwtClaims } from '../../../hooks';

type BreadcrumbState = Array<{
  name: string;
  path: string;
}>;

export const CompanyDetailContext = createContext<{
  companyDetailService: InterpreterFrom<typeof loadCompanyMachine>;
  setBreadcrumbs: (
    item: BreadcrumbState | ((item: BreadcrumbState) => BreadcrumbState),
  ) => void;
}>({
  companyDetailService: {} as InterpreterFrom<typeof loadCompanyMachine>,
  setBreadcrumbs: () => {},
});

export const CompanyDetail = () => {
  const claims = useJwtClaims();
  const { identifier } = useParams();
  const navigate = useNavigate();
  const companyDetailService = useInterpret(
    loadCompanyMachine(identifier as UUID4R),
  );
  const currentCompany = useSelector(
    companyDetailService,
    (state) => state.context.company,
  );
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbState>([
    { name: 'Companies', path: routePaths.companies.ALL },
  ]);

  useEffect(() => {
    if (currentCompany) {
      setBreadcrumbs((prev) => {
        if (!prev.some((item) => item.name === currentCompany.name)) {
          return [
            ...prev,
            {
              name: currentCompany.name,
              path: routePaths.companies.TO_PROFILE(identifier as string),
            },
          ];
        }
        return prev;
      });
    }
  }, [currentCompany, identifier]);

  return (
    <CompanyDetailContext.Provider
      value={{ companyDetailService, setBreadcrumbs }}
    >
      {(claims.role !== Roles.COMPANY_ADMIN ||
        (claims.role === Roles.COMPANY_ADMIN && breadcrumbs.length > 2)) && (
        <MuiBreadcrumbs>
          {breadcrumbs.map((item, index) => (
            <MuiLink
              key={item.name}
              onClick={() => {
                if (index === 1) {
                  setBreadcrumbs((prev) => {
                    const newArray = [...prev];
                    newArray.pop();
                    return newArray;
                  });
                }
                navigate(item.path);
              }}
              textTransform="capitalize"
              underline={index === breadcrumbs.length - 1 ? 'none' : 'hover'}
              color={index === breadcrumbs.length - 1 ? 'inherit' : 'primary'}
              sx={{
                pointerEvents:
                  index === breadcrumbs.length - 1 ? 'none' : 'auto',
              }}
            >
              {item.name}
            </MuiLink>
          ))}
        </MuiBreadcrumbs>
      )}
      <Outlet />
    </CompanyDetailContext.Provider>
  );
};

export default CompanyDetail;
