import { Modal } from '../../../../Components';
import { Box, Button, Typography } from '@mui/material';
import { useMachine } from '@xstate/react';
import { deletePartnerMachine } from './machine';
import { Partner } from '../../../../types';

type DeletePartnerModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  partner: Partner;
  onDone: () => void;
};

const DeletePartnerModal = (props: DeletePartnerModalProps) => {
  const [, send, service] = useMachine(deletePartnerMachine(props.partner));

  const exitModal = () => {
    props.closeModal();
  };

  service.onDone(() => {
    props.onDone();
    service.stop();
  });

  return (
    <Modal open={props.isOpen} onClose={exitModal}>
      <Box width="600px">
        <Typography variant="h2" fontSize="1.5em" fontWeight="bold">
          Delete Partner
        </Typography>
        <Typography variant="body1" mt="1.8125em">
          Are you sure you want to delete this partner account? All companies
          will be disconnected from this partner and the partner representative
          will lose access to the system. Companies will not be deleted from the
          system.
        </Typography>
        <Box display="flex" justifyContent="right">
          <Button
            onClick={exitModal}
            sx={{ mt: '1.5em', mr: '0.625em' }}
            color="error"
          >
            NO, KEEP PARTNER
          </Button>
          <Button
            onClick={() => {
              send({ type: 'CONFIRM_DELETE_PARTNER' });
              props.closeModal();
            }}
            sx={{ mt: '1.5em' }}
            variant="contained"
            color="error"
          >
            YES, DELETE PARTNER
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeletePartnerModal;
