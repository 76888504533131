import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    textPrimary: Palette['primary'];
  }

  interface PaletteOptions {
    textPrimary: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#7A72B4',
      dark: '#5C52A0',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#F44336',
    },
    textPrimary: {
      main: '#393939',
    },
  },
  typography: {
    fontFamily: ['Cairo', 'sans-serif'].join(','),
    allVariants: {
      color: '#393939',
    },
    button: {
      fontSize: '0.9375em',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        FormHelperTextProps: { sx: { fontSize: '0.75em' } },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontWeight: 700,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: ['Cairo', 'sans-serif'].join(','),
        },
      },
    },
  },
});

export default theme;
