import { Modal } from '../../../../../Components';
import { Box, Button, Typography } from '@mui/material';
import { useMachine } from '@xstate/react';
import { removeSurveyMachine } from './machine';

type RemoveSurveyModalProps = {
  open: boolean;
  onClose: () => void;
  surveyId: string;
  companyId: string;
  onDone?: () => void;
};

const RemoveSurveyModal = (props: RemoveSurveyModalProps) => {
  const [, send, service] = useMachine(
    removeSurveyMachine(props.surveyId, props.companyId),
  );

  const exitModal = () => {
    props.onClose();
  };

  service.onDone(() => {
    props.onDone?.();
    service.stop();
  });

  return (
    <Modal open={props.open} onClose={exitModal}>
      <Box width="600px">
        <Typography variant="h2" fontSize="1.5em" fontWeight="bold">
          Remove Survey
        </Typography>
        <Typography variant="body1" mt="1.8125em">
          Are you sure you want to remove this survey? This only disconnects the
          survey and its data from the HSI application. The survey can be
          deleted entirely through Typeform.
        </Typography>
        <Box display="flex" justifyContent="right">
          <Button
            onClick={exitModal}
            sx={{ mt: '1.5em', mr: '0.625em' }}
            color="error"
          >
            NO, KEEP SURVEY
          </Button>
          <Button
            onClick={() => {
              send({ type: 'CONFIRM_REMOVE_SURVEY' });
              props.onClose();
            }}
            sx={{ mt: '1.5em' }}
            variant="contained"
            color="error"
          >
            YES, REMOVE SURVEY
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RemoveSurveyModal;
