import { Modal } from '../../../Components';
import { Box, Button, Typography } from '@mui/material';

type DeleteTutorialProps = {
  isOpen: boolean;
  closeModal: () => void;
  onSave: () => void;
};

const ConfirmationModal = ({
  isOpen,
  closeModal,
  onSave,
}: DeleteTutorialProps) => {
  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Typography variant="h2" fontSize="1.5em" fontWeight="bold">
        Cancel Changes?
      </Typography>
      <Typography variant="body1" fontSize="1em">
        Your changes won't be saved. Are you sure you want to cancel your
        changes?
      </Typography>

      <Box display="flex" justifyContent="right">
        <Button
          onClick={closeModal}
          color="error"
          sx={{ mt: '1.5em', mr: '0.625em' }}
        >
          Continue Editing
        </Button>
        <Button
          onClick={onSave}
          sx={{ mt: '1.5em' }}
          variant="contained"
          type="submit"
        >
          Yes, Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
