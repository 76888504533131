import {
  ActorRefFrom,
  assign,
  createMachine,
  DoneInvokeEvent,
  MachineConfig,
} from 'xstate';
import * as constants from '../../../constants';
import axios from 'axios';
import { getAuthHeaders } from '../../../authentication';
import { addCompanyMachine } from './AddCompanyModal/machine';
import { Company, Product, ProductOwner } from '../../types';
import { parallelServices } from '../../helpers/xstate';

export type ContextT = {
  companies: Company[];
  products: Product[];
  productOwners: ProductOwner[];
  size: number;
  from: number;
  count: number;
  activeCompany?: string;
  search: string;
  productId?: string;
  currentPage: number;
  rowsPerPage: number;
  actors: Record<string, ActorRefFrom<any>>;
};
type GetCompaniesEventT = DoneInvokeEvent<{
  count: number;
  results: Company[];
}>;
type GetProductsEventT = DoneInvokeEvent<Product[]>;
type GetProductOwnersEventT = DoneInvokeEvent<ProductOwner[]>;
type EnterSearchEventT = {
  type: 'ENTER_SEARCH';
  data: string;
};
type ChangeCurrentPageT = {
  type: 'CHANGE_CURRENT_PAGE';
  data: number;
};
type ChangeRowsPerPageT = {
  type: 'CHANGE_ROWS_PER_PAGE';
  data: number;
};
type SelectActiveCompanyT = {
  type: 'SELECT_COMPANY';
  data: string;
};
type SelectProductT = {
  type: 'SELECT_PRODUCT';
  data: string;
};
type DeleteCompanyT = {
  type: 'DELETE_COMPANY';
  data: string;
};
type RefreshCompaniesTableT = {
  type: 'REFRESH_COMPANIES_TABLE';
};

type EventT =
  | GetCompaniesEventT
  | GetProductsEventT
  | EnterSearchEventT
  | ChangeCurrentPageT
  | SelectActiveCompanyT
  | ChangeRowsPerPageT
  | SelectProductT
  | DeleteCompanyT
  | RefreshCompaniesTableT;

export const companyMachine = createMachine<ContextT, EventT>(
  {
    predictableActionArguments: true,
    preserveActionOrder: true,
    context: {
      companies: [],
      products: [],
      productOwners: [],
      size: 0,
      from: 0,
      count: 0,
      search: '',
      productId: 'ANY',
      rowsPerPage: 10,
      currentPage: 0,
      actors: {},
    },
    initial: 'load_companies',
    on: {
      REFRESH_COMPANIES_PROFILE: {
        target: 'refresh_companies_profile',
      },
      REFRESH_COMPANIES_TABLE: {
        target: 'load_companies',
      },
    },
    states: {
      load_companies: {
        ...parallelServices([
          { src: 'getCompanies', actions: 'setCompanies' },
          { src: 'getProducts', actions: 'setProducts' },
          { src: 'getProductOwners', actions: 'setProductOwners' },
        ]),
        onDone: 'load_companies_done',
      },
      refresh_companies_profile: {
        invoke: {
          src: 'getCompanies',
          onDone: {
            target: 'modify_company',
            actions: 'setCompanies',
          },
          onError: {
            target: 'load_companies',
          },
        },
      },
      load_companies_done: {
        on: {
          ENTER_SEARCH: {
            target: 'load_companies',
            actions: 'updateSearch',
          },
          CHANGE_CURRENT_PAGE: {
            target: 'load_companies',
            actions: 'setCurrentPage',
          },
          CHANGE_ROWS_PER_PAGE: {
            target: 'load_companies',
            actions: 'updateRowsPerPage',
          },
          SELECT_COMPANY: {
            target: 'modify_company',
            actions: 'setActiveCompany',
          },
          SELECT_PRODUCT: {
            target: 'load_companies',
            actions: 'setProductId',
          },
          ADD_COMPANY_SUCCESS: {},
          DELETE_COMPANY: {},
          UPDATE_COMPANY: {},
        },
      },
      load_companies_error: {
        on: {
          ENTER_SEARCH: {
            target: 'load_companies',
            actions: 'updateSearch',
          },
          CHANGE_CURRENT_PAGE: {
            target: 'load_companies',
            actions: 'setCurrentPage',
          },
          CHANGE_ROWS_PER_PAGE: {
            actions: 'updateRowsPerPage',
          },
          SELECT_COMPANY: {
            target: 'modify_company',
            actions: 'setActiveCompany',
          },
          SELECT_PRODUCT: {
            target: 'load_companies',
            actions: 'setProductId',
          },
          ADD_COMPANY_SUCCESS: {},
          DELETE_COMPANY: {},
          UPDATE_COMPANY: {},
        },
      },
      modify_company: {
        on: {
          GO_HOME: {
            target: 'load_companies_done',
            actions: 'removeActiveCompany',
          },
          VIEW_HISTORY: {
            target: 'view_survey_history',
          },
          DEACTIVATE_SURVEY: {
            target: 'refresh_companies_profile',
          },
          LINK_SURVEY: {},
          UPDATE_COMPANY: {},
        },
      },
      view_survey_history: {
        on: {
          GO_HOME: {
            target: 'load_companies_done',
            actions: 'removeActiveCompany',
          },
          GO_BACK: {
            target: 'modify_company',
          },
        },
      },
      add_new_company: {
        on: {
          ENTER_STRING_DATA: {
            actions: 'updateSearch',
          },
          GO_HOME: {
            target: 'load_companies',
          },
        },
      },
    },
  },
  {
    services: {
      getCompanies: async (context: ContextT, _: EventT) => {
        const skip = context.currentPage * context.rowsPerPage;
        const result = await axios.get(
          constants.apiPaths.companies.GET_ALL(skip, context.rowsPerPage),
          {
            ...getAuthHeaders(),
            params: {
              term: context.search,
              productId: context.productId === 'ANY' ? '' : context.productId,
            },
          },
        );

        return result.data;
      },
      getProducts: async () => {
        const products = await axios.get(
          constants.apiPaths.products.GET_ALL(),
          getAuthHeaders(),
        );

        return products.data;
      },
      getProductOwners: async () => {
        const productOwners = await axios.get(
          constants.apiPaths.productOwners.GET_ALL(),
          getAuthHeaders(),
        );

        return productOwners.data;
      },
    },
    actions: {
      setCompanies: assign((_, event: EventT) => {
        const {
          data: { count, results },
        } = event as GetCompaniesEventT;
        return {
          companies: results,
          count,
        };
      }),
      setProducts: assign((_, event: EventT) => {
        const { data: products } = event as GetProductsEventT;
        return {
          products,
        };
      }),
      setProductOwners: assign((_, event) => {
        const { data: productOwners } = event as GetProductOwnersEventT;
        return {
          productOwners,
        };
      }),
      updateSearch: assign((_, event: EventT) => {
        return {
          search: (event as EnterSearchEventT).data,
        };
      }),
      setCurrentPage: assign((_, event: EventT) => {
        return {
          currentPage: (event as ChangeCurrentPageT).data,
        };
      }),
      removeActiveCompany: assign((_0, _1: EventT) => {
        return {
          activeCompany: undefined,
        };
      }),
      setActiveCompany: assign((_, event: EventT) => {
        return {
          activeCompany: (event as SelectActiveCompanyT).data,
        };
      }),
      setProductId: assign((_, event: EventT) => {
        return {
          productId: (event as SelectProductT).data,
        };
      }),
      updateRowsPerPage: assign((_, event: EventT) => {
        return {
          rowsPerPage: (event as ChangeRowsPerPageT).data,
          currentPage: 0,
        };
      }),
    },
  },
);

export type CompanyMachine = MachineConfig<ContextT, any, EventT>;
export type AddNewCompanyMachine = ActorRefFrom<typeof addCompanyMachine>;
