import {
  Box,
  Button,
  Paper,
  Skeleton,
  Stack,
  TablePagination,
  Typography,
} from '@mui/material';
import { createContext } from 'react';
import { InterpreterFrom } from 'xstate';
import resourcePageMachine from './machine';
import { useActor, useInterpret } from '@xstate/react';
import {
  AddTutorialModal,
  DeleteFileModal,
  DeleteTutorialModal,
  AddFileModal,
  FileList,
  VideoItem,
} from './components';
import { useJwtClaims } from '../../hooks';
import { Roles } from '../../../constants';

export const ResourcePageContext = createContext(
  {} as InterpreterFrom<typeof resourcePageMachine>,
);

const LoadingIcon = () => (
  <>
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
  </>
);

const ResourcePage = () => {
  const resourcePageService = useInterpret(resourcePageMachine);
  const [state, send] = useActor(resourcePageService);
  const claims = useJwtClaims();

  const isAdmin = claims.role === Roles.GLOBAL_ADMIN;

  return (
    <ResourcePageContext.Provider value={resourcePageService}>
      <Box>
        {state.matches('remove_tutorial') && (
          <DeleteTutorialModal
            isOpen={state.context.isModalOpen}
            closeModal={() => {
              send({
                type: 'TOGGLE_REMOVE_TUTORIAL',
                isModalOpen: false,
                selectedResourceId: '',
              });
            }}
            onDelete={() => {
              send({
                type: 'REMOVE_RESOURCE',
                id: state.context.selectedResourceId,
                resource: 'tutorials',
              });
            }}
          />
        )}
        {state.matches('add_tutorial') && (
          <AddTutorialModal
            isOpen={state.context.isModalOpen}
            closeModal={() => {
              send({
                type: 'TOGGLE_ADD_TUTORIAL',
                isModalOpen: false,
              });
            }}
            onAdd={(data) => {
              send(
                {
                  type: 'ADD_TUTORIAL',
                  data,
                },
                { to: 'add_tutorial' },
              );
            }}
          />
        )}
        <Typography
          variant="h1"
          fontSize="2em"
          fontWeight="bold"
          data-testid="title"
        >
          Resources
        </Typography>
        <Paper
          sx={{
            mt: '1.5em',
            px: '2.75em',
            py: '1.9375em',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2" fontSize="1.625em" fontWeight="bold">
              Tutorials
            </Typography>
            {isAdmin && (
              <Button
                variant="contained"
                onClick={() =>
                  send({
                    type: 'TOGGLE_ADD_TUTORIAL',
                    isModalOpen: true,
                  })
                }
              >
                ADD NEW VIDEO URL
              </Button>
            )}
          </Stack>
          <Stack
            height="100%"
            direction="row"
            alignItems="flex-start"
            mt="1.375em"
            spacing="1.125em"
          >
            {state.context.tutorials.map((yt) => (
              <VideoItem
                key={yt.id}
                {...yt}
                onDelete={() => {
                  send({
                    type: 'TOGGLE_REMOVE_TUTORIAL',
                    isModalOpen: true,
                    selectedResourceId: yt.id,
                  });
                }}
              />
            ))}
          </Stack>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={state.context.tutorialCount}
            rowsPerPage={state.context.tutorialRowsPerPage}
            page={state.context.tutorialCurrentPage}
            onPageChange={(_, v) =>
              send({
                type: 'PAGE_CHANGE',
                prop: 'tutorialCurrentPage',
                data: v,
              })
            }
          />
        </Paper>
      </Box>
      <Box>
        {state.matches('remove_file') && (
          <DeleteFileModal
            isOpen={state.context.isModalOpen}
            closeModal={() => {
              send({
                type: 'TOGGLE_REMOVE_FILE',
                isModalOpen: false,
                selectedResourceId: '',
              });
            }}
            onDelete={() => {
              send({
                type: 'REMOVE_RESOURCE',
                id: state.context.selectedResourceId,
                resource: 'files',
              });
            }}
          />
        )}
        {state.matches('add_file') && (
          <AddFileModal
            isOpen={state.context.isModalOpen}
            closeModal={() => {
              send({
                type: 'TOGGLE_ADD_FILE',
                isModalOpen: false,
              });
            }}
          />
        )}
        <Paper
          sx={{
            mt: '1.5em',
            px: '2.75em',
            py: '1.9375em',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2" fontSize="1.625em" fontWeight="bold">
              Files
            </Typography>
            {isAdmin && (
              <Button
                variant="contained"
                onClick={() =>
                  send({
                    type: 'TOGGLE_ADD_FILE',
                    isModalOpen: true,
                  })
                }
              >
                UPLOAD NEW FILE
              </Button>
            )}
          </Stack>
          <Box mt="1.375em">
            <FileList
              files={state.context.files}
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={state.context.fileCount}
              rowsPerPage={state.context.fileRowsPerPage}
              page={state.context.fileCurrentPage}
              onDelete={(id: string) => {
                send({
                  type: 'TOGGLE_REMOVE_FILE',
                  isModalOpen: true,
                  selectedResourceId: id,
                });
              }}
              onPageChange={(_, v) =>
                send({
                  type: 'PAGE_CHANGE',
                  prop: 'fileCurrentPage',
                  data: v,
                })
              }
              onRowsPerPageChange={(e) =>
                send({
                  type: 'CHANGE_ROWS_PER_PAGE',
                  data: parseInt(e.target.value),
                })
              }
            />
          </Box>
        </Paper>
      </Box>
    </ResourcePageContext.Provider>
  );
};

export default ResourcePage;
