import React, { useContext } from 'react';
import {
  Typography,
  Box,
  Divider,
  Paper,
  Button,
  Snackbar,
  Alert,
  Stack,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from '@xstate/react';
import EditPartnerModal from '../EditPartnerModal';
import DeletePartnerModal from '../DeletePartnerModal';
import { useNavigate } from 'react-router-dom';
import { Roles, apiPaths, routePaths } from '../../../../../constants';
import axios from 'axios';
import { PartnerProfileContext } from '.';
import ResetPasswordModal from './ResetPasswordModal';
import CompanyTable from './CompanyTable/CompanyTable';
import { useJwtClaims } from '../../../../hooks';

type PartnerDetailsProps = {
  inactive?: boolean;
};

export const PartnerDetails = ({ inactive }: PartnerDetailsProps) => {
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] =
    React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [passwordResetOpen, setPasswordResetOpen] =
    React.useState<boolean>(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const claims = useJwtClaims();
  const { partnerProfileService: service } = useContext(PartnerProfileContext);
  const _activePartner = useSelector(service, (state) => state.context.partner);
  const resetPassword = () =>
    axios
      .post(apiPaths.auth.UPDATE_PASSWORD(), {
        email: _activePartner?.user.emailAddress,
      })
      .then(() => setPasswordResetOpen(true));

  if (_activePartner === undefined) return <></>;

  return (
    <>
      <Snackbar
        open={passwordResetOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setPasswordResetOpen(false)}
      >
        <Alert
          onClose={() => setPasswordResetOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          An email with instructions has been sent to reset password.
        </Alert>
      </Snackbar>
      <Snackbar
        open={editOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setEditOpen(false)}
      >
        <Alert
          onClose={() => setEditOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Updated Partner Successfully!
        </Alert>
      </Snackbar>
      <EditPartnerModal
        isOpen={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
        partner={_activePartner}
        onDone={() => {
          setEditOpen(true);
          service.send({ type: 'RELOAD_PARTNER' });
        }}
        onDelete={() => setDeleteModalOpen(true)}
      />
      <ResetPasswordModal
        isOpen={resetPasswordModalOpen}
        closeModal={() => setResetPasswordModalOpen(false)}
        partner={_activePartner}
        onConfirm={() => resetPassword()}
      />
      <DeletePartnerModal
        isOpen={deleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        partner={_activePartner}
        onDone={() => {
          navigate(routePaths.PARTNERS.INDEX);
        }}
      />
      <Box
        sx={{
          display: [, , 'flex'],
          justifyContent: [, , 'space-between'],
          flexWrap: 'wrap',
        }}
      >
        <Box mt="0.5625rem">
          <Typography variant="h1" fontSize="2em" fontWeight="bold" noWrap>
            {_activePartner.name}
          </Typography>
        </Box>
        {!inactive ? (
          <Box
            mt="0.5625rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {claims.role === Roles.GLOBAL_ADMIN && (
              <>
                <Button
                  startIcon={<EditIcon />}
                  variant="contained"
                  onClick={() => setEditModalOpen(true)}
                >
                  EDIT
                </Button>
                <Button
                  startIcon={<LockIcon />}
                  sx={{ ml: '1.0625rem' }}
                  variant="contained"
                  onClick={() => setResetPasswordModalOpen(true)}
                >
                  RESET PASSWORD
                </Button>
                <Button
                  startIcon={<DeleteIcon />}
                  sx={{ ml: '1.0625rem' }}
                  variant="contained"
                  color="error"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  DELETE
                </Button>
              </>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {!inactive ? (
        <Paper
          elevation={3}
          sx={{
            mt: '1.4375rem',
            px: '3.125em',
            py: '1.0625em',
          }}
        >
          <Stack direction="row" spacing={4}>
            <Typography variant="h2" fontSize="1.5em" fontWeight="bold" py={1}>
              Partner Info
            </Typography>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box py={1}>
              <Typography variant="h3" fontSize="0.875em" fontWeight="bold">
                Representative Name
              </Typography>
              <Typography fontSize="0.875em" data-testid="user-fullname">
                {_activePartner.user.firstName +
                  ' ' +
                  _activePartner.user.lastName}
              </Typography>
            </Box>
            <Box py={1}>
              <Typography variant="h3" fontSize="0.875em" fontWeight="bold">
                Email Address
              </Typography>
              <Typography
                fontSize="0.875em"
                sx={{
                  maxWidth: '200px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {_activePartner.user.emailAddress}
              </Typography>
            </Box>
            <Box py={1}>
              <Typography variant="h3" fontSize="0.875em" fontWeight="bold">
                Phone Number
              </Typography>
              <Typography fontSize="0.875em">
                {_activePartner.user.phoneNumber ?? '-'}
              </Typography>
            </Box>
          </Stack>
        </Paper>
      ) : (
        <></>
      )}
      <CompanyTable partner={_activePartner} service={service} />
    </>
  );
};

export default PartnerDetails;
