// This can be used to wait for multiple services to finish running before going to another state

import { StateNodeConfig } from 'xstate';

// SOURCE: https://github.com/statelyai/xstate/issues/321#issuecomment-854075060
export const parallelServices = (
  services: { src: string; actions: string | string[] }[],
): StateNodeConfig<any, any, any> => {
  return {
    type: 'parallel',
    states: services.reduce((acc, { src, actions }) => {
      return {
        ...acc,
        [src]: {
          initial: 'run',
          states: {
            run: {
              invoke: {
                src,
                onDone: {
                  actions,
                  target: 'done',
                },
              },
            },
            done: {
              type: 'final',
            },
          },
        },
      };
    }, {}),
  };
};
