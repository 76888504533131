import axios, { AxiosError } from 'axios';
import { FormValues } from '.';
import { HttpException, Nullable, ProductOwner } from '../../../../types';
import * as constants from '../../../../../constants';
import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import { getAuthHeaders } from '../../../../../authentication';

export type ContextT = {
  productOwner: ProductOwner;
} & {
  errObj: Nullable<HttpException>;
};

type UpdateProductT = {
  type: 'UPDATE_PRODUCT_OWNER';
  data: FormValues;
};
type UpdateProductErrorT = DoneInvokeEvent<HttpException>;
type EventT = UpdateProductT | UpdateProductErrorT;

export const editProductOwnerMachine = (productOwner: ProductOwner) =>
  createMachine<ContextT, EventT>(
    {
      predictableActionArguments: true,
      id: `editMachine-${productOwner.identifier}`,
      context: {
        productOwner: productOwner,
        errObj: null,
      },
      initial: 'main',
      states: {
        main: {
          on: {
            UPDATE_PRODUCT_OWNER: {
              target: 'start',
            },
          },
        },
        start: {
          invoke: {
            src: 'updateProductOwner',
            onDone: {
              target: 'done',
            },
            onError: {
              target: 'main',
              actions: 'setError',
            },
          },
        },
        done: {
          after: {
            0: { target: 'main' },
          },
        },
      },
    },
    {
      services: {
        updateProductOwner: async (c: ContextT, e: EventT) => {
          try {
            const formData = (e as UpdateProductT).data;
            const result = await axios.put(
              constants.apiPaths.productOwners.PRODUCT_OWNER(
                c.productOwner.identifier,
              ),
              {
                user: {
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                  emailAddress: formData.emailAddress,
                  phoneNumber: formData.phoneNumber,
                },
              },
              getAuthHeaders(),
            );

            return result.data;
          } catch (error) {
            throw (error as AxiosError).response?.data;
          }
        },
      },
      actions: {
        setError: assign((_, e: EventT) => ({
          errObj: (e as UpdateProductErrorT).data,
        })),
      },
    },
  );
