import React from 'react';
import { loginMachine } from './machine';
import { Route, Routes } from 'react-router-dom';

import Survey from './Surveys';

import { useInterpret } from '@xstate/react';
import { ActorRefFrom } from 'xstate';

import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import { routePaths } from '../constants';
import Authentication from './Authentication';
import PasswordResetForm from './Authentication/PasswordResetForm';

export type AuthenticationContext = {
  authenticationService: ActorRefFrom<typeof loginMachine>;
};
export const AuthenticationContext = React.createContext(
  {} as AuthenticationContext,
);

const MainLayout = () => {
  const authenticationService = useInterpret(loginMachine);

  return (
    <ThemeProvider theme={theme}>
      <AuthenticationContext.Provider value={{ authenticationService }}>
        <Routes>
          <Route path={routePaths.SURVEYS} element={<Survey />} />
          <Route
            path={routePaths.PASSWORD_RESET}
            element={<PasswordResetForm />}
          />
          <Route path="*" element={<Authentication />} />
        </Routes>
      </AuthenticationContext.Provider>
    </ThemeProvider>
  );
};

export default MainLayout;
