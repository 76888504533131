import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { CompanyC } from './App/Authenticated/Admin/codecs';

// Define a type for the slice state
type CompaniesState = {
  companies: Array<CompanyC>;
};

// Define the initial state using that type
const initialState: CompaniesState = {
  companies: [],
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    addNewCompany: (state, action: PayloadAction<CompanyC>) => {
      state.companies = [action.payload].concat(state.companies);
    },
    loadCompany: (state, action: PayloadAction<CompanyC>) => {
      const exists =
        state.companies.filter(
          (c) => c.identifier === action.payload.identifier,
        ).length === 1;
      state.companies =
        exists === false
          ? [action.payload].concat(state.companies) // add to array
          : state.companies.map((c) =>
              c.identifier === action.payload.identifier ? action.payload : c,
            );
    },
    addCompanies: (state, action: PayloadAction<Array<CompanyC>>) => {
      state.companies = action.payload;
    },
    deleteCompany: (state, action: PayloadAction<CompanyC>) => {
      state.companies = state.companies.filter(
        (c) => c.identifier !== action.payload.identifier,
      );
    },
  },
});

export const { addCompanies, addNewCompany, loadCompany, deleteCompany } =
  companiesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCompanies = (state: RootState) => state.companies;

export default companiesSlice.reducer;
