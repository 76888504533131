import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import { Company, Partner, ProductOwner } from '../../types';
import axios from 'axios';
import * as constants from '../../../constants';
import { getAuthHeaders } from '../../../authentication';
import { parallelServices } from '../../helpers/xstate';

type GetProductOwnersEventType = DoneInvokeEvent<ProductOwner[]>;
type GetCompaniesEventType = DoneInvokeEvent<{
  results: Company[];
  count: number;
}>;
type GetPartnersEventType = DoneInvokeEvent<{
  partners: Partner[];
  count: number;
}>;
type ReloadEventType = {
  type: 'RELOAD';
};
type EventType =
  | ReloadEventType
  | GetCompaniesEventType
  | GetPartnersEventType
  | GetProductOwnersEventType;
type ContextType = {
  companies: Company[];
  partners: Partner[];
  productOwners: ProductOwner[];
};

const productOwnerPageMachine = createMachine<ContextType, EventType>(
  {
    id: 'productOwnerPageMachine',
    initial: 'load_products',
    predictableActionArguments: true,
    context: {
      companies: [],
      partners: [],
      productOwners: [],
    },
    states: {
      load_products: {
        ...parallelServices([
          { src: 'getCompanies', actions: 'setCompanies' },
          { src: 'getPartners', actions: 'setPartners' },
          { src: 'getProductOwners', actions: 'setProductOwners' },
        ]),
        onDone: 'load_success',
      },
      load_success: {
        on: {
          RELOAD: {
            target: 'load_products',
          },
        },
      },
      load_error: {
        on: {
          RELOAD: {
            target: 'load_products',
          },
        },
      },
    },
  },
  {
    services: {
      getProducts: async () => {
        const products = await axios.get(
          constants.apiPaths.products.GET_ALL(),
          getAuthHeaders(),
        );

        return products.data;
      },
      getProductOwners: async () => {
        const productOwners = await axios.get(
          constants.apiPaths.productOwners.GET_ALL(),
          getAuthHeaders(),
        );

        return productOwners.data;
      },
      getCompanies: async () => {
        const companies = await axios.get(
          constants.apiPaths.companies.GET_ALL(0, 1000),
          getAuthHeaders(),
        );

        return companies.data;
      },
      getPartners: async () => {
        const partners = await axios.get(
          constants.apiPaths.partners.GET_ALL(0, 1000),
          getAuthHeaders(),
        );

        return partners.data;
      },
    },
    actions: {
      setProductOwners: assign((_, event) => {
        return {
          productOwners: (event as GetProductOwnersEventType).data,
        };
      }),
      setCompanies: assign((_, event) => {
        const {
          data: { results: companies },
        } = event as GetCompaniesEventType;
        return {
          companies,
        };
      }),
      setPartners: assign((_, event) => {
        const {
          data: { partners },
        } = event as GetPartnersEventType;
        return {
          partners,
        };
      }),
    },
  },
);

export default productOwnerPageMachine;
