import axios from 'axios';
import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import * as constants from '../../../../../../constants';
import { getAuthHeaders } from '../../../../../../authentication';
import { SurveyWithProduct } from '../../../../../types';

export type ContextT = {
  identifier: string;
  productIdentifier?: string;
  inactive: boolean;
  surveys: SurveyWithProduct[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
};
type LoadSurveysT = DoneInvokeEvent<{
  surveys: SurveyWithProduct[];
  count: number;
}>;
type ChangeCurrentPageT = {
  type: 'CHANGE_CURRENT_PAGE';
  data: number;
};
type ChangeRowsPerPageT = {
  type: 'CHANGE_ROWS_PER_PAGE';
  data: number;
};
type ChangeProductIdentifierT = {
  type: 'CHANGE_PRODUCT_IDENTIFIER';
  data: string;
};
type ToggleSurveyT = {
  type: 'ACTIVATE' | 'DEACTIVATE';
  data: string;
};
type ReloadT = {
  type: 'RELOAD';
};

type EventT =
  | LoadSurveysT
  | ChangeCurrentPageT
  | ChangeRowsPerPageT
  | ChangeProductIdentifierT
  | ToggleSurveyT
  | ReloadT;

export const surveyMachine = (
  identifier: string,
  inactive: boolean,
  productIdentifier?: string,
) =>
  createMachine<ContextT, EventT>(
    {
      id: identifier,
      predictableActionArguments: true,
      preserveActionOrder: true,
      context: {
        identifier,
        productIdentifier,
        inactive,
        surveys: [],
        count: 0,
        rowsPerPage: 10,
        currentPage: 0,
      },
      initial: 'load_surveys',
      on: {
        CHANGE_PRODUCT_IDENTIFIER: {
          target: 'load_surveys',
          actions: 'setProductIdentifier',
        },
      },
      states: {
        load_surveys: {
          invoke: {
            src: 'loadSurveys',
            onDone: {
              target: 'load_surveys_done',
              actions: 'setSurveys',
            },
            onError: {
              target: 'load_surveys_error',
            },
          },
        },
        load_surveys_done: {
          on: {
            CHANGE_CURRENT_PAGE: {
              target: 'load_surveys',
              actions: 'setCurrentPage',
            },
            CHANGE_ROWS_PER_PAGE: {
              target: 'load_surveys',
              actions: 'setRowsPerPage',
            },
            ACTIVATE: 'activate_survey',
            DEACTIVATE: 'deactivate_survey',
            RELOAD: 'load_surveys',
          },
        },
        activate_survey: {
          invoke: {
            src: 'activateSurvey',
            onDone: {
              target: 'load_surveys',
            },
          },
        },
        deactivate_survey: {
          invoke: {
            src: 'deactivateSurvey',
            onDone: {
              target: 'load_surveys',
            },
          },
        },
        load_surveys_error: {
          on: {
            CHANGE_CURRENT_PAGE: {
              target: 'load_surveys',
              actions: 'setCurrentPage',
            },
            CHANGE_ROWS_PER_PAGE: {
              target: 'load_surveys',
              actions: 'setRowsPerPage',
            },
            ACTIVATE: 'activate_survey',
            DEACTIVATE: 'deactivate_survey',
            RELOAD: 'load_surveys',
          },
        },
      },
    },
    {
      services: {
        loadSurveys: async (context: ContextT, _: EventT) => {
          const skip = context.currentPage * context.rowsPerPage;
          const response = await axios.get(
            constants.apiPaths.companies.GET_COMPANY_SURVEYS(
              context.identifier,
              skip,
              context.rowsPerPage,
              context.inactive,
              context.productIdentifier,
            ),
            getAuthHeaders(),
          );

          return response.data;
        },
        activateSurvey: async (_: ContextT, event: EventT) => {
          await axios.get(
            constants.apiPaths.surveys.ACTIVATE_SURVEY(
              (event as ToggleSurveyT).data,
            ),
          );
        },
        deactivateSurvey: async (_: ContextT, event: EventT) => {
          await axios.get(
            constants.apiPaths.surveys.DEACTIVATE_SURVEY(
              (event as ToggleSurveyT).data,
            ),
          );
        },
      },
      actions: {
        setSurveys: assign((_, event: EventT) => {
          return {
            surveys: (event as LoadSurveysT).data.surveys,
            count: (event as LoadSurveysT).data.count,
          };
        }),
        setCurrentPage: assign((_, event: EventT) => {
          return {
            currentPage: (event as ChangeCurrentPageT).data,
          };
        }),
        setRowsPerPage: assign((_, event: EventT) => {
          return {
            rowsPerPage: (event as ChangeRowsPerPageT).data,
            currentPage: 0,
          };
        }),
        setProductIdentifier: assign((_, event: EventT) => {
          return {
            productIdentifier: (event as ChangeProductIdentifierT).data,
          };
        }),
      },
    },
  );
