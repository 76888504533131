import * as F from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import React from 'react';
import AuthenticationWrapper from './AuthenticationWrapper';
import { AuthenticationContext } from '../index';
import { useActor } from '@xstate/react';
import FormContainer from './components/FormContainer';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Link as MUILink,
} from '@mui/material';
let type = 'ADMIN';

const LoginFormCall = () => {
  const services = React.useContext(AuthenticationContext);
  const [current, send] = useActor(services.authenticationService);

  const _email = F.pipe(
    current.context.emailAddress,
    O.fold(F.constant(''), F.identity),
  );
  const _password = F.pipe(
    current.context.password,
    O.fold(F.constant(''), F.identity),
  );

  return (
    <AuthenticationWrapper>
      <FormContainer>
        <Typography
          variant="h1"
          fontSize="2em"
          fontWeight="bold"
          align="center"
          mt="0.5625em"
        >
          {type === 'ADMIN' ? 'Login to HSI' : 'Survey Login'}
        </Typography>
        <TextField
          label="Email Address"
          type="email"
          value={_email}
          disabled={true}
          sx={{
            marginTop: '2.1875em',
          }}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="Password"
          type="password"
          value={_password}
          disabled={true}
          sx={{ marginTop: '2.1875em' }}
          fullWidth
          variant="outlined"
        />
        <Button
          sx={{ marginTop: '2.1875em' }}
          fullWidth
          variant="contained"
          type="submit"
          onClick={(e) => send({ type: 'SUBMIT' })}
        >
          <CircularProgress color="inherit" size={27} disableShrink />
        </Button>
        <Box width="100%" display="flex" justifyContent="center">
          <MUILink
            sx={{ cursor: 'pointer', marginTop: '1.5em' }}
            align="center"
            underline="none"
            onClick={() => send({ type: 'RECOVER_PASSWORD' })}
          >
            I'VE FORGOTTEN MY PASSWORD
          </MUILink>
        </Box>
        <Typography mt="2.125em" textAlign="center" fontSize="1em">
          Don't have an account
        </Typography>
        <Box width="100%" display="flex" justifyContent="center">
          {type === 'ADMIN' ? (
            <MUILink
              sx={{ cursor: 'pointer', marginTop: '0.5em' }}
              align="center"
              underline="none"
            >
              Contact info@healthandsafetyindex.com.au
            </MUILink>
          ) : (
            <Button
              fullWidth
              variant="outlined"
              sx={{ marginTop: '1em' }}
              onClick={() => {
                // navigate('/user/signup');
              }}
            >
              CREATE AN ACCOUNT
            </Button>
          )}
        </Box>
      </FormContainer>
    </AuthenticationWrapper>
  );
};

export default LoginFormCall;
