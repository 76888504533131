import ReactPhoneInput, {
  PhoneInputProps as ReactPhoneInputProps,
} from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './style.css';
import { FormHelperText } from '@mui/material';
import { Nullable } from '../types';
import { forwardRef } from 'react';

type PhoneInputProps = ReactPhoneInputProps & {
  hasError: boolean;
  errorMessage?: string;
  onChange: (val: Nullable<string>) => void;
};

const PhoneInput = forwardRef((props: PhoneInputProps, ref) => {
  const { hasError, errorMessage, onChange, ...rest } = props;
  return (
    <>
      <ReactPhoneInput
        onChange={(val, data, e, fmtVal) => {
          onChange(fmtVal === '' || fmtVal === '+' ? null : fmtVal);
        }}
        {...rest}
      />
      {hasError && (
        <FormHelperText error={hasError} sx={{ mx: '14px', mt: '4px' }}>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
});

export default PhoneInput;
