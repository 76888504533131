import {
  Chip,
  FormControl,
  Link,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { ReactNode, useCallback, useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import {
  Company,
  Product,
  Survey,
  SurveyWithCompanies,
  SurveyWithProduct,
} from '../../../types';
import { useJwtClaims } from '../../../hooks';
import { SurveyPageContext } from '..';
import LinkedCompaniesModal from './LinkedCompaniesModal';
import ConfigureSurveyModal from './ConfigureSurveyModal';
import { Roles } from '../../../../constants';
import PaginatedLinkedCompaniesModal from './PaginatedLinkedCompaniesModal';
import { Select } from '../../../common';
import SearchField from '../../../common/SearchField';

const globalAdminHeadColumns = [
  'Survey Name',
  'Product Type',
  'Survey Type',
  'Companies',
  'Status',
  'Actions',
];

const productOwnerHeadColumns = [
  'Survey Name',
  'Companies with Active Surveys',
];

const partnerHeadColumns = [
  'Survey Name',
  'Product Type',
  'Linked Companies',
  // 'Sent on',
  'Status',
  'Actions',
];

type CustomTableRowProps = {
  survey: SurveyWithProduct & SurveyWithCompanies;
  products: Product[];
};

type CustomTableCellProps = {
  children: ReactNode;
  sx?: SxProps;
};

type FilterHandler = (
  prop: 'term' | 'productIdentifier' | 'type',
  data: string,
) => void;

type ProductSelectProps = {
  products: Product[];
  value: string;
  onChange: FilterHandler;
};

const ProductSelect = ({ products, value, onChange }: ProductSelectProps) => {
  return (
    <FormControl
      fullWidth
      sx={{
        '& .MuiInputBase-root': {
          height: '2.375em',
        },
      }}
    >
      <Select
        defaultMenuItemLabel="Any connected product"
        selectedValue={value}
        values={products}
        onChange={(data) => {
          onChange('productIdentifier', data as string);
        }}
      />
    </FormControl>
  );
};

type TypeSelectProps = {
  value: 'ANY' | 'COMPANY' | 'INDIVIDUAL';
  onChange: FilterHandler;
};

const TypeSelect = ({ value, onChange }: TypeSelectProps) => {
  const types = [
    { name: 'Company', identifier: 'COMPANY' },
    { name: 'Individual', identifier: 'INDIVIDUAL' },
  ];

  return (
    <FormControl
      fullWidth
      sx={{
        '& .MuiInputBase-root': {
          height: '2.375em',
        },
      }}
    >
      <Select
        defaultMenuItemLabel="All Survey Types"
        selectedValue={value}
        values={types}
        onChange={(data) => {
          onChange('type', data as string);
        }}
      />
    </FormControl>
  );
};

const CustomTableCell = ({ children, sx }: CustomTableCellProps) => (
  <TableCell sx={{ verticalAlign: 'text-top', ...sx }}>{children}</TableCell>
);

const CustomGlobalAdminTableRow = ({
  survey,
  products,
}: CustomTableRowProps) => {
  const [linkedCompaniesOpen, setLinkedCompaniesOpen] = useState(false);
  const [configureSurveyOpen, setConfigureSurveyOpen] = useState(false);

  return (
    <TableRow key={survey.identifier}>
      <LinkedCompaniesModal
        companies={survey.companies}
        open={linkedCompaniesOpen}
        onClose={() => setLinkedCompaniesOpen(false)}
      />
      <ConfigureSurveyModal
        survey={survey}
        products={products}
        open={configureSurveyOpen}
        onClose={() => setConfigureSurveyOpen(false)}
      />
      <CustomTableCell>{survey.name}</CustomTableCell>
      <CustomTableCell>{survey.product?.name}</CustomTableCell>
      <CustomTableCell>
        {survey.isAuthRequired ? 'Individual' : 'Company'}
      </CustomTableCell>
      <CustomTableCell>
        <Link
          sx={{ cursor: 'pointer' }}
          onClick={() => setLinkedCompaniesOpen(true)}
        >
          {`${survey.companies.length} ${
            survey.companies.length <= 1 ? 'company' : 'companies'
          }`}
        </Link>
      </CustomTableCell>
      <CustomTableCell>
        <Chip
          label={survey.isActive ? 'Visible' : 'Hidden'}
          sx={
            survey.isActive
              ? { backgroundColor: '#4caf50', color: '#ffffff' }
              : { backgroundColor: '#e0e0e0', color: '#000000' }
          }
        />
      </CustomTableCell>
      <CustomTableCell>
        <Link
          sx={{ cursor: 'pointer' }}
          onClick={() => setConfigureSurveyOpen(true)}
        >
          Configure Survey
        </Link>
      </CustomTableCell>
    </TableRow>
  );
};

const CustomProductOwnerTableRow = ({ survey }: CustomTableRowProps) => {
  const [linkedCompaniesOpen, setLinkedCompaniesOpen] = useState(false);
  const claims = useJwtClaims();

  return (
    <TableRow key={survey.identifier}>
      {linkedCompaniesOpen &&
        (claims.role === Roles.PRODUCT_OWNER ? (
          <PaginatedLinkedCompaniesModal
            open={linkedCompaniesOpen}
            onClose={() => setLinkedCompaniesOpen(false)}
            survey={survey}
          />
        ) : (
          <LinkedCompaniesModal
            companies={survey.companies}
            open={linkedCompaniesOpen}
            onClose={() => setLinkedCompaniesOpen(false)}
          />
        ))}
      <CustomTableCell>{survey.name}</CustomTableCell>
      <CustomTableCell>
        <Link
          sx={{ cursor: 'pointer' }}
          onClick={() => setLinkedCompaniesOpen(true)}
        >
          {`${survey.companies.length} ${
            survey.companies.length <= 1 ? 'company' : 'companies'
          }`}
        </Link>
      </CustomTableCell>
    </TableRow>
  );
};

const CustomPartnerTableRow = ({ survey, products }: CustomTableRowProps) => {
  const [linkedCompaniesOpen, setLinkedCompaniesOpen] = useState(false);

  return (
    <TableRow key={survey.identifier}>
      <LinkedCompaniesModal
        companies={survey.companies}
        open={linkedCompaniesOpen}
        onClose={() => setLinkedCompaniesOpen(false)}
        surveyName={survey.name}
      />
      <CustomTableCell>{survey.name}</CustomTableCell>
      <CustomTableCell>{survey.product?.name}</CustomTableCell>
      <CustomTableCell>
        {`${survey.companies.length} ${
          survey.companies.length <= 1 ? 'company' : 'companies'
        }`}
      </CustomTableCell>
      {/* Hiding for now based on QA */}
      {/* <CustomTableCell>
        {survey.sentAt
          ? moment(new Date(survey.sentAt)).format('DD MMM YYYY, HH:mm a')
          : ''}
      </CustomTableCell> */}
      <CustomTableCell>
        <Chip
          label={survey.isActive ? 'Visible' : 'Hidden'}
          sx={
            survey.isActive
              ? { backgroundColor: '#4caf50', color: '#ffffff' }
              : { backgroundColor: '#e0e0e0', color: '#000000' }
          }
        />
      </CustomTableCell>
      <CustomTableCell>
        <Link
          sx={{ cursor: 'pointer' }}
          onClick={() => setLinkedCompaniesOpen(true)}
        >
          View linked companies
        </Link>
      </CustomTableCell>
    </TableRow>
  );
};

const SurveyTable = () => {
  const service = useContext(SurveyPageContext);
  const [state, send] = useActor(service);
  const handleFilter: FilterHandler = useCallback(
    (prop, data) => {
      send({
        type: 'FILTER',
        prop,
        data,
      });
    },
    [send],
  );

  const claims = useJwtClaims();

  const isAdmin = claims.role === Roles.GLOBAL_ADMIN;

  const displayTableRow = (
    survey: Survey & {
      companies: Company[];
    } & {
      product: Product;
    },
  ) => {
    switch (claims.role) {
      case Roles.GLOBAL_ADMIN:
        return (
          <CustomGlobalAdminTableRow
            key={survey.identifier}
            survey={survey}
            products={state.context.products}
          />
        );
      case Roles.PRODUCT_OWNER:
        return (
          <CustomProductOwnerTableRow
            key={survey.identifier}
            survey={survey}
            products={state.context.products}
          />
        );
      case Roles.PARTNER:
        return (
          <CustomPartnerTableRow
            key={survey.identifier}
            survey={survey}
            products={state.context.products}
          />
        );

      default:
        return <></>;
    }
  };

  const tableHeadersList = () => {
    switch (claims.role) {
      case Roles.GLOBAL_ADMIN:
        return globalAdminHeadColumns;
      case Roles.PRODUCT_OWNER:
        return productOwnerHeadColumns;
      case Roles.PARTNER:
        return partnerHeadColumns;
      default:
        return [];
    }
  };

  const handleSearchFieldChange: (data: string) => void = useCallback(
    (data) => {
      handleFilter('term', data);
    },
    [handleFilter],
  );

  const productOwner =
    claims.role === Roles.PRODUCT_OWNER
      ? state.context.products?.[0]?.ProductOwner?.name ?? ''
      : '';

  return (
    <Paper sx={{ px: '3em', py: '1.9375em' }}>
      {/* 
        TODO: If there will be new partners aside from MATES, this needs updating.
        For now, this is fixed to MATES since this needs a specific company/partner name based
        on the specs, instead of the product name (MATES Blueprint etc..)
      */}
      {claims.role === Roles.PRODUCT_OWNER && (
        <Typography variant="h2" fontSize="1.625em" fontWeight="bold" mb={2}>
          {`${productOwner} Surveys (${state.context.count})`}
        </Typography>
      )}
      {isAdmin && (
        <Stack direction="row" spacing="14px" mb="0.75em">
          <SearchField
            onChange={handleSearchFieldChange}
            label={'Search name of survey'}
          />
          <ProductSelect
            products={state.context.products}
            value={state.context.productIdentifier}
            onChange={handleFilter}
          />
          <TypeSelect value={state.context.type} onChange={handleFilter} />
        </Stack>
      )}
      {claims.role === Roles.PARTNER && (
        <Typography
          data-testid="survey-count"
          variant="h3"
          fontSize="1.5em"
          fontWeight="bold"
          mb={2}
        >
          {`Active Surveys (${state.context.count})`}
        </Typography>
      )}
      <TableContainer>
        <Table sx={{ fontSize: '0.875em' }}>
          <TableHead>
            <TableRow>
              {tableHeadersList().map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.context.surveys.map((survey) => displayTableRow(survey))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          fontSize: '0.75em',
          [`& .MuiTablePagination-selectLabel, 
              .MuiTablePagination-displayedRows`]: { fontSize: '12px' },
        }}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={state.context.count}
        rowsPerPage={state.context.rowsPerPage}
        onRowsPerPageChange={(e) => {
          send({
            type: 'ROWS_PER_PAGE_CHANGE',
            data: parseInt(e.target.value),
          });
        }}
        page={state.context.currentPage}
        onPageChange={(_, v) => {
          send({
            type: 'PAGE_CHANGE',
            data: v,
          });
        }}
      />
    </Paper>
  );
};

export default SurveyTable;
