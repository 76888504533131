import { Box, Card } from '@mui/material';
import { ChildComponentProps } from '../codecs';

const FormContainer: React.FC<ChildComponentProps> = ({ children }) => {
  return (
    <Card
      sx={{
        maxWidth: '21rem',
        padding: '2.1875rem',
        py: '3.5rem',
        borderRadius: '0.25rem',
        fontSize: ['12px', '14px', '16px'],
      }}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="img"
          src="/hsi-logo.png"
          alt="HSI Logo"
          sx={{
            width: '195px',
            height: '82px',
          }}
        />
      </Box>
      {children}
    </Card>
  );
};

export default FormContainer;
