import ReactPlayer from 'react-player/youtube';

export type YoutubeEmbedProps = {
  url: string;
  width?: string;
  height?: string;
};

const YoutubeEmbed = ({
  url,
  width = '100%',
  height = '100%',
}: YoutubeEmbedProps) => (
  <ReactPlayer
    url={url}
    width={width}
    height={height}
    config={{
      playerVars: {
        fs: 1,
        controls: 1,
      },
    }}
  />
);

export default YoutubeEmbed;
