import { Widget } from '@typeform/embed-react';
import { Props } from '.';

const CompanySurvey = (props: Props) => {
  return (
    <>
      <Widget
        id={props.survey.formId}
        hidden={{
          email: 'ANONYMOUS',
          company: props.survey.companies?.[0]?.name ?? '',
          company_identifier: props.survey.companies?.[0]?.identifier ?? '',
          survey: props.survey.name,
          survey_identifier: props.survey.identifier,
        }}
      />
    </>
  );
};

export default CompanySurvey;
