import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import {
  Company,
  Product,
  SurveyWithCompanies,
  SurveyWithProduct,
} from '../../types';
import axios from 'axios';
import * as constants from '../../../constants';
import { getAuthHeaders } from '../../../authentication';
import { parallelServices } from '../../helpers/xstate';
import { FormValues } from './components/ConfigureSurveyModal';

type GetSurveysEventType = DoneInvokeEvent<{
  surveys: (SurveyWithCompanies & SurveyWithProduct)[];
  count: number;
}>;
type GetProductsEventType = DoneInvokeEvent<Product[]>;
type GetCompaniesEventType = DoneInvokeEvent<{
  results: Company[];
  count: number;
}>;
type ReloadEventType = {
  type: 'RELOAD';
};
type FilterEventType = {
  type: 'FILTER';
  prop: 'term' | 'productIdentifier' | 'type';
  data: string;
};
type PageChangeEventType = {
  type: 'PAGE_CHANGE';
  data: number;
};
type RowsPerPageChangeEventType = {
  type: 'ROWS_PER_PAGE_CHANGE';
  data: number;
};
type UpdateSurveyType = {
  type: 'SUBMIT';
  id: string;
  data: FormValues;
};
type EventType =
  | ReloadEventType
  | FilterEventType
  | GetSurveysEventType
  | GetProductsEventType
  | GetCompaniesEventType
  | PageChangeEventType
  | RowsPerPageChangeEventType
  | UpdateSurveyType;
type ContextType = {
  surveys: (SurveyWithCompanies & SurveyWithProduct)[];
  products: Product[];
  count: number;
  rowsPerPage: number;
  currentPage: number;
  term?: string;
  productIdentifier: string;
  type: 'ANY' | 'COMPANY' | 'INDIVIDUAL';
};

const surveyPageMachine = createMachine<ContextType, EventType>(
  {
    id: 'surveyPageMachine',
    initial: 'load_surveys',
    predictableActionArguments: true,
    context: {
      surveys: [],
      products: [],
      count: 0,
      rowsPerPage: 10,
      currentPage: 0,
      type: 'ANY',
      productIdentifier: 'ANY',
    },
    states: {
      load_surveys: {
        ...parallelServices([
          { src: 'getSurveys', actions: 'setSurveys' },
          { src: 'getProducts', actions: 'setProducts' },
        ]),
        onDone: 'load_success',
      },
      load_success: {
        on: {
          RELOAD: {
            target: 'load_surveys',
          },
          FILTER: {
            target: 'load_surveys',
            actions: 'setFilters',
          },
          ROWS_PER_PAGE_CHANGE: {
            target: 'load_surveys',
            actions: 'setRowsPerPage',
          },
          PAGE_CHANGE: {
            target: 'load_surveys',
            actions: 'setCurrentPage',
          },
          SUBMIT: {
            target: 'submitting',
          },
        },
      },
      load_error: {
        on: {
          RELOAD: {
            target: 'load_surveys',
          },
          FILTER: {
            target: 'load_surveys',
            actions: 'setFilters',
          },
          ROWS_PER_PAGE_CHANGE: {
            target: 'load_surveys',
            actions: 'setRowsPerPage',
          },
          PAGE_CHANGE: {
            target: 'load_surveys',
            actions: 'setCurrentPage',
          },
          SUBMIT: {
            target: 'submitting',
          },
        },
      },
      submitting: {
        invoke: {
          src: 'updateSurvey',
          onDone: 'submit_done',
          onError: 'load_success',
        },
      },
      submit_done: {
        after: {
          500: {
            target: 'load_surveys',
          },
        },
      },
    },
  },
  {
    services: {
      getSurveys: async (context, _) => {
        const skip = context.currentPage * context.rowsPerPage;

        const partners = await axios.get(
          constants.apiPaths.surveys.GET_ALL(
            skip,
            context.rowsPerPage,
            context.type,
          ),
          {
            ...getAuthHeaders(),
            params: {
              term: context.term,
              productIdentifier:
                context.productIdentifier === 'ANY'
                  ? undefined
                  : context.productIdentifier,
            },
          },
        );

        return partners.data;
      },
      getProducts: async () => {
        const products = await axios.get(
          constants.apiPaths.products.GET_ALL(),
          getAuthHeaders(),
        );

        return products.data;
      },
      updateSurvey: async (_, event) => {
        const { id, data } = event as UpdateSurveyType;
        await axios.put(
          constants.apiPaths.surveys.CONFIGURE_SURVEY(id),
          data,
          getAuthHeaders(),
        );
      },
    },
    actions: {
      setSurveys: assign((_, event) => {
        const {
          data: { surveys, count },
        } = event as GetSurveysEventType;
        return {
          surveys,
          count,
        };
      }),
      setProducts: assign((_, event) => {
        const { data: products } = event as GetProductsEventType;
        return {
          products,
        };
      }),
      setFilters: assign((_, event) => {
        const { prop, data } = event as FilterEventType;
        return {
          [prop]: data,
        };
      }),
      setRowsPerPage: assign((_, event) => ({
        rowsPerPage: (event as RowsPerPageChangeEventType).data,
        currentPage: 0,
      })),
      setCurrentPage: assign((_, event) => ({
        currentPage: (event as PageChangeEventType).data,
      })),
    },
  },
);

export default surveyPageMachine;
