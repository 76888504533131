import * as F from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

import { Alert, Button, TextField, Typography, useTheme } from '@mui/material';
import React from 'react';
import AuthenticationWrapper from '../AuthenticationWrapper';
import FormContainer from '../components/FormContainer';
import { useActor } from '@xstate/react';
import { AuthenticationContext } from '../..';
import { ReactNoop } from '../../_react';

const PasswordRecoveryForm = () => {
  const theme = useTheme();
  const context = React.useContext(AuthenticationContext);
  const [current, send] = useActor(context.authenticationService);

  const _emailAddress = F.pipe(
    current.context.emailAddress,
    O.getOrElse(F.constant('')),
  );

  return (
    <AuthenticationWrapper>
      <FormContainer>
        <Typography
          variant="h1"
          fontSize="2em"
          fontWeight="bold"
          align="center"
          mt="0.5625em"
          color={theme.palette.textPrimary.main}
        >
          Reset Password
        </Typography>
        <Typography
          mt="0.5625em"
          fontSize="1em"
          color={theme.palette.textPrimary.main}
        >
          Send a password reset link to the email address entered below. If they
          didn't receive an email it may be because that they have not signed up
          to HSI with that email.
        </Typography>
        <>
          {current.matches('reset_password_success') ? (
            <Alert
              sx={{
                mt: '2.125em',
                fontSize: '1em',
                '.MuiAlert-icon': {
                  fontSize: '1.375em',
                },
              }}
              severity="success"
            >
              Request sent. <br />
              You will receive an email if this user exists in the system.
            </Alert>
          ) : (
            <ReactNoop />
          )}
        </>
        <>
          {current.matches('reset_password_error') ? (
            <Alert
              sx={{
                mt: '2.125em',
                fontSize: '1em',
                '.MuiAlert-icon': {
                  fontSize: '1.375em',
                },
              }}
              severity="error"
            >
              There has been an error submitting this request.
            </Alert>
          ) : (
            <ReactNoop />
          )}
        </>
        <TextField
          label="Email Address"
          type="email"
          onChange={(e) =>
            send({
              type: 'ENTER_DATA',
              field: 'emailAddress',
              data: e.currentTarget.value,
            })
          }
          onFocus={(e) => send({ type: 'FOCUS' })}
          value={_emailAddress}
          disabled={current.matches('reset_password_start')}
          sx={{ mt: '2.1875em' }}
          fullWidth
          variant="outlined"
        />
        <Button
          sx={{ mt: '1.5em' }}
          fullWidth
          variant="contained"
          type="submit"
          disabled={current.matches('reset_password_start')}
          onClick={(e) => send({ type: 'SUBMIT' })}
        >
          SEND RECOVERY LINK
        </Button>
        <Typography
          mt="4.75em"
          align="center"
          color={theme.palette.textPrimary.main}
          fontSize="1em"
        >
          Remember your password?
        </Typography>
        <Button
          sx={{ mt: '1.5em' }}
          fullWidth
          variant="contained"
          onClick={() => send({ type: 'LOG_IN' })}
        >
          RETURN TO LOGIN
        </Button>
      </FormContainer>
    </AuthenticationWrapper>
  );
};

export default PasswordRecoveryForm;
