import { AppBar, Box, Typography } from '@mui/material';

const SurveyInactive = () => {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '3.75rem',
          px: '0.75rem',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Box width="124px" height="36px" position="relative" left={0}>
          <img src="/hsi-logo-white.png" alt="HSI Logo White" />
        </Box>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box>
          <img src="/login.png" />
          <Typography
            variant="h1"
            fontSize="2em"
            fontWeight="bold"
            align="center"
            mt="0.5625em"
          >
            This survey is no longer active.
          </Typography>

          <Typography variant="h4" fontSize="1em" align="center" mt="0.5625em">
            Looks like this survey has concluded - it is no longer accepting
            responses.
            <br />
            <br />
            You can close this window.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SurveyInactive;
