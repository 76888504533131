import { IconButton, MenuItem, Select as MuiSelect } from '@mui/material';
import { Company, Product, Survey } from '../types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';

export type SelectProps<T, U extends keyof T = keyof T> = {
  selectedValue?: T[U] | string;
  values: T[];
  defaultMenuItemLabel?: string;
  onChange: (data: T[U] | string) => void;
};

const Select = <
  T extends Product | Company | Pick<Survey, 'identifier' | 'name'>,
>({
  values,
  selectedValue,
  onChange,
  defaultMenuItemLabel,
}: SelectProps<T>) => {
  const defaultValue = 'ANY';
  const ClearIconComponent = () => (
    <IconButton size="small" onClick={() => onChange(defaultValue)}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <MuiSelect
      id="product"
      value={selectedValue}
      onChange={(e) => onChange(e.target.value)}
      IconComponent={
        selectedValue === defaultValue ? ArrowDropDownIcon : ClearIconComponent
      }
    >
      {defaultMenuItemLabel && (
        <MenuItem value={defaultValue}>{defaultMenuItemLabel}</MenuItem>
      )}
      {values.map((value) => (
        <MenuItem key={value.identifier} value={value.identifier}>
          {value.name}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default Select;
