import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import { lens, Lens } from 'monocle-ts';
import * as O from 'fp-ts/lib/Option';
import * as F from 'fp-ts/lib/function';
import * as E from 'fp-ts/lib/Either';
import * as T from 'fp-ts/lib/Task';
import * as TE from 'fp-ts/lib/TaskEither';
import * as c from './Authentication/codecs';
import * as t from 'io-ts';
import * as constants from '../constants';
import axios, { AxiosError } from 'axios';
import { isEmail } from '../validation';
import * as jose from 'jose';
import { HttpException } from './types';

export type ContextT = {
  emailAddress: O.Option<string>;
  password: O.Option<string>;
  accessToken: O.Option<string>;
  errorEmailMessage: O.Option<string>;
  errorPasswordMessage: O.Option<string>;
  firstName: O.Option<string>;
  lastName: O.Option<string>;
  phoneNumber: O.Option<string>;
} & {
  errObj?: HttpException;
};

enum EmailErrorMessage {
  'Email is required',
  'Must be a valid email format',
}

enum PasswordErrorMessage {
  'Password is required',
  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
}

type EnterDataT = {
  type: 'ENTER_DATA';
  field: keyof Omit<ContextT, 'access_token'>;
  data: string;
};
type LogoutT = {
  type: 'LOGOUT';
};
type SubmitT = {
  type: 'SUBMIT';
};
type ResetPasswordT = {
  type: 'RECOVER_PASSWORD';
};
type LoginT = {
  type: 'LOG_IN';
};
type FocusT = {
  type: 'FOCUS';
};
type RegisterT = {
  type: 'REGISTER';
};

type LoginErrorT = DoneInvokeEvent<AxiosError>;

type EventT =
  | EnterDataT
  | LogoutT
  | SubmitT
  | ResetPasswordT
  | LoginT
  | FocusT
  | RegisterT;

// applicative optic for the machine context
const contextLens = Lens.fromPath<ContextT>();

// takes a string and if empty folds it to {None}, otherwise folds it to {Some <value>}
const emptyStringToNone = (value: string): O.Option<string> =>
  F.pipe(value, O.fromNullable, O.chain(O.fromPredicate((v) => v.length > 0)));

// generic update method to update context specifically for data entry
const update_context_on_data_entry = assign(
  (context: ContextT, event: EnterDataT) =>
    F.pipe(event.data, emptyStringToNone, contextLens([event.field]).set, (f) =>
      f(context),
    ),
);

const validateInputBeforeSubmit = (context: ContextT, event: EnterDataT) => {
  context.errorPasswordMessage = context.password
    ? emptyStringToNone(PasswordErrorMessage[1])
    : emptyStringToNone('');
  context.errorEmailMessage =
    !isEmail(context.emailAddress.toString()) && context.emailAddress.toString()
      ? emptyStringToNone(EmailErrorMessage[1])
      : emptyStringToNone('');
};

const validateInputOnSubmit = (context: ContextT, event: EnterDataT) => {
  context.errorPasswordMessage = !context.password
    ? emptyStringToNone(PasswordErrorMessage[0])
    : context.errorPasswordMessage;
  context.errorEmailMessage = !context.emailAddress
    ? emptyStringToNone(EmailErrorMessage[0])
    : context.errorEmailMessage;
  return !(context.errorPasswordMessage && context.errorEmailMessage);
};

// The result of this function doesnt matter, its just used as type conversion tool
// between TaskEither<v> - Task<Option<v>>
const decodeError = (e: t.Errors): Error => {
  const missingKeys = e.map((e) => e.context.map(({ key }) => key).join('.'));
  return new Error(`${missingKeys}`);
};

const decode = (res: unknown): TE.TaskEither<Error, c.LoginRequest> =>
  F.pipe(res, c.LoginRequest.decode, TE.fromEither, TE.mapLeft(decodeError));

// The async login call.  Lifts to a TaskEither
const async_login = (data: {
  emailAddress: string;
  password: string;
}): TE.TaskEither<Error, unknown> =>
  TE.tryCatch(
    () =>
      axios.post(constants.apiPaths.auth.LOGIN(), data).then((res) => res.data),
    E.toError,
  );

// The actual, fully type safe login handler.
// It:
//      - checks if there is actually a value in the access token
//          - if not, it skips all subsequent steps by lifting to a TaskEither.left
//          - if so, it lifts the value to a TaskEiter.right
//      - does some chained mapping in the context to get the login data
//          - TODO: Use bind to get values in sync for cleaner magicks
//      - runs the actual async login code and flattens the nested TaskEithers.
//          - if there are any errors bypass the rest of the computation and throw - we are using unsafe handling
//            on purpose to cause the machine to behave how we want it to
//      - validates the incoming data to make sure that it passes the codec schema defined in `codecs.ts`
//          - if it fails it folds out to a Task<None> and throws for the same reason above
//      - assigns the typechecked, validated data to Context and updates the state machine.
//
// It is important to note that every step here is fully type safe.  The *ML combinators allow for very terse,
// very compact, compile-time guaranteed code, but I acknowledge this makes a lot of people weep and cry
// the first time they see this :)

const invoke_login = (context: ContextT, event: EventT) =>
  F.pipe(
    context.emailAddress,
    O.map((v) => ({ emailAddress: v })),
    O.chain((p) =>
      F.pipe(
        context.password,
        O.map((v) => ({ ...p, password: v })),
      ),
    ),
    TE.fromOption(() => F.pipe(O.none, E.toError)),
    TE.chain(async_login),
    TE.chain(decode),
    TE.foldW(
      (e) => {
        throw e;
      },
      (v) => T.of(v),
    ),
  );

const reset_password = (context: ContextT, event: EventT) => {
  const emailAddress = F.pipe(
    context.emailAddress,
    O.getOrElse(F.constant('')),
  );
  return axios.post(constants.apiPaths.auth.UPDATE_PASSWORD(), {
    email: emailAddress,
  });
};

const register_user = async (context: ContextT, event: EventT) => {
  const data = {
    emailAddress: F.pipe(context.emailAddress, O.getOrElse(F.constant(''))),
    firstName: F.pipe(context.firstName, O.getOrElse(F.constant(''))),
    lastName: F.pipe(context.lastName, O.getOrElse(F.constant(''))),
    phoneNumber: F.pipe(context.phoneNumber, O.getOrElse(F.constant(''))),
  };
  return axios
    .post(constants.apiPaths.auth.REGISTER_USER(), data)
    .then((res) => res.data);
};

const reset_values_after_registration = assign(
  (context: ContextT, event: DoneInvokeEvent<unknown>) =>
    F.pipe(
      context,
      contextLens(['firstName']).set(O.none),
      contextLens(['lastName']).set(O.none),
      contextLens(['password']).set(O.none),
      contextLens(['phoneNumber']).set(O.none),
    ),
);

const reset_registration_values = assign((context: ContextT, event: any) =>
  F.pipe(
    context,
    contextLens(['emailAddress']).set(O.none),
    contextLens(['firstName']).set(O.none),
    contextLens(['lastName']).set(O.none),
    contextLens(['password']).set(O.none),
    contextLens(['phoneNumber']).set(O.none),
  ),
);

const add_name_to_context_after_login = assign(
  (context: ContextT, event: DoneInvokeEvent<c.LoginRequest>) =>
    F.pipe(
      context,
      contextLens(['firstName']).set(O.some(event.data.first_name)),
    ),
);

const add_login_token_to_context = assign(
  (context: ContextT, event: DoneInvokeEvent<c.LoginRequest>) =>
    F.pipe(
      event.data.access_token,
      O.fromNullable,
      contextLens(['accessToken']).set,
      (f) => f(context),
    ),
);

const add_login_token_to_context_from_local_storage = assign(
  (context: ContextT, event: DoneInvokeEvent<c.LoginRequest>) =>
    F.pipe(
      localStorage.getItem(constants.TOKEN_STORAGE_KEY),
      O.fromNullable,
      contextLens(['accessToken']).set,
      (f) => f(context),
    ),
);

const add_login_to_local_storage = (
  context: ContextT,
  event: DoneInvokeEvent<c.LoginRequest>,
) =>
  F.pipe(event.data.access_token, (data) =>
    localStorage.setItem(constants.TOKEN_STORAGE_KEY, data),
  );

const remove_token_from_context = assign((context: ContextT, event: any) =>
  F.pipe(context, contextLens(['accessToken']).set(O.none)),
);

const clear_login_credentials_from_context = assign(
  (context: ContextT, event: any) =>
    F.pipe(
      context,
      contextLens(['password']).set(O.none),
      contextLens(['emailAddress']).set(O.none),
    ),
);

const remove_token_from_local_storage = (
  context: ContextT,
  event: DoneInvokeEvent<any> | EventT,
) => localStorage.removeItem(constants.TOKEN_STORAGE_KEY);

// Lift `getItem` into a promise.  If the token isn't there fail hard to cause a state
// change in the machine, otherwise write the token to storage and advance.
const check_token_exists_in_storage = (context: ContextT, event: EventT) =>
  F.pipe(
    window.localStorage.getItem(constants.TOKEN_STORAGE_KEY),
    O.fromNullable,
    O.fold(
      () => Promise.reject(),
      (d) => Promise.resolve(d),
    ),
  );

const check_token_is_valid = () =>
  axios.post(constants.apiPaths.auth.TOKEN_VALID(), {
    access_token: window.localStorage.getItem(constants.TOKEN_STORAGE_KEY),
  });

const add_token_to_local_storage_after_register = assign(
  (context: ContextT, event: DoneInvokeEvent<c.LoginRequest>) =>
    F.pipe(
      context,
      contextLens(['accessToken']).set(O.some(event.data.access_token)),
    ),
);

const set_error = assign((context: ContextT, event: LoginErrorT) => {
  const errorResponse = event.data.response?.data as unknown as HttpException;

  return F.pipe(context, contextLens(['errObj']).set(errorResponse));
});

export const loginMachine = createMachine<ContextT, EventT>({
  id: 'authenticationMachine',
  predictableActionArguments: true,
  context: {
    password: O.none,
    emailAddress: O.none,
    accessToken: O.none,
    errorEmailMessage: O.none,
    errorPasswordMessage: O.none,
    firstName: O.none,
    lastName: O.none,
    phoneNumber: O.none,
    errObj: undefined,
  },
  initial: 'init_check',
  states: {
    init_check: {
      invoke: {
        src: check_token_exists_in_storage,
        onDone: {
          target: 'check_token_valid',
        },
        onError: {
          target: 'not_authenticated',
          actions: 'setError',
        },
      },
    },
    not_authenticated: {
      on: {
        ENTER_DATA: {
          actions: [update_context_on_data_entry, validateInputBeforeSubmit],
        },
        SUBMIT: {
          target: 'login_call',
        },
        RECOVER_PASSWORD: {
          target: 'reset_password',
        },
        REGISTER: {
          target: 'register_user',
        },
      },
    },
    check_token_valid: {
      invoke: {
        src: check_token_is_valid,
        onDone: {
          target: 'is_authenticated',
          actions: [add_login_token_to_context_from_local_storage],
        },
        onError: {
          target: 'not_authenticated',
          actions: [remove_token_from_context, remove_token_from_local_storage],
        },
      },
    },
    login_call: {
      entry: [validateInputOnSubmit],
      invoke: {
        src: invoke_login,
        onDone: {
          target: 'is_authenticated',
          actions: [
            add_name_to_context_after_login,
            add_login_to_local_storage,
            add_login_token_to_context,
          ],
        },
        onError: {
          target: 'error_with_login',
          actions: [set_error],
        },
      },
    },
    is_authenticated: {
      entry: [clear_login_credentials_from_context],
      on: {
        LOGOUT: {
          target: 'not_authenticated',
          actions: [remove_token_from_context, remove_token_from_local_storage],
        },
        SUBMIT: {
          target: 'login_call',
          actions: [remove_token_from_context, remove_token_from_local_storage],
        },
      },
    },
    error_with_login: {
      on: {
        FOCUS: {
          target: 'not_authenticated',
        },
        RECOVER_PASSWORD: {
          target: 'reset_password',
        },
      },
    },
    reset_password: {
      on: {
        LOG_IN: {
          target: 'not_authenticated',
        },
        ENTER_DATA: {
          actions: [update_context_on_data_entry],
        },
        SUBMIT: {
          target: 'reset_password_start',
        },
      },
    },
    reset_password_start: {
      invoke: {
        src: reset_password,
        onDone: {
          target: 'reset_password_success',
        },
        onError: {
          target: 'reset_password_error',
        },
      },
      on: {
        FOCUS: {
          target: 'reset_password',
        },
        LOG_IN: {
          target: 'not_authenticated',
        },
      },
    },
    reset_password_success: {
      on: {
        FOCUS: {
          target: 'reset_password',
        },
        LOG_IN: {
          target: 'not_authenticated',
        },
      },
    },
    reset_password_error: {
      on: {
        FOCUS: {
          target: 'reset_password',
        },
        LOG_IN: {
          target: 'not_authenticated',
        },
      },
    },
    register_user: {
      on: {
        SUBMIT: {
          target: 'register_user_start',
        },
        LOG_IN: {
          target: 'not_authenticated',
        },
        ENTER_DATA: {
          actions: [update_context_on_data_entry],
        },
      },
    },
    register_user_start: {
      invoke: {
        src: register_user,
        onDone: {
          target: 'register_user_success',
          actions: [add_token_to_local_storage_after_register],
        },
        onError: {
          target: 'register_user_error',
        },
      },
    },
    register_user_success: {
      on: {
        LOG_IN: {
          target: 'not_authenticated',
        },
      },
      after: {
        2000: {
          target: 'is_authenticated',
        },
      },
    },
    register_user_error: {
      on: {
        SUBMIT: {
          target: 'register_user_start',
        },
        LOG_IN: {
          target: 'not_authenticated',
          actions: [reset_registration_values],
        },
        FOCUS: {
          target: 'register_user',
        },
      },
    },
  },
});
