import { Box } from '@mui/material';
import { createContext } from 'react';
import { Outlet } from 'react-router-dom';
import { InterpreterFrom } from 'xstate';
import partnerPageMachine from './machine';
import { useInterpret } from '@xstate/react';

export const PartnerPageContext = createContext(
  {} as InterpreterFrom<typeof partnerPageMachine>,
);

const PartnerPage = () => {
  const partnerService = useInterpret(partnerPageMachine);
  return (
    <PartnerPageContext.Provider value={partnerService}>
      <Box mt="1.25em">
        <Outlet />
      </Box>
    </PartnerPageContext.Provider>
  );
};

export default PartnerPage;
