import { Modal } from '../../Components';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';

import * as constants from '../../../constants';
import { getAuthHeaders } from '../../../authentication';

export type NewPassword = {
  password: string;
  password_confirmation: string;
};

type ResetPasswordModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const schema = z
  .object({
    password: z
      .string()
      .min(1, 'Password is required')
      .refine(
        (value) => value.length >= 4,
        'Password must be atleast 4 characters',
      ),
    password_confirmation: z
      .string()
      .min(1, 'Password is required')
      .refine(
        (value) => value.length >= 4,
        'Password must be atleast 4 characters',
      ),
  })
  .refine((data) => data.password === data.password_confirmation, {
    path: ['password_confirmation'],
    message: 'Passwords does not match.',
  });
export type FormValues = z.infer<typeof schema>;

const ResetPasswordModal = (props: ResetPasswordModalProps) => {
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const defaultValues = {
    password: '',
    password_confirmation: '',
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const handleClose = () => {
    reset(defaultValues);
    props.closeModal();
  };

  const onUpdatePassword = (password: string) =>
    axios
      .put(
        constants.apiPaths.profile.PUT_PASSWORD(),
        {
          password,
        },
        getAuthHeaders(),
      )
      .then((_) => setHasSuccess(true))
      .catch((_) => setHasError(true))
      .finally(() => {
        setTimeout(() => {
          handleClose();
          setHasSuccess(false);
          setHasError(false);
        }, 1000);
      });

  return (
    <Modal open={props.isOpen} onClose={handleClose}>
      <Typography
        variant="h1"
        fontSize="1.5em"
        fontWeight="bold"
        align="center"
        mt="0.5625em"
      >
        Set New Password
      </Typography>
      {hasError && (
        <Alert
          sx={{
            mt: '2.125em',
            fontSize: '1em',
            '.MuiAlert-icon': {
              fontSize: '1.375em',
            },
          }}
          severity="error"
        >
          Error updating password.
        </Alert>
      )}
      {hasSuccess && (
        <Alert
          sx={{
            mt: '2.125em',
            fontSize: '1em',
            '.MuiAlert-icon': {
              fontSize: '1.375em',
            },
          }}
          severity="success"
        >
          Password updated successfully.
        </Alert>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit((data) => onUpdatePassword(data.password))}
      >
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <TextField
              {...field}
              id="password"
              name="password"
              label="New password"
              type="password"
              sx={{ mt: '2.1875em' }}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              fullWidth
              variant="outlined"
            />
          )}
        />
        <Controller
          control={control}
          name="password_confirmation"
          render={({ field }) => (
            <>
              <TextField
                {...field}
                id="confirmPassword"
                name="confirmPassword"
                label="Retype new password"
                type="password"
                sx={{ mt: '2.1875em' }}
                error={Boolean(errors.password_confirmation)}
                helperText={errors.password_confirmation?.message}
                fullWidth
                variant="outlined"
              />
            </>
          )}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column-reverse', 'column-reverse', 'row'],
            justifyContent: [, , 'flex-end'],
          }}
        >
          <Button onClick={handleClose} sx={{ mt: '1.5em', mr: '0.625em' }}>
            CANCEL
          </Button>
          <Button sx={{ mt: '1.5em' }} variant="contained" type="submit">
            UPDATE PASSWORD
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResetPasswordModal;
