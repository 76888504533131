import * as F from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import React from 'react';
import { AuthenticationContext } from '../../index';
import { useActor } from '@xstate/react';
import AuthenticationWrapper from '../AuthenticationWrapper';
import FormContainer from '../components/FormContainer';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link as MUILink,
  Alert,
  useTheme,
} from '@mui/material';
import { ReactNoop } from '../../_react';

const RegisterUserForm = () => {
  const theme = useTheme();
  const services = React.useContext(AuthenticationContext);
  const [current, send] = useActor(services.authenticationService);

  const _email = F.pipe(
    current.context.emailAddress,
    O.fold(F.constant(''), F.identity),
  );
  const _password = F.pipe(
    current.context.password,
    O.fold(F.constant(''), F.identity),
  );
  const _firstName = F.pipe(
    current.context.firstName,
    O.fold(F.constant(''), F.identity),
  );
  const _lastName = F.pipe(
    current.context.lastName,
    O.fold(F.constant(''), F.identity),
  );
  const _phoneNumber = F.pipe(
    current.context.phoneNumber,
    O.fold(F.constant(''), F.identity),
  );

  return (
    <AuthenticationWrapper>
      <FormContainer>
        <Typography
          variant="h1"
          fontSize="2em"
          fontWeight="bold"
          align="center"
          mt="0.5625em"
        >
          Register with HSI
        </Typography>
        <>
          {current.matches('register_user_success') ? (
            <Alert
              sx={{
                mt: '2.125em',
                fontSize: '1em',
                '.MuiAlert-icon': {
                  fontSize: '1.375em',
                },
              }}
              severity="success"
            >
              Account created. Check your email.
            </Alert>
          ) : (
            <ReactNoop />
          )}
        </>
        <>
          {current.matches('register_user_error') ? (
            <Alert
              sx={{
                mt: '2.125em',
                fontSize: '1em',
                '.MuiAlert-icon': {
                  fontSize: '1.375em',
                },
              }}
              severity="error"
            >
              Error creating account.
            </Alert>
          ) : (
            <ReactNoop />
          )}
        </>
        <TextField
          label="Email Address"
          type="email"
          value={_email}
          disabled={current.matches('register_user_start')}
          onChange={(e) =>
            send({
              type: 'ENTER_DATA',
              field: 'emailAddress',
              data: e.currentTarget.value,
            })
          }
          onFocus={(e) => send({ type: 'FOCUS' })}
          sx={{
            marginTop: '2.155em',
          }}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="First Name"
          type="text"
          value={_firstName}
          disabled={current.matches('register_user_start')}
          onChange={(e) =>
            send({
              type: 'ENTER_DATA',
              field: 'firstName',
              data: e.currentTarget.value,
            })
          }
          onFocus={(e) => send({ type: 'FOCUS' })}
          sx={{ marginTop: '2.155em' }}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="Last Name"
          type="text"
          value={_lastName}
          disabled={current.matches('register_user_start')}
          onChange={(e) =>
            send({
              type: 'ENTER_DATA',
              field: 'lastName',
              data: e.currentTarget.value,
            })
          }
          onFocus={(e) => send({ type: 'FOCUS' })}
          sx={{ marginTop: '2.155em' }}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="Phone Number"
          type="text"
          value={_phoneNumber}
          disabled={current.matches('register_user_start')}
          onChange={(e) =>
            send({
              type: 'ENTER_DATA',
              field: 'phoneNumber',
              data: e.currentTarget.value,
            })
          }
          onFocus={(e) => send({ type: 'FOCUS' })}
          sx={{ marginTop: '2.155em' }}
          fullWidth
          variant="outlined"
        />
        <Button
          sx={{ marginTop: '2.1875em' }}
          fullWidth
          variant="contained"
          type="submit"
          disabled={current.matches('register_user_start')}
          onClick={(e) => send({ type: 'SUBMIT' })}
        >
          REGISTER
        </Button>
        <Typography
          mt="4.75em"
          align="center"
          color={theme.palette.textPrimary.main}
          fontSize="1em"
        >
          Have an account?
        </Typography>
        <Button
          sx={{ mt: '1.5em' }}
          fullWidth
          variant="contained"
          onClick={() => send({ type: 'LOG_IN' })}
        >
          RETURN TO LOGIN
        </Button>
      </FormContainer>
    </AuthenticationWrapper>
  );
};

export default RegisterUserForm;
