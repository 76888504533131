import * as F from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import React from 'react';
import { AuthenticationContext } from '../../index';
import { useActor } from '@xstate/react';
import AuthenticationWrapper from '../AuthenticationWrapper';
import FormContainer from '../components/FormContainer';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link as MUILink,
  Alert,
  useTheme,
} from '@mui/material';
import { ReactNoop } from '../../_react';

const UserRegistered = () => {
  const theme = useTheme();
  const services = React.useContext(AuthenticationContext);
  const [current, send] = useActor(services.authenticationService);

  const _email = F.pipe(
    current.context.emailAddress,
    O.fold(F.constant(''), F.identity),
  );
  const _password = F.pipe(
    current.context.password,
    O.fold(F.constant(''), F.identity),
  );
  const _firstName = F.pipe(
    current.context.firstName,
    O.fold(F.constant(''), F.identity),
  );
  const _lastName = F.pipe(
    current.context.lastName,
    O.fold(F.constant(''), F.identity),
  );
  const _phoneNumber = F.pipe(
    current.context.phoneNumber,
    O.fold(F.constant(''), F.identity),
  );

  return (
    <AuthenticationWrapper>
      <FormContainer>
        <Typography
          variant="h1"
          fontSize="2em"
          fontWeight="bold"
          align="center"
          mt="0.5625em"
        >
          Register with HSI
        </Typography>
        <>
          <Alert
            sx={{
              mt: '2.125em',
              fontSize: '1em',
              '.MuiAlert-icon': {
                fontSize: '1.375em',
              },
            }}
            severity="success"
          >
            Account created. Check your email for a confirmation message.
          </Alert>
        </>
        <Button
          sx={{ mt: '1.5em' }}
          fullWidth
          variant="contained"
          onClick={() => send({ type: 'LOG_IN' })}
        >
          RETURN TO LOGIN
        </Button>
      </FormContainer>
    </AuthenticationWrapper>
  );
};

export default UserRegistered;
