const host = process.env.REACT_APP_BACKEND_HOST;

export const TOKEN_STORAGE_KEY = 'hsi-jwt-token';

export enum Roles {
  GLOBAL_ADMIN = 'GLOBAL_ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  SURVEY_USER = 'SURVEY_USER',
  PRODUCT_OWNER = 'PRODUCT_OWNER',
  PARTNER = 'PARTNER',
}

export const routePaths = {
  SURVEYS: '/surveys',
  PASSWORD_RESET: 'reset',
  surveys: {
    INDEX: '/survey',
    TO_SURVEY: (surveyId: string, companyId?: string) =>
      `/surveys?companyId=${companyId}&surveyId=${surveyId}`,
    TO_SURVEY_LINK: (surveyId: string, companyId?: string) =>
      `${process.env.REACT_APP_HOST}/surveys?companyId=${companyId}&surveyId=${surveyId}`,
  },

  PROFILE: '/profile',
  PARTNERS: {
    INDEX: '/partners',
    PROFILE: ':identifier',
    TO_PROFILE: (identifier: string) => `/partners/${identifier}`,
  },
  RESOURCES: '/resources',
  COMPANIES: '/companies',
  PRODUCT_OWNERS: '/productOwners',
  companies: {
    ALL: '/companies/all',
    PROFILE: 'companies/:identifier',
    TO_PROFILE: (identifier: string) => `/companies/${identifier}/`,
    SURVEY_HISTORY: ':identifier/history',
    TO_SURVEY_HISTORY: (identifier: string) => `${identifier}/`,
  },
};

export const apiPaths = {
  auth: {
    LOGIN: () => `${host}/auth/login`,
    TOKEN_VALID: () => `${host}/auth/validate`,
    UPDATE_PASSWORD: () => `${host}/auth/resetPassword`,
    REGISTER_USER: () => `${host}/auth/register`,
  },
  companies: {
    CREATE: () => `${host}/companies`,
    GET_ALL: (from: number, size: number) =>
      `${host}/companies?size=${size}&from=${from}`,
    COMPANY: (identifier: string) => `${host}/companies/${identifier}`,
    SURVEYS: (identifier: string, skip?: number, take?: number) =>
      `${host}/companies/${identifier}/surveys?skip=${skip}&take=${take}`,
    GET_COMPANY_SURVEYS: (
      identifier: string,
      skip?: number,
      take?: number,
      inactive?: boolean,
      productIdentifier?: string,
    ) =>
      `${host}/companies/${identifier}/surveys?skip=${skip}&take=${take}&inactive=${inactive}&productIdentifier=${productIdentifier}`,
    GET_AVAILABLE_BY_PARTNER: (
      from: number,
      size: number,
      partnerIdentifier: string,
    ) =>
      `${host}/companies?size=${size}&from=${from}&partnerIdentifier=${partnerIdentifier}`,
    GET_AVAILABLE_BY_PO: (from: number, size: number, poIdentifier: string) =>
      `${host}/companies?size=${size}&from=${from}&poIdentifier=${poIdentifier}`,
  },
  profile: {
    GET_PROFILE: () => `${host}/users/profile`,
    PUT_PROFILE: () => `${host}/users/profile`,
    PUT_PASSWORD: () => `${host}/users/profile/change-password`,
  },
  surveys: {
    GET_ALL: (
      skip: number,
      take: number,
      type: 'ANY' | 'COMPANY' | 'INDIVIDUAL',
    ) => `${host}/surveys?type=${type}&skip=${skip}&take=${take}`,
    GET_SURVEY: (identifier: string, companyId?: string) =>
      `${host}/surveys/${identifier}?companyId=${companyId}`,
    ACTIVATE_SURVEY: (identifier: string) =>
      `${host}/surveys/${identifier}/activate`,
    DEACTIVATE_SURVEY: (identifier: string) =>
      `${host}/surveys/${identifier}/deactivate`,
    GENERATE_REPORT: (identifier: string) =>
      `${host}/surveys/${identifier}/generateReport`,
    SUBMIT_SURVEY: () => `${host}/surveys/submit`,
    FINAL_REPORT: (surveyId: string, companyId: string) =>
      `${host}/surveys/${surveyId}/finalReport?companyId=${companyId}`,
    UNLINK_SURVEY: (surveyId: string, companyId: string) =>
      `${host}/surveys/${surveyId}/company/${companyId}`,
    CONFIGURE_SURVEY: (surveyId: string) => `${host}/surveys/${surveyId}`,
    GET_COMPANIES: (identifier: string, skip: number, take: number) =>
      `${host}/surveys/${identifier}/companies?skip=${skip}&take=${take}`,
  },
  typeform: {
    GET: () => `${host}/typeform/forms`,
  },
  sendgrid: {
    POST: () => `${host}/sendgrid`,
  },
  qlik: {
    REPORT: (identifier: string) => `${host}/qlik/${identifier}`,
    UPDATE: (identifier: string) => `${host}/qlik/${identifier}/update`,
  },
  tutorials: {
    GET_ALL: (skip: number, take: number) =>
      `${host}/tutorials?skip=${skip}&take=${take}`,
    DELETE: (id: string) => `${host}/tutorials/${id}`,
    ADD: () => `${host}/tutorials`,
  },
  files: {
    GET_ALL: (skip: number, take: number) =>
      `${host}/files?skip=${skip}&take=${take}`,
    DELETE: (id: string) => `${host}/files/${id}`,
    ADD: () => `${host}/files`,
  },
  partners: {
    GET_ALL: (skip: number, take: number) =>
      `${host}/partners?skip=${skip}&take=${take}`,
    PARTNER: (identifier: string) => `${host}/partners/${identifier}`,
    GET_COMPANIES: (identifier: string, skip: number, take: number) =>
      `${host}/partners/${identifier}/companies?skip=${skip}&take=${take}`,
    GET: (id: string) => `${host}/partners/${id}`,
    CREATE: () => `${host}/partners`,
    LINK_COMPANIES: (identifier: string) =>
      `${host}/partners/${identifier}/companies`,
    GET_AVAILABLE_BY_PO: (skip: number, take: number, poIdentifier: string) =>
      `${host}/partners?skip=${skip}&take=${take}&poIdentifier=${poIdentifier}`,
  },
  products: {
    GET_ALL: () => `${host}/products`,
    GET_ALL_BY_PARTNER: (partnerIdentifier: string) =>
      `${host}/products?partnerIdentifier=${partnerIdentifier}`,
    GET_COMPANY_PRODUCT_SURVEYS: (identifier: string) =>
      `${host}/products?companyIdentifier=${identifier}`,
    LINK_COMPANIES: (id: string) => `${host}/products/${id}/companies`,
    LINK_PARTNERS: (id: string) => `${host}/products/${id}/partners`,
  },
  productOwners: {
    GET_ALL: () => `${host}/product-owners`,
    PRODUCT_OWNER: (identifier: string) =>
      `${host}/product-owners/${identifier}`,
    LINK_PARTNERS: (id: string) => `${host}/product-owners/${id}/partners`,
    LINK_COMPANIES: (id: string) => `${host}/product-owners/${id}/companies`,
  },
};
