import * as React from 'react';
import * as F from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import axios from 'axios';
import { Widget } from '@typeform/embed-react';
import { useActor } from '@xstate/react';

import { AuthenticationContext } from '../..';
import { Props } from '.';
import Authentication from '../../Authentication';
import { apiPaths } from '../../../constants';
import { getAuthHeaders } from '../../../authentication';

const IndividualSurvey = (props: Props) => {
  const authenticationContext = React.useContext(AuthenticationContext);
  const [authCurrent] = useActor(authenticationContext.authenticationService);

  const _email = F.pipe(
    authCurrent.context.emailAddress,
    O.foldW(F.constant('ANONYMOUS'), F.identity),
  );

  const _firstName = F.pipe(
    authCurrent.context.firstName,
    O.foldW(F.constant('ANONYMOUS'), F.identity),
  );

  const _submitResponse = (e: { responseId: string }) =>
    axios.post(
      apiPaths.surveys.SUBMIT_SURVEY(),
      {
        surveyId: props.survey.identifier,
        responseId: e.responseId,
      },
      getAuthHeaders(),
    );

  return (
    <>
      {authCurrent.matches('is_authenticated') ? (
        <Widget
          id={props.survey.formId}
          onSubmit={_submitResponse}
          hidden={{
            email: _email,
            first_name: _firstName,
            company: props.survey.companies?.[0]?.name ?? '',
            company_identifier: props.survey.companies?.[0]?.identifier ?? '',
            survey: props.survey.name,
            survey_identifier: props.survey.identifier,
          }}
        />
      ) : (
        <Authentication />
      )}
    </>
  );
};

export default IndividualSurvey;
