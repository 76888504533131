import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import * as constants from '../../../constants';
import axios from 'axios';
import { getAuthHeaders } from '../../../authentication';
import { Product } from '../../types';

export type ContextT = {
  products: Product[];
  productId: string;
  companyIdentifier?: string;
};
type GetProductsEventT = DoneInvokeEvent<Product[]>;
type SelectProductT = {
  type: 'SELECT_PRODUCT';
  data: string;
};

type EventT = GetProductsEventT | SelectProductT;

export const productSelectMachine = (companyIdentifier?: string) =>
  createMachine<ContextT, EventT>(
    {
      predictableActionArguments: true,
      preserveActionOrder: true,
      context: {
        products: [],
        productId: 'ANY',
        companyIdentifier,
      },
      initial: 'load_products',
      states: {
        load_products: {
          invoke: {
            src: 'getProducts',
            onDone: {
              target: 'load_products_done',
              actions: 'setProducts',
            },
            onError: 'load_products_error',
          },
        },
        load_products_done: {
          on: {
            SELECT_PRODUCT: {
              actions: 'setProductId',
            },
          },
        },
        load_products_error: {
          on: {
            SELECT_PRODUCT: {
              actions: 'setProductId',
            },
          },
        },
      },
    },
    {
      services: {
        getProducts: async (context) => {
          const products = await axios.get(
            constants.apiPaths.products.GET_COMPANY_PRODUCT_SURVEYS(
              context.companyIdentifier as string,
            ),
            getAuthHeaders(),
          );

          return products.data;
        },
      },
      actions: {
        setProducts: assign((_, event: EventT) => {
          const { data: products } = event as GetProductsEventT;
          return {
            products,
            productId: products[0].identifier,
          };
        }),
        setProductId: assign((_, event: EventT) => {
          return {
            productId: (event as SelectProductT).data,
          };
        }),
      },
    },
  );

export type SetEditingEventT = {
  type: 'SET_EDITING';
};

export type SetSavingEventT = {
  type: 'SET_SAVING';
};

export type SetNextEventT = {
  type: 'NEXT_PATH';
};

export type SetSaveSuccessEventT = {
  type: 'SET_SAVE_SUCCESS';
};

export type SetConfirmingEventT = {
  type: 'SET_CONFIRMING';
  data: string;
};

export type SetIdleEventT = {
  type: 'SET_IDLE';
};

type FormStateContext = {
  next: string;
};
type FormStateEventT =
  | SetEditingEventT
  | SetSavingEventT
  | SetNextEventT
  | SetSaveSuccessEventT
  | SetIdleEventT
  | SetConfirmingEventT;

export const formStateMachine = createMachine<
  FormStateContext,
  FormStateEventT
>(
  {
    context: {
      next: '',
    },
    initial: 'idle',
    states: {
      idle: {
        on: {
          SET_EDITING: {
            target: 'is_editing',
          },
          NEXT_PATH: {
            actions: 'nextFunction',
          },
        },
      },
      is_editing: {
        on: {
          SET_CONFIRMING: {
            target: 'confirming',
            actions: 'setNextPage',
          },
          SET_IDLE: {
            target: 'idle',
          },
        },
      },
      confirming: {
        on: {
          SET_EDITING: {
            target: 'is_editing',
          },
          SET_IDLE: {
            target: 'idle',
          },
        },
      },
    },
  },
  {
    actions: {
      setNextPage: assign((_: FormStateContext, event: FormStateEventT) => ({
        next: (event as SetConfirmingEventT).data,
      })),
    },
  },
);
