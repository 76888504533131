import axios from 'axios';
import { assign, createMachine, DoneInvokeEvent } from 'xstate';

import * as constants from '../../../../../constants';
import { getAuthHeaders } from '../../../../../authentication';
import { Partner } from '../../../../types';

export type ContextT = {
  identifier: string;
  partner?: Partner;
};
type LoadPartnerT = DoneInvokeEvent<Partner>;
type ReloadPartnerT = {
  type: 'RELOAD_PARTNER';
};

type EventT = LoadPartnerT | ReloadPartnerT;

const loadPartner = (context: ContextT, event: EventT) =>
  axios
    .get(
      constants.apiPaths.partners.PARTNER(context.identifier),
      getAuthHeaders(),
    )
    .then((res) => res.data);

export const loadPartnerMachine = (identifier: string) =>
  createMachine<ContextT, EventT>(
    {
      id: identifier,
      predictableActionArguments: true,
      preserveActionOrder: true,
      context: {
        identifier: identifier,
      },
      initial: 'load_partner_start',
      states: {
        load_partner_start: {
          invoke: {
            src: loadPartner,
            onDone: {
              target: 'load_partner_success',
              actions: 'setPartner',
            },
            onError: {
              target: 'load_partner_error',
            },
          },
        },
        load_partner_success: {
          on: {
            RELOAD_PARTNER: {
              target: 'load_partner_start',
            },
          },
        },
        load_partner_error: {},
        idle: {
          on: {},
        },
      },
    },
    {
      actions: {
        setPartner: assign((_, event: EventT) => {
          return {
            partner: (event as LoadPartnerT).data,
          };
        }),
      },
    },
  );
