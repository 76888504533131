import { Button, TextField, Typography, useTheme, Alert } from '@mui/material';
import AuthenticationWrapper from '../AuthenticationWrapper';
import FormContainer from '../components/FormContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import resetPasswordMachine from './machine';
import { ReactNoop } from '../../_react';

const PasswordRecoveryForm = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const [current, send] = useMachine(resetPasswordMachine(token as string));

  //todo: remove data state and replace with state machine, implement sendgrid api
  //todo: only return server error related to unavailable otherwise success
  return (
    <AuthenticationWrapper>
      <FormContainer>
        <Typography
          variant="h1"
          fontSize="2em"
          fontWeight="bold"
          align="center"
          mt="0.5625em"
          color={theme.palette.textPrimary.main}
        >
          Reset Password
        </Typography>
        {current.matches('success') ? (
          <Alert
            sx={{
              mt: '2.125em',
              fontSize: '1em',
              '.MuiAlert-icon': {
                fontSize: '1.375em',
              },
            }}
            severity="success"
          >
            Successfully reset password.
          </Alert>
        ) : (
          <ReactNoop />
        )}

        {current.matches('error') ? (
          <Alert
            sx={{
              mt: '2.125em',
              fontSize: '1em',
              '.MuiAlert-icon': {
                fontSize: '1.375em',
              },
            }}
            severity="error"
          >
            Error resetting password.
          </Alert>
        ) : (
          <ReactNoop />
        )}
        <Typography
          mt="0.5625em"
          fontSize="1em"
          textAlign="center"
          color={theme.palette.textPrimary.main}
        >
          Enter your new password
        </Typography>

        <TextField
          label="Enter Password"
          type="password"
          onFocus={(e) => send({ type: 'FOCUS' })}
          onChange={(e) =>
            send({ type: 'ADD_DATA', data: e.currentTarget.value })
          }
          value={current.context.password}
          disabled={current.matches('start') === true}
          sx={{ mt: '2.1875em' }}
          fullWidth
          variant="outlined"
        />
        <Button
          sx={{ mt: '1.5em' }}
          fullWidth
          variant="contained"
          type="submit"
          onClick={() => send({ type: 'SUBMIT' })}
          disabled={
            current.context.password.length === 0 || current.matches('start')
          }
        >
          UPDATE PASSWORD
        </Button>
        <Typography
          mt="4.75em"
          align="center"
          color={theme.palette.textPrimary.main}
          fontSize="1em"
        >
          Remember your password?
        </Typography>
        <Button
          sx={{ mt: '1.5em' }}
          fullWidth
          variant="contained"
          onClick={() => navigate('/')}
        >
          RETURN TO LOGIN
        </Button>
      </FormContainer>
    </AuthenticationWrapper>
  );
};

export default PasswordRecoveryForm;
