import * as React from 'react';
import { Box } from '@mui/material';

type Authentication = {
  children: React.ReactNode;
};

const AuthenticationWrapper = (props: Authentication) => {
  return (
    <Box>
      <Box position="fixed" height="100vh" width="100vw" zIndex={-1}>
        <Box
          component="img"
          src="/background.jpg"
          alt="background"
          sx={{
            objectFit: 'cover',
            height: '100vh',
            width: '100vw',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default AuthenticationWrapper;
