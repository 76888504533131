import { Box } from '@mui/material';
import { createContext } from 'react';
import { Outlet } from 'react-router-dom';
import { InterpreterFrom } from 'xstate';
import { useInterpret } from '@xstate/react';
import SurveyPageMachine from './machine';

export const SurveyPageContext = createContext(
  {} as InterpreterFrom<typeof SurveyPageMachine>,
);

const SurveyPage = () => {
  const surveyService = useInterpret(SurveyPageMachine);
  return (
    <SurveyPageContext.Provider value={surveyService}>
      <Box mt="1.25em">
        <Outlet />
      </Box>
    </SurveyPageContext.Provider>
  );
};

export default SurveyPage;
