import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Link as MuiLink,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Typography,
  Stack,
  FormControl,
  SxProps,
  Theme,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import EditCompanyModal from './EditCompanyModal';
import DeleteCompanyModal from './DeleteCompanyModal';
import { CompanyContext } from '.';
import { useActor } from '@xstate/react';
import AddCompanyModal from './AddCompanyModal';
import { ReactNoop } from '../../_react';
import { Roles, routePaths } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { Company, Product } from '../../types';
import { useJwtClaims } from '../../hooks';
import { Select } from '../../common';
import SearchField from '../../common/SearchField';

type ProductSelectProps = {
  selectedProductId: string;
  products: Product[];
  onChange: (data: string) => void;
};

const ProductSelect = ({
  products,
  selectedProductId,
  onChange,
}: ProductSelectProps) => {
  return (
    <FormControl
      sx={{
        '& .MuiInputBase-root': {
          height: '2.375em',
        },
        width: '30%',
      }}
    >
      <Select
        defaultMenuItemLabel="Any connected product"
        selectedValue={selectedProductId}
        values={products}
        onChange={(data) => {
          onChange(data as string);
        }}
      />
    </FormControl>
  );
};

const StyledTableCell = styled(TableCell)<TableCellProps>(() => ({
  fontSize: '0.875em',
}));

type CompanyTableRowProps = {
  company: Company;
  send: (event: any) => void;
};

type TableCellData = {
  name: string;
  sx?: SxProps<Theme>;
};

const CompanyTableRow = (props: CompanyTableRowProps): JSX.Element => {
  const navigate = useNavigate();
  const claims = useJwtClaims();
  const isPartner = claims.role === Roles.PARTNER;

  const [editModalOpen, setModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteOpen] = useState<boolean>(false);

  const _email =
    props.company.primaryContact.emailAddress.length >= 25
      ? `${props.company.primaryContact.emailAddress.substring(0, 22)}...`
      : props.company.primaryContact.emailAddress.substring(0, 25);

  const isAdmin = claims.role === Roles.GLOBAL_ADMIN;
  const isOwner = claims.role === Roles.PRODUCT_OWNER;

  return (
    <>
      {isAdmin && editModalOpen && (
        <EditCompanyModal
          isOpen={editModalOpen}
          closeModal={() => setModalOpen(false)}
          company={props.company}
          onDone={() => {
            props.send({ type: 'REFRESH_COMPANIES_TABLE' });
          }}
        />
      )}
      {isAdmin && (
        <DeleteCompanyModal
          isOpen={deleteModalOpen}
          closeModal={() => setDeleteOpen(false)}
          company={props.company}
          onDone={() => props.send({ type: 'REFRESH_COMPANIES_TABLE' })}
        />
      )}
      <TableRow key={props.company.identifier}>
        <StyledTableCell>{props.company.name}</StyledTableCell>
        <StyledTableCell
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {_email}
        </StyledTableCell>
        {isOwner && (
          <StyledTableCell>
            <Stack>
              {props.company.products.map((product) => (
                <Typography key={product.id} sx={{ fontSize: '14px' }}>
                  {product.name}
                </Typography>
              ))}
            </Stack>
          </StyledTableCell>
        )}
        {!isPartner && (
          <StyledTableCell>
            <Stack>
              {isAdmin
                ? props.company.products.map((product) => (
                    <Typography key={product.id} sx={{ fontSize: '14px' }}>
                      {product.name}
                    </Typography>
                  ))
                : props.company.partners.map((partner) => (
                    <Typography key={partner.id} sx={{ fontSize: '14px' }}>
                      {partner.name}
                    </Typography>
                  ))}
            </Stack>
          </StyledTableCell>
        )}
        <StyledTableCell>{props.company.surveys.length}</StyledTableCell>
        <StyledTableCell>
          {props.company.surveys.filter((s) => s.isActive === true).length}
        </StyledTableCell>
        <StyledTableCell>
          <Stack direction="row" spacing={2}>
            <MuiLink
              onClick={() =>
                navigate(
                  routePaths.companies.TO_PROFILE(props.company.identifier),
                )
              }
              sx={{ cursor: 'pointer' }}
            >
              View Profile
            </MuiLink>
            {isAdmin && (
              <>
                <MuiLink
                  onClick={() => setModalOpen(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  Edit
                </MuiLink>
                <MuiLink
                  onClick={() => setDeleteOpen(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  Delete
                </MuiLink>
              </>
            )}
          </Stack>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

const CompanyTable = () => {
  const [addCompanyModalOpen, setAddCompanyModal] = React.useState(false);
  const claims = useJwtClaims();

  const context = React.useContext(CompanyContext);
  const [current, send] = useActor(context.companyService);

  const isAdmin = claims.role === Roles.GLOBAL_ADMIN;
  const isPartner = claims.role === Roles.PARTNER;
  const isOwner = claims.role === Roles.PRODUCT_OWNER;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) =>
    send({ type: 'CHANGE_ROWS_PER_PAGE', data: parseInt(event.target.value) });

  const headers: TableCellData[] = [
    {
      name: 'Company Name',
    },
    {
      name: 'Email Address',
      sx: { maxWidth: '100px' },
    },
    {
      name: isAdmin ? 'Connected Products' : 'Partner',
    },
    {
      name: 'Total Surveys',
    },
    {
      name: 'Active Surveys	',
    },
    {
      name: 'Actions',
    },
  ];

  if (isOwner) {
    headers.splice(2, 0, { name: 'Connected Products' });
  }

  const handleFilter: (data: string) => void = useCallback(
    (data) => {
      send({ type: 'ENTER_SEARCH', data });
    },
    [send],
  );

  return (
    <>
      {addCompanyModalOpen && (
        <AddCompanyModal
          isOpen={addCompanyModalOpen}
          closeModal={() => setAddCompanyModal(false)}
        />
      )}
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h1"
          fontSize="2em"
          fontWeight="bold"
          data-testid="title"
        >
          Companies
        </Typography>
        {claims.role === Roles.GLOBAL_ADMIN ? (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setAddCompanyModal(true)}
          >
            ADD NEW COMPANY
          </Button>
        ) : (
          <ReactNoop />
        )}
      </Box>
      <Paper sx={{ mt: '1.25rem', p: '2.125em' }}>
        <Stack direction="row" spacing="14px" mb="1em">
          <SearchField onChange={handleFilter} label="Search name of company" />
          <ProductSelect
            selectedProductId={current.context.productId as string}
            products={current.context.products}
            onChange={(data) => {
              send({ type: 'SELECT_PRODUCT', data });
            }}
          />
        </Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headers
                  .filter((item) => !(isPartner && item.name === 'Partner'))
                  .map((header) => (
                    <StyledTableCell key={header.name} sx={header.sx}>
                      {header.name}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {current.context.companies.map((company) => (
                <CompanyTableRow
                  company={company}
                  key={company.identifier}
                  send={send}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={current.context.count}
          rowsPerPage={current.context.rowsPerPage}
          page={current.context.currentPage}
          onPageChange={(_, v) =>
            send({ type: 'CHANGE_CURRENT_PAGE', data: v })
          }
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default CompanyTable;
