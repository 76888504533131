import { assign, createMachine, DoneInvokeEvent, MachineConfig } from 'xstate';
import * as constants from '../../../../../../constants';
import axios from 'axios';
import { getAuthHeaders } from '../../../../../../authentication';
import { Company, Product, ProductOwner } from '../../../../../types';
import { parallelServices } from '../../../../../helpers/xstate';

export type ContextT = {
  identifier: string;
  companies: Company[];
  partnerCompanies: Company[];
  products: Product[];
  productOwners: ProductOwner[];
  size: number;
  from: number;
  count: number;
  search: string;
  productIdentifier: string;
  currentPage: number;
  rowsPerPage: number;
};
type GetCompaniesEventT = DoneInvokeEvent<{
  count: number;
  companies: Company[];
}>;
type GetProductsEventT = DoneInvokeEvent<Product[]>;
type GetProductOwnersEventT = DoneInvokeEvent<ProductOwner[]>;
type EnterSearchEventT = {
  type: 'ENTER_SEARCH';
  data: string;
};
type ChangeCurrentPageT = {
  type: 'CHANGE_CURRENT_PAGE';
  data: number;
};
type ChangeRowsPerPageT = {
  type: 'CHANGE_ROWS_PER_PAGE';
  data: number;
};
type SelectActiveCompanyT = {
  type: 'SELECT_COMPANY';
  data: string;
};
type SelectProductT = {
  type: 'SELECT_PRODUCT';
  data: string;
};
type DeleteCompanyT = {
  type: 'DELETE_COMPANY';
  data: string;
};
type RefreshCompaniesTableT = {
  type: 'REFRESH_COMPANIES_TABLE';
};

type GetPartnerCompaniesEventT = DoneInvokeEvent<{
  results: Company[];
}>;

type EventT =
  | GetCompaniesEventT
  | GetProductsEventT
  | EnterSearchEventT
  | ChangeCurrentPageT
  | SelectActiveCompanyT
  | ChangeRowsPerPageT
  | SelectProductT
  | DeleteCompanyT
  | RefreshCompaniesTableT;

export const companyMachine = (identifier: string) =>
  createMachine<ContextT, EventT>(
    {
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: `partnerCompanyMachine-${identifier}`,
      context: {
        identifier,
        companies: [],
        partnerCompanies: [],
        products: [],
        productOwners: [],
        size: 0,
        from: 0,
        count: 0,
        search: '',
        productIdentifier: 'ANY',
        rowsPerPage: 10,
        currentPage: 0,
      },
      initial: 'load_companies',
      on: {
        REFRESH_COMPANIES_PROFILE: {
          target: 'refresh_companies_profile',
        },
        REFRESH_COMPANIES_TABLE: {
          target: 'load_companies',
        },
      },
      states: {
        load_companies: {
          ...parallelServices([
            { src: 'getCompanies', actions: 'setCompanies' },
            { src: 'getProducts', actions: 'setProducts' },
            { src: 'getPartnerCompanies', actions: 'setPartnerCompanies' },
            { src: 'getProductOwners', actions: 'setProductOwners' },
          ]),
          onDone: 'load_companies_done',
        },
        refresh_companies_profile: {
          invoke: {
            src: 'getCompanies',
            onDone: {
              target: 'modify_company',
              actions: 'setCompanies',
            },
            onError: {
              target: 'load_companies',
            },
          },
        },
        load_companies_done: {
          on: {
            ENTER_SEARCH: {
              target: 'load_companies',
              actions: 'updateSearch',
            },
            CHANGE_CURRENT_PAGE: {
              target: 'load_companies',
              actions: 'setCurrentPage',
            },
            CHANGE_ROWS_PER_PAGE: {
              target: 'load_companies',
              actions: 'updateRowsPerPage',
            },
            SELECT_COMPANY: {
              target: 'modify_company',
              actions: 'setActiveCompany',
            },
            SELECT_PRODUCT: {
              target: 'load_companies',
              actions: 'setProductId',
            },
            ADD_COMPANY_SUCCESS: {},
            DELETE_COMPANY: {},
            UPDATE_COMPANY: {},
          },
        },
        load_companies_error: {},
        modify_company: {
          on: {
            GO_HOME: {
              target: 'load_companies_done',
              actions: 'removeActiveCompany',
            },
            VIEW_HISTORY: {
              target: 'view_survey_history',
            },
            DEACTIVATE_SURVEY: {
              target: 'refresh_companies_profile',
            },
            LINK_SURVEY: {},
            UPDATE_COMPANY: {},
          },
        },
        view_survey_history: {
          on: {
            GO_HOME: {
              target: 'load_companies_done',
              actions: 'removeActiveCompany',
            },
            GO_BACK: {
              target: 'modify_company',
            },
          },
        },
        add_new_company: {
          on: {
            ENTER_STRING_DATA: {
              actions: 'updateSearch',
            },
            GO_HOME: {
              target: 'load_companies',
            },
          },
        },
      },
    },
    {
      services: {
        getCompanies: async (context: ContextT, _: EventT) => {
          const skip = context.currentPage * context.rowsPerPage;
          const result = await axios.get(
            constants.apiPaths.partners.GET_COMPANIES(
              context.identifier,
              skip,
              context.rowsPerPage,
            ),
            {
              ...getAuthHeaders(),
              params: {
                term: context.search,
                productId:
                  context.productIdentifier === 'ANY'
                    ? undefined
                    : context.productIdentifier,
              },
            },
          );

          return result.data;
        },
        getPartnerCompanies: async (context: ContextT, _: EventT) => {
          const companies = await axios.get(
            constants.apiPaths.companies.GET_AVAILABLE_BY_PARTNER(
              0,
              1000,
              context.identifier,
            ),
            getAuthHeaders(),
          );

          return companies.data;
        },
        getProducts: async () => {
          const products = await axios.get(
            constants.apiPaths.products.GET_ALL(),
            getAuthHeaders(),
          );

          return products.data;
        },
        getProductOwners: async () => {
          const productOwners = await axios.get(
            constants.apiPaths.productOwners.GET_ALL(),
            getAuthHeaders(),
          );

          return productOwners.data;
        },
      },
      actions: {
        setCompanies: assign((_, event: EventT) => {
          const {
            data: { count, companies },
          } = event as GetCompaniesEventT;
          return {
            companies,
            count,
          };
        }),
        setProducts: assign((_, event: EventT) => {
          const { data: products } = event as GetProductsEventT;
          return {
            products,
          };
        }),
        setProductOwners: assign((_, event) => {
          const { data: productOwners } = event as GetProductOwnersEventT;
          return {
            productOwners,
          };
        }),
        updateSearch: assign((_, event: EventT) => {
          return {
            search: (event as EnterSearchEventT).data,
          };
        }),
        setCurrentPage: assign((_, event: EventT) => {
          return {
            currentPage: (event as ChangeCurrentPageT).data,
          };
        }),
        setProductId: assign((_, event: EventT) => {
          return {
            productIdentifier: (event as SelectProductT).data,
          };
        }),
        updateRowsPerPage: assign((_, event: EventT) => {
          return {
            rowsPerPage: (event as ChangeRowsPerPageT).data,
            currentPage: 0,
          };
        }),
        setPartnerCompanies: assign((_, event: EventT) => {
          const {
            data: { results },
          } = event as GetPartnerCompaniesEventT;
          return {
            partnerCompanies: results,
          };
        }),
      },
    },
  );

export type CompanyMachine = MachineConfig<ContextT, any, EventT>;
