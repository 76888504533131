import { Box, IconButton, Modal as MuiModal } from '@mui/material';
import { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';

type Modal = {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
};

export const Modal = ({ open, onClose, children }: Modal) => {
  return (
    <MuiModal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          py: ['33px', '35px', '37px'],
          px: ['35px', '37px', '39px'],
          borderRadius: '0.25rem',
          fontSize: ['12px', '14px', '16px'],
          outline: 'none',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '1.25em',
            top: '1.0625em',
            height: '1.1em',
            width: '1.1em',
            fontSize: '1em',
          }}
        >
          <CloseIcon sx={{ height: '1em', width: '1em', fontSize: '1em' }} />
        </IconButton>
        {children}
      </Box>
    </MuiModal>
  );
};
