import { Box, Skeleton } from '@mui/material';

export default () => {
  return (
    <Box>
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
    </Box>
  );
};
