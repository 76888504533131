import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import { Company, Partner, Product, ProductOwner } from '../../types';
import axios from 'axios';
import * as constants from '../../../constants';
import { getAuthHeaders } from '../../../authentication';
import { parallelServices } from '../../helpers/xstate';

type GetPartnersEventType = DoneInvokeEvent<{
  partners: Partner[];
  count: number;
}>;
type GetProductsEventType = DoneInvokeEvent<Product[]>;
type GetProductOwnersEventType = DoneInvokeEvent<ProductOwner[]>;
type GetCompaniesEventType = DoneInvokeEvent<{
  results: Company[];
  count: number;
}>;
type ReloadEventType = {
  type: 'RELOAD';
};
type FilterEventType = {
  type: 'FILTER';
  prop: 'term' | 'productId' | 'companyId';
  data: string;
};
type PageChangeEventType = {
  type: 'PAGE_CHANGE';
  data: number;
};
type RowsPerPageChangeEventType = {
  type: 'ROWS_PER_PAGE_CHANGE';
  data: number;
};
type EventType =
  | ReloadEventType
  | FilterEventType
  | GetPartnersEventType
  | GetProductsEventType
  | GetCompaniesEventType
  | PageChangeEventType
  | RowsPerPageChangeEventType;
type ContextType = {
  partners: Partner[];
  products: Product[];
  productOwners: ProductOwner[];
  companies: Company[];
  count: number;
  rowsPerPage: number;
  currentPage: number;
  term?: string;
  productId?: string;
  companyId?: string;
};

const partnerPageMachine = createMachine<ContextType, EventType>(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QAcCGAnALgOzOgCqjALKoDGAFgJa4B0ANgPaoQD6aWu6sAxBI3RoA3RgGswtDjjyES5anSYt2GadwTDGZVJioCA2gAYAukeOIUjWFV0CLIAB6IATIYCctAGwAOAOwBWABoQAE9EbwBGWn8AXxjgqS5ZMFJKGgklNkS8Xjx0RnRJeh0AMwKAW0lVJKIU+XSGZizqnI1sEW1bbDMze2QrGz1seycEfzdPaIj-T2cg0MQIgGYluISWglrUhQym1lgAVzIyOF4AJQBRABkAeQBBABFepBB+6y6Rl3cvP3mwhAivg8sXirw2yW2DUy+yOJ1gvAAYgBJK4AFQuZ2elneQ0+CFcHh8AWC-wizjccVB2EYEDgfXBW3quD6Aw+L1GAFpPCTEFy1mDODJGWlFHtstwWTi7OzEEs3M5aO4lsSFgDDN5ovzxRCmbtlIdjqdJYNpaBRgAWZw8hDeDW-LUMuQivVsPIFY1ss2IXw+6K+SJza3LZyUmJAA */
    id: 'partnerPageMachine',
    initial: 'load_partners',
    predictableActionArguments: true,
    context: {
      partners: [],
      products: [],
      productOwners: [],
      companies: [],
      count: 0,
      rowsPerPage: 10,
      currentPage: 0,
      productId: 'ANY',
      companyId: 'ANY',
    },
    states: {
      load_partners: {
        ...parallelServices([
          { src: 'getPartners', actions: 'setPartners' },
          { src: 'getProducts', actions: 'setProducts' },
          { src: 'getCompanies', actions: 'setCompanies' },
          { src: 'getProductOwners', actions: 'setProductOwners' },
        ]),
        onDone: 'load_success',
      },
      load_success: {
        on: {
          RELOAD: {
            target: 'load_partners',
          },
          FILTER: {
            target: 'load_partners',
            actions: 'setFilters',
          },
          ROWS_PER_PAGE_CHANGE: {
            target: 'load_partners',
            actions: 'setRowsPerPage',
          },
          PAGE_CHANGE: {
            target: 'load_partners',
            actions: 'setCurrentPage',
          },
        },
      },
      load_error: {
        on: {
          RELOAD: {
            target: 'load_partners',
          },
          FILTER: {
            target: 'load_partners',
            actions: 'setFilters',
          },
          ROWS_PER_PAGE_CHANGE: {
            target: 'load_partners',
            actions: 'setRowsPerPage',
          },
          PAGE_CHANGE: {
            target: 'load_partners',
            actions: 'setCurrentPage',
          },
        },
      },
    },
  },
  {
    services: {
      getPartners: async (context, _) => {
        const skip = context.currentPage * context.rowsPerPage;

        const partners = await axios.get(
          constants.apiPaths.partners.GET_ALL(skip, context.rowsPerPage),
          {
            ...getAuthHeaders(),
            params: {
              term: context.term ?? '',
              productId: context.productId === 'ANY' ? '' : context.productId,
              companyId: context.companyId === 'ANY' ? '' : context.companyId,
            },
          },
        );

        return partners.data;
      },
      getProducts: async () => {
        const products = await axios.get(
          constants.apiPaths.products.GET_ALL(),
          getAuthHeaders(),
        );

        return products.data;
      },
      getCompanies: async () => {
        const companies = await axios.get(
          constants.apiPaths.companies.GET_ALL(0, 1000),
          getAuthHeaders(),
        );

        return companies.data;
      },
      getProductOwners: async () => {
        const productOwners = await axios.get(
          constants.apiPaths.productOwners.GET_ALL(),
          getAuthHeaders(),
        );

        return productOwners.data;
      },
    },
    actions: {
      setPartners: assign((_, event) => {
        const {
          data: { partners, count },
        } = event as GetPartnersEventType;
        return {
          partners,
          count,
        };
      }),
      setProducts: assign((_, event) => {
        const { data: products } = event as GetProductsEventType;
        return {
          products,
        };
      }),
      setCompanies: assign((_, event) => {
        const {
          data: { results: companies },
        } = event as GetCompaniesEventType;
        return {
          companies,
        };
      }),
      setProductOwners: assign((_, event) => {
        const { data: productOwners } = event as GetProductOwnersEventType;
        return {
          productOwners,
        };
      }),
      setFilters: assign((_, event) => {
        const { prop, data } = event as FilterEventType;
        return {
          [prop]: data,
        };
      }),
      setRowsPerPage: assign((_, event) => ({
        rowsPerPage: (event as RowsPerPageChangeEventType).data,
      })),
      setCurrentPage: assign((_, event) => ({
        currentPage: (event as PageChangeEventType).data,
      })),
    },
  },
);

export default partnerPageMachine;
