import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Link as MuiLink,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Stack,
  Menu,
  MenuItem,
  IconButton,
  TablePagination,
  TableContainer,
  Alert,
  Snackbar,
  Typography,
} from '@mui/material';
import AddFileModal from '../AddFileModal';
import { Roles, routePaths } from '../../../../../../constants';
import { Company, SurveyWithProduct } from '../../../../../types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { useActor, useInterpret, useSelector } from '@xstate/react';
import { surveyMachine } from './machine';
import RemoveSurveyModal from '../RemoveSurveyModal';
import { CompanyDetailContext } from '../..';
import ConfigureSurveyModal from './ConfigureSurveyModal';
import { useJwtClaims } from '../../../../../hooks';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { ProductSelectContext } from '../../../..';
import moment from 'moment';

const StyledTableCell = styled(TableCell)<TableCellProps>(() => ({
  fontSize: '0.875em',
}));

type SurveyTableRowProps = {
  survey: SurveyWithProduct;
  company: Company;
  send: (event: any) => void;
  companySend: (event: any) => void;
};

const SurveyTableRowGlobalAdmin = (props: SurveyTableRowProps) => {
  const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [configureSurveyModalOpen, setConfigureSurveyModalOpen] =
    useState(false);
  const [addFileNotificationOpen, setAddFileNotificationOpen] = useState(false);
  const _surveyRoute = `${routePaths.surveys.TO_SURVEY(
    props.survey.identifier,
    props.company.identifier,
  )}`;
  const claims = useJwtClaims();

  const FinalReport = () => (
    <MuiLink
      onClick={() => setFileModalOpen(true)}
      style={{ textAlign: 'left' }}
      className="survey-action"
    >
      Upload Final
    </MuiLink>
  );

  const GlobalAdminActions = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCopyLink = async () => {
      await navigator.clipboard.writeText(
        routePaths.surveys.TO_SURVEY_LINK(
          props.survey.identifier,
          props.company.identifier,
        ),
      );
      handleClose();
    };

    const handleDeactivate = () => {
      props.send({ type: 'DEACTIVATE', data: props.survey.identifier });
      handleClose();
    };

    const handleRemoveSurvey = () => {
      setRemoveModalOpen(true);
    };

    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <FinalReport />
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => navigate(_surveyRoute)}>
            View survey
          </MenuItem>
          <MenuItem onClick={handleCopyLink}>Copy survey link</MenuItem>
          <MenuItem onClick={() => setConfigureSurveyModalOpen(true)}>
            Configure survey
          </MenuItem>
          <MenuItem onClick={handleDeactivate}>Deactivate survey</MenuItem>
          <MenuItem onClick={handleRemoveSurvey}>Remove survey</MenuItem>
        </Menu>
      </Stack>
    );
  };

  const DefaultActions = () => {
    const handleCopyLink = async () => {
      await navigator.clipboard.writeText(
        routePaths.surveys.TO_SURVEY_LINK(
          props.survey.identifier,
          props.company.identifier,
        ),
      );
    };

    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <MuiLink href={_surveyRoute} target="_blank">
          View Survey
        </MuiLink>
        <MuiLink onClick={handleCopyLink} sx={{ cursor: 'pointer' }}>
          <Stack direction="row" alignItems="center">
            <FileCopyIcon fontSize="inherit" sx={{ mr: '0.5rem' }} /> Copy Link
          </Stack>
        </MuiLink>
        {props.survey?.QlikReports?.length > 0 ? (
          <MuiLink href={props.survey.QlikReports[0].location} target="_blank">
            View Report
          </MuiLink>
        ) : (
          <Typography
            variant="body2"
            sx={{ fontStyle: 'italic', color: 'rgba(57, 57, 57, 0.6)' }}
          >
            Report not ready
          </Typography>
        )}
      </Stack>
    );
  };

  return (
    <>
      <Snackbar
        open={addFileNotificationOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setAddFileNotificationOpen(false);
          props.send({ type: 'RELOAD' });
        }}
      >
        <Alert
          onClose={() => setAddFileNotificationOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Final report uploaded.
        </Alert>
      </Snackbar>
      {fileModalOpen && (
        <AddFileModal
          open={fileModalOpen}
          onClose={() => setFileModalOpen(false)}
          onDone={() => setAddFileNotificationOpen(true)}
          survey={props.survey}
          company={props.company}
        />
      )}
      <RemoveSurveyModal
        open={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        surveyId={props.survey.identifier}
        companyId={props.company.identifier}
        onDone={() => {
          props.send({ type: 'RELOAD' });
          props.companySend({ type: 'RELOAD_COMPANY' });
        }}
      />
      {configureSurveyModalOpen && (
        <ConfigureSurveyModal
          survey={props.survey}
          open={configureSurveyModalOpen}
          onClose={() => setConfigureSurveyModalOpen(false)}
        />
      )}
      <TableRow>
        <StyledTableCell>
          <MuiLink href={_surveyRoute} target="_blank">
            {props.survey.name}
          </MuiLink>
        </StyledTableCell>
        <StyledTableCell sx={{ minWidth: '100px' }}>
          {props.survey?.product?.name ?? ''}
        </StyledTableCell>
        {claims.role !== Roles.COMPANY_ADMIN && (
          <StyledTableCell sx={{ minWidth: '100px' }}>
            {props.survey.isAuthRequired ? 'Individual' : 'Company'}
          </StyledTableCell>
        )}
        <StyledTableCell sx={{ minWidth: '100px' }}>
          <Chip
            label="Active"
            style={{ backgroundColor: '#4caf50', color: '#ffffff' }}
          />
        </StyledTableCell>
        <StyledTableCell>
          {props.survey.sentAt
            ? moment(new Date(props.survey.sentAt)).format(
                'DD MMM YYYY, HH:mm a',
              )
            : ''}
        </StyledTableCell>
        <StyledTableCell>
          <Box display="flex" justifyContent="space-between">
            {claims.role === Roles.GLOBAL_ADMIN ? (
              <GlobalAdminActions />
            ) : (
              <DefaultActions />
            )}
          </Box>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

const SurveyActive = (props: { company: Company }) => {
  const productSelectService = useContext(ProductSelectContext);
  const productIdentifier = useSelector(
    productSelectService,
    (state) => state.context.productId,
  );
  const service = useInterpret(surveyMachine(props.company.identifier, false));
  const [state, send] = useActor(service);
  const { companyDetailService } = useContext(CompanyDetailContext);
  const [, companyDetailSend] = useActor(companyDetailService);
  const claims = useJwtClaims();

  useEffect(() => {
    if (claims.role === Roles.COMPANY_ADMIN) {
      send({
        type: 'CHANGE_PRODUCT_IDENTIFIER',
        data: productIdentifier,
      });
    }
  }, [claims.role, productIdentifier, send]);

  useEffect(() => {
    companyDetailSend({ type: 'SET_SURVEY_COUNT', data: state.context.count });
  }, [companyDetailSend, state.context.count]);

  useEffect(() => {
    send({ type: 'RELOAD' });
  }, [props.company, send]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) =>
    send({ type: 'CHANGE_ROWS_PER_PAGE', data: parseInt(event.target.value) });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Survey Name</StyledTableCell>
            <StyledTableCell>Product Type</StyledTableCell>
            {claims.role !== Roles.COMPANY_ADMIN && (
              <StyledTableCell>Survey Type</StyledTableCell>
            )}
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Sent On</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.context.surveys
            .filter((survey) => survey.isActive)
            .map((survey) => (
              <SurveyTableRowGlobalAdmin
                key={survey.identifier}
                survey={survey}
                company={props.company}
                send={send}
                companySend={companyDetailSend}
              />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={state.context.count}
        rowsPerPage={state.context.rowsPerPage}
        page={state.context.currentPage}
        onPageChange={(_, v) => send({ type: 'CHANGE_CURRENT_PAGE', data: v })}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default SurveyActive;
