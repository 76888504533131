import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
} from '@mui/material';
import { Partner } from '../../../../types';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { Roles, routePaths } from '../../../../../constants';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDebounce, useJwtClaims } from '../../../../hooks';
import { useActor, useInterpret } from '@xstate/react';
import { companyMachine } from './machine';
import { InterpreterFrom } from 'xstate';

const StyledTableCell = styled(TableCell)<TableCellProps>(() => ({
  borderBottom: '1px solid rgba(0,0,0,0.12)',
  maxWidth: '150px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

type LinkedCompaniesModalProps = {
  open: boolean;
  onClose: () => void;
  partner: Partner;
};

type FilterHandler = (prop: 'term', data: string) => void;

type SearchBarProps = {
  onChange: FilterHandler;
};

export const ConnectedCompaniesContext = createContext(
  {} as InterpreterFrom<typeof companyMachine>,
);

const SearchBar = ({ onChange }: SearchBarProps) => {
  const [term, setTerm] = useState('');
  const debouncedTerm = useDebounce(term, 500);

  useEffect(() => {
    onChange('term', debouncedTerm);
  }, [debouncedTerm, onChange]);

  return (
    <TextField
      size="small"
      name="search"
      onChange={(e) => setTerm(e.target.value)}
      fullWidth
      placeholder="Company Name"
    />
  );
};

const LinkedCompaniesModal = ({
  open,
  onClose,
  partner,
}: LinkedCompaniesModalProps) => {
  const machine = useMemo(
    () => companyMachine(partner.identifier),
    [partner.identifier],
  );
  const companiesService = useInterpret(machine);
  const [current, send] = useActor(companiesService);

  const navigate = useNavigate();
  const claims = useJwtClaims();

  const isAdmin = claims.role === Roles.GLOBAL_ADMIN;

  const handleClose = () => onClose();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) =>
    send({ type: 'CHANGE_ROWS_PER_PAGE', data: parseInt(event.target.value) });

  const handleFilter: FilterHandler = useCallback(
    (_, data) => {
      send({
        type: 'ENTER_SEARCH',
        data,
      });
    },
    [send],
  );

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      scroll="paper"
      fullWidth
      maxWidth={isAdmin ? 'sm' : 'md'}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: '1.25em',
          top: '1.0625em',
          height: '1.1em',
          width: '1.1em',
          fontSize: '1em',
        }}
      >
        <CloseIcon sx={{ height: '1em', width: '1em', fontSize: '1em' }} />
      </IconButton>
      <DialogTitle
        sx={{ fontSize: '2em', fontWeight: 700, px: '50px', pt: '47px' }}
      >
        {isAdmin
          ? 'Connected Companies'
          : 'Connected Companies with Product Access'}
      </DialogTitle>
      <ConnectedCompaniesContext.Provider value={companiesService}>
        <DialogContent sx={{ px: '50px', pb: '47px' }}>
          <SearchBar onChange={handleFilter} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Company Name</StyledTableCell>
                  <StyledTableCell>Email Address</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {current.context.companies.map((company) => (
                  <TableRow key={company.id}>
                    <StyledTableCell>{company.name}</StyledTableCell>
                    <StyledTableCell>
                      {company.primaryContact.emailAddress}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Link
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          navigate(
                            routePaths.companies.TO_PROFILE(company.identifier),
                          )
                        }
                      >
                        View Profile
                      </Link>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {current.context.companies.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={current.context.count}
              rowsPerPage={current.context.rowsPerPage}
              page={current.context.currentPage}
              onPageChange={(_, v) =>
                send({ type: 'CHANGE_CURRENT_PAGE', data: v })
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </DialogContent>
      </ConnectedCompaniesContext.Provider>
    </Dialog>
  );
};

export default LinkedCompaniesModal;
