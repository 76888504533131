import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import * as O from 'fp-ts/lib/Option';
import * as F from 'fp-ts/lib/function';
import * as T from 'fp-ts/lib/Task';
import * as E from 'fp-ts/lib/Either';
import * as TE from 'fp-ts/lib/TaskEither';
import axios from 'axios';
import { Lens } from 'monocle-ts';

import * as c from './codecs';
import * as t from 'io-ts';
import * as constants from '../../constants';
import { getAuthHeaders } from '../../authentication';

export type ContextT = {
  surveyLinkId: string;
  companyId?: string;
  survey: O.Option<c.SurveyC>;
};

type SurveyIdentifiers = {
  identifier: string;
  companyIdentifier?: string;
};

const lens = Lens.fromPath<ContextT>();

const get_survey = (identifier: string, companyIdentifier?: string) =>
  axios.get(
    constants.apiPaths.surveys.GET_SURVEY(identifier, companyIdentifier),
    getAuthHeaders(),
  );

const lift_get_survey = (
  identifier: SurveyIdentifiers,
  companyIdentifier?: string,
): TE.TaskEither<Error, unknown> =>
  TE.tryCatch(
    () =>
      get_survey(identifier.identifier, identifier.companyIdentifier).then(
        (res) => res.data,
      ),
    E.toError,
  );

const decodeError = (e: t.Errors): Error => {
  const missingKeys = e.map((e) => e.context.map(({ key }) => key).join('.'));
  return new Error(`${missingKeys}`);
};

const decode = (res: unknown): TE.TaskEither<Error, c.SurveyC> =>
  F.pipe(res, c.SurveyC.decode, TE.fromEither, TE.mapLeft(decodeError));

const invoke_get_survey = (context: ContextT, event: any) =>
  F.pipe(
    context,
    ({ surveyLinkId, companyId }) => ({
      identifier: surveyLinkId,
      companyIdentifier: companyId,
    }),
    lift_get_survey,
    TE.chain(decode),
    TE.foldW((e) => {
      throw new Error(e.message);
    }, T.of),
  );

const assign_survey_to_context = assign(
  (c: ContextT, e: DoneInvokeEvent<c.SurveyC>) =>
    F.pipe(c, lens(['survey']).set(O.some(e.data))),
);

const surveyIsActive = (c: ContextT, e: any) =>
  F.pipe(
    c.survey,
    O.fold(F.constFalse, (s) => s.isActive),
  );

export const getSurveyMachine = (
  identifier: string,
  companyIdentifier?: string,
) =>
  createMachine<ContextT>({
    predictableActionArguments: true,
    id: 'surveyMachine',
    context: {
      surveyLinkId: identifier,
      companyId: companyIdentifier,
      survey: O.none,
    },
    initial: 'init_survey',
    states: {
      init_survey: {
        invoke: {
          src: invoke_get_survey,
          onDone: {
            target: 'survey_parse',
            actions: [
              assign_survey_to_context,
              (c, e) => console.log({ c, e }),
            ],
          },
          onError: {
            target: 'survey_invalid',
            actions: [(c, e) => console.log({ c, e })],
          },
        },
      },
      survey_parse: {
        always: [
          { target: 'survey_valid', cond: surveyIsActive },
          { target: 'survey_invalid' },
        ],
      },
      survey_valid: {},
      survey_invalid: {},
    },
  });

export default getSurveyMachine;
