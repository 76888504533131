import { TextField, InputAdornment, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDebounce } from '../hooks';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

type SearchBarProps = {
  label: string;
  onChange: (data: string) => void;
};

const SearchField = ({ onChange, label }: SearchBarProps) => {
  const [term, setTerm] = useState('');
  const debouncedTerm = useDebounce(term, 500);

  useEffect(() => {
    onChange(debouncedTerm);
  }, [debouncedTerm, onChange]);

  return (
    <TextField
      id="term"
      label={label}
      fullWidth
      value={term}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: term.length > 0 && (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setTerm('');
              }}
              sx={{
                padding: 0,
              }}
            >
              <CloseIcon sx={{ fontSize: '.75em' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
      sx={{
        '& .MuiInputBase-root': {
          height: '2.375em',
        },
      }}
      onChange={(e) => setTerm(e.target.value)}
    />
  );
};

export default SearchField;
