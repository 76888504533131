import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';

import { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { apiPaths } from '../../../../constants';
import { Modal } from '../../../Components';
import { ReactNoop } from '../../../_react';
import { getAuthHeaders } from '../../../../authentication';
import { ResourcePageContext } from '..';
import { useActor } from '@xstate/react';

type AddFileProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const AddFileModal = (props: AddFileProps) => {
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const service = useContext(ResourcePageContext);
  const [, send] = useActor(service);

  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    setUploadFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);

  useEffect(() => {
    if (uploadFiles.length > 0) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [uploadFiles]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  });

  const removeFile = (name: string) => {
    const newFiles = uploadFiles.filter((file) => file.name !== name);
    setUploadFiles(newFiles);
  };

  const handleClose = () => {
    setDisableSubmit(true);
    setUploadFiles([]);
    setFileUploaded(false);
    setUploadError(false);
    props.closeModal();
  };

  const AcceptedFileItem = ({ file }: { file: File }) => (
    <Box
      sx={{
        border: '1px solid rgba(50,50,50,0.7)',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        opacity: '0.8',
        my: '1vh',
        padding: '0.8125em',
      }}
    >
      <InsertDriveFileIcon sx={{ color: 'grey', mr: '1.25rem' }} />
      <Stack width="100%">
        <Typography
          variant="body1"
          color="black"
          fontWeight="bold"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '500px',
          }}
        >
          {file.name}
        </Typography>
        <Typography variant="body2">
          {((file.size as number) / 1000).toFixed()} KB
        </Typography>
      </Stack>
      <IconButton
        disabled={disableSubmit}
        onClick={() => removeFile(file.name)}
      >
        <CloseIcon
          sx={{
            fontSize: '1.25rem',
            color: 'grey',
          }}
        />
      </IconButton>
    </Box>
  );
  const handleSubmit = async () => {
    if (uploadFiles.length === 0) return;

    const formData = new FormData();

    uploadFiles.forEach((file) => {
      formData.append('files[]', file, file.name);
    });

    setDisableSubmit(true);
    setUploadError(false);
    setFileUploaded(false);

    axios({
      method: 'post',
      url: apiPaths.files.ADD(),
      headers: {
        'Content-Type': 'multipart/form-data',
        ...getAuthHeaders().headers,
      },
      data: formData, // --> the data to be sent as the request body
    })
      .then(() => {
        setUploadFiles([]);
        setFileUploaded(true);
        setUploadError(false);
      })
      .catch(() => {
        setUploadError(true);
        setFileUploaded(false);
      })
      .finally(() => {
        setDisableSubmit(false);
        send({ type: 'RELOAD' });
      });
  };

  return (
    <Modal open={props.isOpen} onClose={handleClose}>
      <Box maxWidth="600px">
        <div {...getRootProps()} style={{ width: '37.5em' }}>
          <input {...getInputProps()} />
          {fileUploaded === true ? (
            <Alert
              severity="success"
              sx={{
                marginBottom: '2vh',
              }}
            >
              Report successfully uploaded
            </Alert>
          ) : (
            <ReactNoop />
          )}
          {uploadError === true ? (
            <Alert
              severity="error"
              sx={{
                marginBottom: '2vh',
              }}
            >
              Upload Failed, please retry
            </Alert>
          ) : (
            <ReactNoop />
          )}
          <Typography variant="h2" fontSize="1.5em" fontWeight="bold" mb="33px">
            Upload new files
          </Typography>
          <Box
            display="flex"
            sx={{
              flexDirection: 'column',
              textAlign: 'center',
              border: '1px dashed grey',
              borderRadius: '6px',
              paddingX: '10vh',
              paddingY: '5vh',
            }}
          >
            <CloudDownloadIcon
              sx={{
                color: 'rgb(112, 114, 180)',
                height: '3rem',
                fontSize: '64px',
                textAlign: 'center',
                mx: 'auto',
              }}
            />
            <p style={{ fontFamily: 'Cairo', color: 'gray' }}>
              Drag and drop or <span className="browse-files">browse</span>
            </p>
          </Box>
        </div>
        <Box maxHeight="16.5em" my="2vh" sx={{ overflowY: 'auto' }}>
          {uploadFiles.map((file) => (
            <AcceptedFileItem file={file} />
          ))}
        </Box>
        <Box display="flex" justifyContent="right">
          <Button onClick={handleClose} sx={{ mt: '1.5em', mr: '0.625em' }}>
            CANCEL
          </Button>
          <Button
            sx={{ mt: '1.5em' }}
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            ADD FILES
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddFileModal;
