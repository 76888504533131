import { Box, Typography } from '@mui/material';
import { createContext } from 'react';
import { InterpreterFrom } from 'xstate';
import productOwnerPageMachine from './machine';
import { useInterpret } from '@xstate/react';
import { ProductTable } from './components';

export const ProductOwnerPageContext = createContext(
  {} as InterpreterFrom<typeof productOwnerPageMachine>,
);

const ProductOwnerPage = () => {
  const service = useInterpret(productOwnerPageMachine);

  return (
    <ProductOwnerPageContext.Provider value={service}>
      <Typography
        variant="h1"
        fontSize="2em"
        fontWeight="bold"
        data-testid="title"
      >
        Product Owners
      </Typography>
      <Box my="1.25em">
        <ProductTable />
      </Box>
    </ProductOwnerPageContext.Provider>
  );
};

export default ProductOwnerPage;
