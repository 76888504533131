import * as F from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import v from 'validator';
import { useSearchParams } from 'react-router-dom';
import Invalid from './Invalid';
import Survey from './Survey';

const Surveys = () => {
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('surveyId');
  const companyId = searchParams.get('companyId') ?? undefined;

  const validSurveyId = F.pipe(
    surveyId,
    O.fromNullable,
    O.chain(O.fromPredicate(v.isUUID)),
    O.fold(F.constNull, F.identity),
  );

  return (
    <>
      {validSurveyId === null ? (
        <Invalid />
      ) : (
        <Survey surveyId={validSurveyId} companyId={companyId} />
      )}
    </>
  );
};

export default Surveys;
