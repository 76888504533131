import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import axios from 'axios';
import * as constants from '../../../../../constants';
import { getAuthHeaders } from '../../../../../authentication';
import { Company, ProductOwner } from '../../../../types';

export type ContextT = {
  productOwner: ProductOwner;
  selectedCompanies: string[];
  displayList: Company[];
  poCompanies: Company[];
};

type UpdateProductT = {
  type: 'UPDATE_PRODUCT';
};

type SetSelectedCompaniesT = {
  type: 'SET_SELECTED_COMPANIES';
  data: string[];
};

type SetDisplayListT = {
  type: 'SET_DISPLAY_LIST';
  data: Company[];
};

type SetPOCompaniesEventT = DoneInvokeEvent<{
  results: Company[];
}>;

type LoadPoCompaniesEventT = {
  type: 'LOAD_COMPANIES';
};

type EventT =
  | UpdateProductT
  | SetSelectedCompaniesT
  | SetDisplayListT
  | SetPOCompaniesEventT
  | LoadPoCompaniesEventT;

const update_product = (c: ContextT, e: EventT) =>
  axios
    .put(
      constants.apiPaths.productOwners.LINK_COMPANIES(
        c.productOwner.identifier,
      ),
      {
        companies: c.selectedCompanies.map((item) => ({ identifier: item })),
      },
      getAuthHeaders(),
    )
    .then((res) => res.data);

const getCompanies = async (context: ContextT) => {
  const { productOwner } = context;
  try {
    const res = await axios.get(
      constants.apiPaths.companies.GET_AVAILABLE_BY_PO(
        0,
        1000,
        productOwner.identifier,
      ),
      getAuthHeaders(),
    );
    return res.data;
  } catch (error) {
    throw new Error('Failed to get companies');
  }
};

export const manageCompaniesMachine = (productOwner: ProductOwner) => {
  const selectedCompanies = productOwner.companies?.map(
    (company) => company.identifier,
  );
  return createMachine<ContextT, EventT>(
    {
      predictableActionArguments: true,
      id: `manageCompanyMachine-${productOwner.id}`,
      context: {
        productOwner,
        selectedCompanies: selectedCompanies ?? [],
        displayList: [],
        poCompanies: [],
      },
      initial: 'main',
      states: {
        main: {
          on: {
            UPDATE_PRODUCT: {
              target: 'start',
            },
            SET_SELECTED_COMPANIES: {
              actions: 'setSelectedCompanies',
            },
            SET_DISPLAY_LIST: {
              actions: 'setDisplayList',
            },
            LOAD_COMPANIES: 'loadCompanies',
          },
        },
        loadCompanies: {
          invoke: {
            src: 'getCompanies',
            onDone: {
              target: 'main',
              actions: 'setCompanies',
            },
            onError: {
              target: 'main',
              actions: () => {
                throw new Error('Failed to load companies');
              },
            },
          },
        },
        start: {
          invoke: {
            src: update_product,
            onDone: {
              target: 'done',
            },
            onError: {
              target: 'main',
            },
          },
        },
        done: {
          after: {
            0: { target: 'main' },
          },
        },
      },
    },
    {
      services: {
        getCompanies,
      },
      actions: {
        setSelectedCompanies: assign((_, event) => {
          const { data } = event as SetSelectedCompaniesT;
          return {
            selectedCompanies: data,
          };
        }),
        setDisplayList: assign((_, event) => {
          const { data } = event as SetDisplayListT;
          return {
            displayList: data,
          };
        }),
        setCompanies: assign((_, event) => {
          const {
            data: { results },
          } = event as SetPOCompaniesEventT;

          return {
            poCompanies: results,
          };
        }),
      },
    },
  );
};
