import {
  Box,
  Typography,
  Button,
  TextField,
  Alert,
  Paper,
  CircularProgress,
} from '@mui/material';
import ResetPasswordModal from './ResetPasswordModal';
import ConfirmationModal from './components/ConfirmationModal';
import { useState, useEffect, useContext, useRef } from 'react';
import { useActor } from '@xstate/react';
import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PhoneInput } from '../../common';
import { FormStateContext, UserProfileContext } from '..';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';
import { useJwtClaims } from '../../hooks';
import { Roles } from '../../../constants';

const schema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  emailAddress: z.string().email(),
  phoneNumber: z
    .string()
    .nullable()
    .refine(
      (val) => (val === null ? true : isValidPhoneNumber(val)),
      'Invalid phone number',
    )
    .optional()
    .or(z.literal(''))
    .transform((val) => val),
});

export type WatchNameProps =
  | 'firstName'
  | 'lastName'
  | 'emailAddress'
  | 'phoneNumber';
export type FormValues = z.infer<typeof schema>;

const UserProfilePage = () => {
  const profileService = useContext(UserProfileContext);
  const [current, send] = useActor(profileService);
  const formStateService = useContext(FormStateContext);
  const [formState, sendFormState] = useActor(formStateService);
  const formRef = useRef();
  const navigate = useNavigate();
  const claims = useJwtClaims();

  const isAdmin = claims.role === Roles.GLOBAL_ADMIN;

  const [resetPasswordOpen, setResetPasswordOpen] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    getFieldState,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: current.context.firstName,
      lastName: current.context.lastName,
      emailAddress: current.context.emailAddress,
      phoneNumber: current.context.phoneNumber,
    },
  });

  useEffect(() => {
    // Update form default values when `current.context` changes
    reset({
      firstName: current.context.firstName,
      lastName: current.context.lastName,
      emailAddress: current.context.emailAddress,
      phoneNumber: current.context.phoneNumber,
    });
  }, [current.context, reset]);

  useEffect(() => {
    const subscribe = watch((value, { name }) => {
      if (
        value[name as WatchNameProps] !==
        current.context[name as WatchNameProps]
      ) {
        return sendFormState({
          type: 'SET_EDITING',
        });
      }
      return sendFormState({
        type: 'SET_IDLE',
      });
    });
    return () => subscribe.unsubscribe();
  }, [current.context, sendFormState, watch]);

  useEffect(() => {
    const subscription = profileService.subscribe((state) => {
      if (state.matches('update_success')) {
        sendFormState({ type: 'SET_IDLE' });
      }
    });
    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileService]);

  return (
    <>
      <ResetPasswordModal
        isOpen={resetPasswordOpen}
        closeModal={() => setResetPasswordOpen(false)}
      />
      <ConfirmationModal
        isOpen={formState.matches('confirming')}
        closeModal={() => sendFormState({ type: 'SET_EDITING' })}
        onSave={() => {
          sendFormState({ type: 'SET_IDLE' });
          navigate(formState.context.next);
        }}
      />
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h1"
          fontSize="2em"
          fontWeight="bold"
          data-testid="title"
        >
          My Profile
        </Typography>
        <Button variant="contained" onClick={() => setResetPasswordOpen(true)}>
          CHANGE PASSWORD
        </Button>
      </Box>
      <Paper sx={{ mt: '1.25rem', py: '3.5em', px: '4em' }}>
        <Typography variant="h2" fontSize="1.5em" fontWeight="bold">
          {isAdmin ? 'HSI Admin' : 'Account Representative'}
        </Typography>
        {current.value.toString() === 'update_error' && (
          <Alert
            sx={{
              mt: '2.125em',
              fontSize: '1em',
              '.MuiAlert-icon': {
                fontSize: '1.375em',
              },
            }}
            severity="error"
          >
            There was an error updating your profile.
          </Alert>
        )}
        {current.value.toString() === 'update_success' && (
          <Alert
            sx={{
              mt: '2.125em',
              fontSize: '1em',
              '.MuiAlert-icon': {
                fontSize: '1.375em',
              },
            }}
            severity="success"
          >
            Profile successfully updated.
          </Alert>
        )}
        <Box
          component="form"
          onSubmit={handleSubmit((data) => {
            send({ type: 'SAVE_CHANGES', data });
            sendFormState({ type: 'SET_IDLE' });
          })}
          ref={formRef}
        >
          <Box sx={{ display: [, , 'flex'] }}>
            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                  size="small"
                  label="First name"
                  sx={{ mt: '2.1875em', mr: [0, 0, '1.1875em'] }}
                  fullWidth
                  variant="outlined"
                  disabled={!isAdmin}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName?.message}
                  size="small"
                  label="Last name"
                  sx={{ mt: '2.1875em' }}
                  fullWidth
                  variant="outlined"
                  disabled={!isAdmin}
                />
              )}
            />
          </Box>
          <Controller
            control={control}
            name="emailAddress"
            render={({ field }) => (
              <TextField
                {...field}
                type="email"
                error={Boolean(errors.emailAddress)}
                helperText={errors.emailAddress?.message}
                size="small"
                label="Email address"
                sx={{ mt: '2.1875em', mr: [0, 0, '1.1875em'] }}
                fullWidth
                variant="outlined"
                disabled={!isAdmin}
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <PhoneInput
                {...field}
                country="au"
                isValid={!getFieldState('phoneNumber').invalid}
                hasError={getFieldState('phoneNumber').invalid}
                errorMessage={errors.phoneNumber?.message}
                containerStyle={{
                  marginTop: '2.1875em',
                  width: '100%',
                }}
                inputStyle={{
                  width: '100%',
                  color: !isAdmin ? 'rgba(0, 0, 0, 0.38)' : undefined,
                }}
                disabled={!isAdmin}
              />
            )}
          />
          {isAdmin ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: ['column-reverse', 'column-reverse', 'row'],
                justifyContent: [, , 'flex-end'],
              }}
            >
              <Button
                disabled={current.value === 'init_profile'}
                sx={{ mt: '1.5em' }}
                variant="contained"
                type="submit"
              >
                {current.value === 'update_profile_call' ? (
                  <CircularProgress color="inherit" size={27} disableShrink />
                ) : (
                  'SAVE CHANGES'
                )}
              </Button>
            </Box>
          ) : (
            <Alert
              sx={{
                my: '1.5em',
                fontSize: '1em',
                '.MuiAlert-icon': { fontSize: '1.375em' },
              }}
              severity="info"
            >
              To update representative details, please contact the Health &
              Safety Index team.
            </Alert>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default UserProfilePage;
