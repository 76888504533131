import { createMachine } from 'xstate';
import axios from 'axios';
import * as constants from '../../../../../../../constants';
import { getAuthHeaders } from '../../../../../../../authentication';
import { Company } from '../../../../../../types';
import { FormValues } from '.';

export type ContextT = {
  company: Company;
};

type UpdateCompanyT = {
  type: 'UPDATE_COMPANY';
  data: FormValues;
};

type EventT = UpdateCompanyT;

const update_company = (c: ContextT, e: EventT) =>
  axios
    .put(
      constants.apiPaths.companies.COMPANY(c.company.identifier),
      {
        name: e.data.name,
        primaryContact: {
          firstName: e.data.firstName,
          lastName: e.data.lastName,
          emailAddress: e.data.emailAddress,
          phoneNumber: e.data.phoneNumber,
        },
        products: e.data.products,
      },
      getAuthHeaders(),
    )
    .then((res) => res.data);

export const editCompanyMachine = (company: Company) =>
  createMachine<ContextT, EventT>({
    predictableActionArguments: true,
    id: `editMachine-${company.identifier}`,
    context: {
      company: company,
    },
    initial: 'main',
    states: {
      main: {
        on: {
          UPDATE_COMPANY: {
            target: 'start',
          },
        },
      },
      start: {
        invoke: {
          src: update_company,
          onDone: {
            target: 'done',
          },
        },
      },
      done: {
        after: {
          0: { target: 'main' },
        },
      },
    },
  });
