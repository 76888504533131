import { Alert, Box, Snackbar, Typography } from '@mui/material';
import SurveyTable from './SurveyTable';
import { useContext, useEffect, useState } from 'react';
import { SurveyPageContext } from '..';
import { useJwtClaims } from '../../../hooks';
import { Roles } from '../../../../constants';

const SurveyList = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const service = useContext(SurveyPageContext);
  const claims = useJwtClaims();

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      if (state.matches('submit_done')) {
        setSnackbarOpen(true);
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  return (
    <Box>
      <Typography
        variant="h1"
        fontSize="2em"
        fontWeight="bold"
        data-testid="title"
        mb={3.5}
      >
        {claims.role === Roles.GLOBAL_ADMIN ? 'All Surveys' : 'Surveys'}
      </Typography>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Updated survey successfully!
        </Alert>
      </Snackbar>
      <SurveyTable />
    </Box>
  );
};

export default SurveyList;
