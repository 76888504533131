import axios from 'axios';
import {
  ActorRefFrom,
  assign,
  createMachine,
  DoneInvokeEvent,
  spawn,
} from 'xstate';

import * as constants from '../../../../constants';
import * as r from '../../../../refinements';
import { getAuthHeaders } from '../../../../authentication';
import { Company } from '../../../types';
import surveyPageMachine from '../../Survey/machine';

export type ContextT = {
  identifier: string;
  company?: Company;
  surveyCount: number;
  surveyActor?: ActorRefFrom<typeof surveyPageMachine>;
};
type LoadCompanyT = DoneInvokeEvent<Company>;
type ReloadCompanyT = {
  type: 'RELOAD_COMPANY';
};
type SetSurveyCountT = {
  type: 'SET_SURVEY_COUNT';
  data: number;
};

type EventT = LoadCompanyT | ReloadCompanyT | SetSurveyCountT;

const loadCompany = (context: ContextT, event: EventT) =>
  axios
    .get(
      constants.apiPaths.companies.COMPANY(context.identifier),
      getAuthHeaders(),
    )
    .then((res) => res.data);

export const loadCompanyMachine = (identifier: r.UUID4R) =>
  createMachine<ContextT, EventT>(
    {
      id: identifier,
      predictableActionArguments: true,
      preserveActionOrder: true,
      context: {
        identifier: identifier,
        surveyCount: 0,
      },
      initial: 'load_company_start',
      states: {
        load_company_start: {
          on: {
            SET_SURVEY_COUNT: {
              actions: 'setSurveyCount',
            },
          },
          invoke: {
            src: loadCompany,
            onDone: {
              target: 'load_company_success',
              actions: 'setCompany',
            },
            onError: {
              target: 'load_company_error',
            },
          },
        },
        load_company_success: {
          entry: assign({
            surveyActor: () =>
              spawn(surveyPageMachine, { sync: true, name: 'surveyActor' }),
          }),
          on: {
            RELOAD_COMPANY: {
              target: 'load_company_start',
            },
            SET_SURVEY_COUNT: {
              actions: 'setSurveyCount',
            },
          },
        },
        load_company_error: {
          on: {
            RELOAD_COMPANY: {
              target: 'load_company_start',
            },
            SET_SURVEY_COUNT: {
              actions: 'setSurveyCount',
            },
          },
        },
        idle: {
          on: {},
        },
      },
    },
    {
      actions: {
        setCompany: assign((_, event: EventT) => {
          return {
            company: (event as LoadCompanyT).data,
          };
        }),
        setSurveyCount: assign((_, event: EventT) => {
          return {
            surveyCount: (event as SetSurveyCountT).data,
          };
        }),
      },
    },
  );
