import AddIcon from '@mui/icons-material/Add';
import { Alert, Box, Button, Snackbar, Stack, Typography } from '@mui/material';
import { PartnerTable } from '.';
import { useState } from 'react';
import AddPartnerModal from './AddPartnerModal';
import { useJwtClaims } from '../../../hooks';
import { Roles } from '../../../../constants';

const PartnerList = () => {
  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const claims = useJwtClaims();

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Added partner successfully!
        </Alert>
      </Snackbar>
      {claims.role === Roles.GLOBAL_ADMIN && addCompanyModalOpen && (
        <AddPartnerModal
          isOpen={addCompanyModalOpen}
          closeModal={() => setAddCompanyModalOpen(false)}
          onDone={() => setSnackbarOpen(true)}
        />
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3.5}
      >
        <Typography
          variant="h1"
          fontSize="2em"
          fontWeight="bold"
          data-testid="title"
        >
          Partners
        </Typography>
        {claims.role === Roles.GLOBAL_ADMIN && (
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => setAddCompanyModalOpen(true)}
          >
            ADD NEW PARTNER
          </Button>
        )}
      </Stack>
      <PartnerTable />
    </Box>
  );
};

export default PartnerList;
