import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import axios from 'axios';
import * as constants from '../../../../../constants';
import { getAuthHeaders } from '../../../../../authentication';
import { Partner, ProductOwner } from '../../../../types';

export type ContextT = {
  productOwner: ProductOwner;
  selectedPartners: string[];
  displayList: Partner[];
  poPartners: Partner[];
};

type UpdateProductT = {
  type: 'UPDATE_PRODUCT';
};

type SetselectedPartnersT = {
  type: 'SET_SELECTED_PARTNERS';
  data: string[];
};

type SetDisplayListT = {
  type: 'SET_DISPLAY_LIST';
  data: Partner[];
};

type LoadPoPartnersEventT = {
  type: 'LOAD_PARTNERS';
};

type SetPOPartnersEventT = DoneInvokeEvent<{
  partners: Partner[];
}>;

type EventT =
  | UpdateProductT
  | SetselectedPartnersT
  | SetDisplayListT
  | LoadPoPartnersEventT
  | SetPOPartnersEventT;

const update_product = (c: ContextT, e: EventT) =>
  axios
    .put(
      constants.apiPaths.productOwners.LINK_PARTNERS(c.productOwner.identifier),
      {
        partners: c.selectedPartners.map((item) => ({ identifier: item })),
      },
      getAuthHeaders(),
    )
    .then((res) => res.data);

const getPartners = async (context: ContextT) => {
  const { productOwner } = context;
  try {
    const res = await axios.get(
      constants.apiPaths.partners.GET_AVAILABLE_BY_PO(
        0,
        1000,
        productOwner.identifier,
      ),
      getAuthHeaders(),
    );
    return res.data;
  } catch (error) {
    throw new Error('Failed to get partners');
  }
};

export const managePartnersMachine = (productOwner: ProductOwner) => {
  const selectedPartners = productOwner.partners?.map(
    (partner) => partner.identifier,
  );
  return createMachine<ContextT, EventT>(
    {
      predictableActionArguments: true,
      id: `managePartnersMachine-${productOwner.id}`,
      context: {
        productOwner,
        selectedPartners: selectedPartners ?? [],
        displayList: [],
        poPartners: [],
      },
      initial: 'main',
      states: {
        main: {
          on: {
            UPDATE_PRODUCT: {
              target: 'start',
            },
            SET_SELECTED_PARTNERS: {
              actions: 'setSelectedPartners',
            },
            SET_DISPLAY_LIST: {
              actions: 'setDisplayList',
            },
            LOAD_PARTNERS: 'loadPartners',
          },
        },
        loadPartners: {
          invoke: {
            src: 'getPartners',
            onDone: {
              target: 'main',
              actions: 'setPartners',
            },
            onError: {
              target: 'main',
              actions: () => {
                throw new Error('Failed to load partners');
              },
            },
          },
        },
        start: {
          invoke: {
            src: update_product,
            onDone: {
              target: 'done',
            },
            onError: {
              target: 'main',
            },
          },
        },
        done: {
          after: {
            0: { target: 'main' },
          },
        },
      },
    },
    {
      services: { getPartners },
      actions: {
        setSelectedPartners: assign((_, event) => {
          const { data } = event as SetselectedPartnersT;
          return {
            selectedPartners: data,
          };
        }),
        setDisplayList: assign((_, event) => {
          const { data } = event as SetDisplayListT;
          return {
            displayList: data,
          };
        }),
        setPartners: assign((_, event) => {
          const {
            data: { partners },
          } = event as SetPOPartnersEventT;

          return {
            poPartners: partners,
          };
        }),
      },
    },
  );
};
