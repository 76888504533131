import * as React from 'react';
import * as F from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import { useActor, useInterpret } from '@xstate/react';
import { ActorRefFrom } from 'xstate';
import surveyMachine from './machine';
import SurveyLoading from './SurveyLoading';
import SurveyInactive from './SurveyInactive';
import SurveyType from './SurveyType';

export type SurveyContext = {
  surveyService: ActorRefFrom<typeof surveyMachine>;
};
export const SurveyContext = React.createContext({} as SurveyContext);

type Props = {
  surveyId: string;
  companyId?: string;
};

const Survey = (props: Props) => {
  // Survey
  const surveyService = useInterpret(
    surveyMachine(props.surveyId, props.companyId),
  );
  const [current, send] = useActor(surveyService);

  const Embedded = () =>
    F.pipe(
      current.context.survey,
      O.map((survey) => <SurveyType survey={survey} />),
      O.fold(
        () => <div>LOADING</div>,
        (v) => v,
      ),
    );

  return (
    <SurveyContext.Provider value={{ surveyService }}>
      {current.matches('init_survey') && <SurveyLoading />}
      {current.matches('survey_valid') && Embedded()}
      {current.matches('survey_invalid') && <SurveyInactive />}
    </SurveyContext.Provider>
  );
};

export default Survey;
