import { createMachine } from 'xstate';
import axios from 'axios';
import * as constants from '../../../../../../constants';
import { getAuthHeaders } from '../../../../../../authentication';
import { Survey } from '../../../../../types';

export type ContextT = {
  surveyId: string;
  companyId: string;
};

type ConfirmRemoveSurveyE = {
  type: 'CONFIRM_REMOVE_SURVEY';
};
type EventE = ConfirmRemoveSurveyE;

const remove_survey = (c: ContextT, e: EventE) =>
  axios.delete(
    constants.apiPaths.surveys.UNLINK_SURVEY(c.surveyId, c.companyId),
    getAuthHeaders(),
  );

export const removeSurveyMachine = (surveyId: string, companyId: string) =>
  createMachine<ContextT, EventE>({
    predictableActionArguments: true,
    id: `removeSurveyMachine-${surveyId}`,
    context: {
      surveyId,
      companyId,
    },
    initial: 'main',
    states: {
      main: {
        on: {
          CONFIRM_REMOVE_SURVEY: {
            target: 'start',
          },
        },
      },
      start: {
        invoke: {
          src: remove_survey,
          onDone: {
            target: 'done',
          },
          onError: {
            target: 'error',
          },
        },
      },
      done: {
        type: 'final',
      },
      error: {},
    },
  });
