import { useActor, useInterpret } from '@xstate/react';
import {
  Alert,
  Box,
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Link as MuiLink,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { addSurveyMachine } from './machine';
import {
  Company,
  SurveyWithCompanies,
  SurveyWithProduct,
} from '../../../../../types';
import CloseIcon from '@mui/icons-material/Close';
import { Control, Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { CompanyDetailContext } from '../..';
import { LinkedCompaniesModal } from '../../../../Survey/components';
import ConfigureSurveyModal from '../SurveyTable/ConfigureSurveyModal';

const StyledTableCell = styled(TableCell)<TableCellProps>(() => ({
  fontSize: '0.875em',
}));

type Props = {
  company: Company;
  isOpen: boolean;
  onClose: () => void;
};

const schema = z.object({
  surveys: z.array(
    z.object({
      id: z.string(),
    }),
  ),
});

export type FormValues = z.infer<typeof schema>;

type TableRowItemProps = {
  survey: SurveyWithProduct & SurveyWithCompanies;
  handleCheck: (
    onChange: (
      items: {
        id: string;
      }[],
    ) => void,
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  control: Control<{ surveys: { id: string }[] }, any>;
};

const TableRowItem = ({ survey, handleCheck, control }: TableRowItemProps) => {
  const [companiesOpen, setCompaniesOpen] = useState(false);
  const [configureOpen, setConfigureOpen] = useState(false);

  const handleCompaniesClick = () => {
    setCompaniesOpen(true);
  };

  const handleConfigureClick = () => {
    setConfigureOpen(true);
  };

  return (
    <>
      <LinkedCompaniesModal
        companies={survey.companies}
        open={companiesOpen}
        onClose={() => setCompaniesOpen(false)}
      />
      <ConfigureSurveyModal
        open={configureOpen}
        onClose={() => setConfigureOpen(false)}
        survey={survey}
      />
      <TableRow>
        <StyledTableCell>
          <FormGroup sx={{ display: 'flex' }}>
            <FormControlLabel
              key={survey.identifier}
              control={
                <Controller
                  control={control}
                  name="surveys"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      value={survey.identifier}
                      onChange={handleCheck(field.onChange)}
                    />
                  )}
                />
              }
              label={survey.name}
            />
          </FormGroup>
        </StyledTableCell>
        <StyledTableCell>{survey.product.name}</StyledTableCell>
        <StyledTableCell>
          {survey.isAuthRequired ? 'Individual' : 'Company'}
        </StyledTableCell>
        <StyledTableCell>
          <MuiLink
            onClick={handleCompaniesClick}
            sx={{ cursor: 'pointer' }}
          >{`${survey.companies.length} companies`}</MuiLink>
        </StyledTableCell>
        <StyledTableCell>
          <MuiLink sx={{ cursor: 'pointer' }} onClick={handleConfigureClick}>
            Configure Survey
          </MuiLink>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

const AddSurveyModal = (props: Props) => {
  const addSurveyService = useInterpret(
    addSurveyMachine(props.company.identifier),
  );
  const [state, send] = useActor(addSurveyService);
  const { companyDetailService } = useContext(CompanyDetailContext);
  const [, companySend] = useActor(companyDetailService);
  const { handleSubmit, getValues, control } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      surveys: [],
    },
  });
  const [search, setSearch] = useState('');
  const companySurveyIds = props.company.surveys.map(
    (survey) => survey.identifier,
  );
  const surveyList = state.context.surveys?.filter(
    (survey) => !companySurveyIds.includes(survey.identifier),
  );
  const filteredRows = search
    ? surveyList?.filter((survey) =>
        survey.name.toLowerCase().includes(search.toLowerCase()),
      )
    : surveyList;

  useEffect(() => {
    const subscription = addSurveyService.subscribe((state) => {
      if (state.matches('link_done')) {
        companySend({ type: 'RELOAD_COMPANY' });
        props.onClose();
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSurveyService]);

  const handleClose = () => {
    props.onClose();
  };

  const handleCheck =
    (onChange: (items: { id: string }[]) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      let current = getValues().surveys;
      if (e.target.checked) {
        onChange([...current, { id: e.target.value }]);
      } else {
        current = current.filter((item) => item.id !== e.target.value);
        onChange(current);
      }
    };

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1000px',
          },
        },
      }}
    >
      <IconButton
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: '1.25em',
          top: '1.0625em',
          height: '1.1em',
          width: '1.1em',
          fontSize: '1em',
        }}
      >
        <CloseIcon sx={{ height: '1em', width: '1em', fontSize: '1em' }} />
      </IconButton>
      <Box
        component="form"
        sx={{
          py: ['33px', '35px', '37px'],
          px: ['35px', '37px', '39px'],
          borderRadius: '0.25rem',
          fontSize: ['12px', '14px', '16px'],
          outline: 'none',
        }}
        onSubmit={handleSubmit((data) => {
          send({ type: 'LINK_SURVEY', data: data.surveys });
        })}
      >
        <DialogTitle
          variant="h2"
          fontSize="1.5em"
          fontWeight="bold"
          mb="1.5em"
          sx={{ p: 0 }}
        >
          Add New Survey
        </DialogTitle>
        <DialogContent sx={{ mt: '1.25rem', p: 0 }}>
          <Alert severity="info" sx={{ mb: '1rem' }}>
            <strong>Company survey</strong> data is aggregated inside a single
            Qlik report, and each user is not required to log in before
            completing the survey.
          </Alert>
          <TextField
            size="small"
            name="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            fullWidth
            placeholder="Survey Name"
          />
          <TableContainer sx={{ maxHeight: '25rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    sx={{
                      '&.MuiTableCell-root': {
                        width: '30%',
                      },
                    }}
                  >
                    Survey Name
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      '&.MuiTableCell-root': {
                        width: '20%',
                      },
                    }}
                  >
                    Product Type
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      '&.MuiTableCell-root': {
                        width: '15%',
                      },
                    }}
                  >
                    Survey Type
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      '&.MuiTableCell-root': {
                        width: '15%',
                      },
                    }}
                  >
                    Companies
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      '&.MuiTableCell-root': {
                        width: '20%',
                      },
                    }}
                  >
                    Actions
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows?.map((survey) => (
                  <TableRowItem
                    key={survey.identifier}
                    survey={survey}
                    control={control}
                    handleCheck={handleCheck}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'right', p: 0 }}>
          <Button onClick={handleClose} sx={{ mt: '1.5em', mr: '0.625em' }}>
            CANCEL
          </Button>
          <Button sx={{ mt: '1.5em' }} variant="contained" type="submit">
            ADD SURVEY
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddSurveyModal;
