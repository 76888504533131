import { zodResolver } from '@hookform/resolvers/zod';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  IconButton,
  Box,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Divider,
  DialogActions,
  Button,
  Alert,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ProductOwner } from '../../../../types';
import { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { editProductOwnerMachine } from './machine';
import { PhoneInput } from '../../../../common';
import { isValidPhoneNumber } from 'libphonenumber-js';

const schema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  emailAddress: z.string().email(),
  phoneNumber: z
    .string()
    .nullable()
    .refine(
      (val) => (val === null ? true : isValidPhoneNumber(val)),
      'Invalid phone number',
    )
    .optional()
    .or(z.literal(''))
    .transform((val) => (val ? val : null)),
});

export type FormValues = z.infer<typeof schema>;

type EditProductOwnerModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  productOwner: ProductOwner;
  onDone: () => void;
};

const EditProductOwnerModal = (props: EditProductOwnerModalProps) => {
  const { isOpen, closeModal, productOwner, onDone } = props;

  const [state, send, service] = useMachine(
    editProductOwnerMachine(productOwner),
  );

  const {
    handleSubmit,
    control,
    getFieldState,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: productOwner?.User?.firstName ?? '',
      lastName: productOwner?.User?.lastName ?? '',
      emailAddress: productOwner?.User?.emailAddress ?? '',
      phoneNumber: productOwner?.User?.phoneNumber ?? null,
    },
  });

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      if (state.matches('done')) {
        onDone();
        closeModal();
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  return (
    <Dialog open={isOpen} onClose={closeModal} scroll="paper">
      <IconButton
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: '1.25em',
          top: '1.0625em',
          height: '1.1em',
          width: '1.1em',
          fontSize: '1em',
        }}
      >
        <CloseIcon sx={{ height: '1em', width: '1em', fontSize: '1em' }} />
      </IconButton>
      <Box
        component="form"
        onSubmit={handleSubmit((data) =>
          send({
            type: 'UPDATE_PRODUCT_OWNER',
            data,
          }),
        )}
        sx={{
          py: ['33px', '35px', '37px'],
          px: ['35px', '37px', '39px'],
          borderRadius: '0.25rem',
          fontSize: ['12px', '14px', '16px'],
          outline: 'none',
        }}
      >
        <DialogTitle
          variant="h2"
          fontSize="1.625em"
          fontWeight="bold"
          sx={{ p: 0 }}
        >
          Edit Product Owner
        </DialogTitle>
        <DialogContent sx={{ p: 0, mb: 4 }}>
          {state.context.errObj && (
            <Alert
              severity="error"
              sx={{
                mt: '1.5625em',
              }}
            >
              {state.context.errObj.message}
            </Alert>
          )}
          <Typography
            variant="h2"
            fontSize="1.25em"
            mt="1.5em"
            fontWeight="bold"
          >
            Product Representative
          </Typography>
          <Box sx={{ display: { sm: 'block', md: 'flex' } }}>
            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                  size="small"
                  label="First name*"
                  sx={{ mt: '2.1875em', mr: [0, 0, '1.1875em'] }}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName?.message}
                  size="small"
                  label="Last name*"
                  sx={{ mt: '2.1875em', ml: [0, 0, '1.1875em'] }}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Controller
            control={control}
            name="emailAddress"
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.emailAddress)}
                helperText={errors.emailAddress?.message}
                size="small"
                label="Email address*"
                type="email"
                sx={{ mt: '2.1875em' }}
                fullWidth
                variant="outlined"
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <PhoneInput
                country="au"
                isValid={!getFieldState('phoneNumber').invalid}
                containerStyle={{
                  marginTop: '2.1875em',
                  width: '100%',
                }}
                inputStyle={{ width: '100%' }}
                hasError={getFieldState('phoneNumber').invalid}
                errorMessage={errors.phoneNumber?.message}
                {...field}
              />
            )}
          />
        </DialogContent>
        <Alert
          sx={{
            mb: '1.5em',
            fontSize: '1em',
            '.MuiAlert-icon': { fontSize: '1.375em' },
          }}
          severity="info"
        >
          Note that changing the email address will send a password reset to the
          new nominated email address, and the current representative will lose
          access immediately.
        </Alert>
        <Divider />
        <DialogActions>
          <Box display="flex" justifyContent="right">
            <Button
              onClick={props.closeModal}
              sx={{ mt: '1.5em', mr: '0.625em' }}
            >
              CANCEL
            </Button>
            <Button sx={{ mt: '1.5em' }} variant="contained" type="submit">
              SAVE CHANGES
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditProductOwnerModal;
