import React, { useContext, useState } from 'react';
import { Box, Button, Paper, TableContainer, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Active from './Active';
import Inactive from './Inactive';
import AddSurveyModal from '../AddSurveyModal';
import { Company } from '../../../../../types';
import { useNavigate } from 'react-router-dom';
import { CompanyDetailContext } from '../..';
import { useSelector } from '@xstate/react';
import { useJwtClaims } from '../../../../../hooks';
import { Roles } from '../../../../../../constants';

export type Props = { company: Company; inactive: boolean };

const SurveyTable = (props: Props) => {
  const navigate = useNavigate();
  const { setBreadcrumbs, companyDetailService } =
    useContext(CompanyDetailContext);
  const surveyCount = useSelector(
    companyDetailService,
    (state) => state.context.surveyCount,
  );
  const [addModalOpen, setAddModalOpen] = useState(false);
  const claims = useJwtClaims();

  const isAdmin = claims.role === Roles.GLOBAL_ADMIN;

  let headerTitle = 'Active Surveys';

  if (props.inactive) {
    headerTitle = isAdmin ? 'Deactivated Surveys' : 'Inactive Surveys';
  }

  const headerText = `${headerTitle} (${surveyCount})`;

  return (
    <>
      {addModalOpen && (
        <AddSurveyModal
          company={props.company}
          isOpen={addModalOpen}
          onClose={() => setAddModalOpen(false)}
        />
      )}
      <Box
        sx={{
          display: [, , 'flex'],
          justifyContent: [, , 'space-between'],
          flexWrap: 'wrap',
        }}
      >
        <Paper sx={{ width: '100%', mt: '1.25rem', p: '2.125em' }}>
          <Box
            sx={{
              display: [, , 'flex'],
              justifyContent: [, , 'space-between'],
              flexWrap: 'wrap',
            }}
          >
            <Typography
              data-testid="survey-count"
              variant="h3"
              fontSize="1.5em"
              fontWeight="bold"
            >
              {headerText}
            </Typography>
            {!props.inactive && (
              <Box display="flex" mt={['0.5625rem', '0.5625rem', 0]}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setBreadcrumbs((prev) => [
                      ...prev,
                      { name: 'Survey History', path: 'history' },
                    ]);
                    navigate('history');
                  }}
                >
                  VIEW SURVEY HISTORY
                </Button>
                {claims.role === Roles.GLOBAL_ADMIN && (
                  <Button
                    disabled={props.inactive}
                    onClick={() => setAddModalOpen(true)}
                    variant="contained"
                    sx={{ ml: '1.0625rem' }}
                    startIcon={<AddIcon />}
                  >
                    ADD NEW SURVEY
                  </Button>
                )}
              </Box>
            )}
          </Box>

          <TableContainer>
            {!props.inactive ? (
              <Active company={props.company} />
            ) : (
              <Inactive {...props} />
            )}
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default SurveyTable;
