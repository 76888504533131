import {
  Stack,
  Link,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  AlertColor,
} from '@mui/material';
import { ReactNode, useContext, useState } from 'react';
import { useActor, useSelector } from '@xstate/react';
import { ProductOwner } from '../../../types';
import { ProductOwnerPageContext } from '..';
import ManageCompaniesModal from './ManageCompaniesModal';
import ManagePartnersModal from './ManagePartnersModal';
import EditProductOwnerModal from './EditProductOwnerModal';
import ResetPasswordModal from './ResetPasswordModal';
import axios from 'axios';
import { apiPaths } from '../../../../constants';

const tableHeadColumns = [
  'Product Owner Company Name',
  'Representative Name',
  'Representative Email',
  'Partners',
  'Companies',
  'Actions',
];

type CustomTableRowProps = {
  productOwner: ProductOwner;
};

type CustomTableCellProps = {
  children: ReactNode;
  sx?: SxProps;
};

const CustomTableCell = ({ children, sx }: CustomTableCellProps) => (
  <TableCell sx={{ verticalAlign: 'text-top', ...sx }}>{children}</TableCell>
);

type SnackbarState = {
  open: boolean;
  severity: AlertColor;
  message: string;
};

const CustomTableRow = ({ productOwner }: CustomTableRowProps) => {
  const initialSnackbarState: SnackbarState = {
    open: false,
    severity: 'success',
    message: '',
  };
  const service = useContext(ProductOwnerPageContext);
  const [, send] = useActor(service);
  const [snackbar, setSnackbar] = useState<SnackbarState>(initialSnackbarState);
  const [linkedCompaniesOpen, setLinkedCompaniesOpen] = useState(false);
  const [linkedPartnersOpen, setLinkedPartnersOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const fullName = productOwner?.User
    ? productOwner?.User?.firstName + ' ' + productOwner?.User?.lastName
    : '';

  const resetPassword = () =>
    axios.post(apiPaths.auth.UPDATE_PASSWORD(), {
      email: productOwner?.User?.emailAddress,
    });

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setSnackbar(initialSnackbarState);
        }}
      >
        <Alert
          onClose={() => setSnackbar(initialSnackbarState)}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <TableRow key={productOwner.id}>
        {linkedCompaniesOpen && (
          <ManageCompaniesModal
            productOwner={productOwner}
            open={linkedCompaniesOpen}
            onClose={() => setLinkedCompaniesOpen(false)}
            onDone={() => {
              send({ type: 'RELOAD' });
              setSnackbar({
                open: true,
                severity: 'success',
                message: 'Updated connected companies',
              });
            }}
          />
        )}
        {linkedPartnersOpen && (
          <ManagePartnersModal
            productOwner={productOwner}
            open={linkedPartnersOpen}
            onClose={() => setLinkedPartnersOpen(false)}
            onDone={() => {
              send({ type: 'RELOAD' });
              setSnackbar({
                open: true,
                severity: 'success',
                message: 'Updated connected partners',
              });
            }}
          />
        )}
        {editModalOpen && (
          <EditProductOwnerModal
            isOpen={editModalOpen}
            closeModal={() => setEditModalOpen(false)}
            productOwner={productOwner}
            onDone={() => send({ type: 'RELOAD' })}
          />
        )}
        <ResetPasswordModal
          isOpen={resetPasswordModalOpen}
          closeModal={() => setResetPasswordModalOpen(false)}
          productOwner={productOwner}
          onConfirm={resetPassword}
        />
        <CustomTableCell>{productOwner.name}</CustomTableCell>
        <CustomTableCell>{fullName}</CustomTableCell>
        <CustomTableCell>{productOwner?.User?.emailAddress}</CustomTableCell>
        <CustomTableCell>
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={() => setLinkedPartnersOpen(true)}
          >
            {`${productOwner.partners?.length} partners`}
          </Link>
        </CustomTableCell>
        <CustomTableCell>
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={() => setLinkedCompaniesOpen(true)}
          >
            {`${productOwner.companies?.length} companies`}
          </Link>
        </CustomTableCell>
        <CustomTableCell>
          <Stack direction="row" spacing={2}>
            <Link
              sx={{ cursor: 'pointer' }}
              onClick={() => setEditModalOpen(true)}
            >
              Edit
            </Link>
            <Link
              sx={{
                cursor: 'pointer',
                color: !productOwner?.User?.emailAddress
                  ? '#A0A0A0'
                  : undefined,
              }}
              onClick={(e) =>
                productOwner?.User?.emailAddress
                  ? setResetPasswordModalOpen(true)
                  : e.preventDefault()
              }
            >
              Reset Password
            </Link>
          </Stack>
        </CustomTableCell>
      </TableRow>
    </>
  );
};

const ProductTable = () => {
  const service = useContext(ProductOwnerPageContext);
  const productOwners = useSelector(
    service,
    (state) => state.context.productOwners,
  );

  return (
    <Paper sx={{ px: '3em', py: '1.9375em' }}>
      <TableContainer>
        <Table sx={{ fontSize: '0.875em' }}>
          <TableHead>
            <TableRow>
              {tableHeadColumns.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {productOwners.map((po) => (
              <CustomTableRow key={po.id} productOwner={po} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ProductTable;
