import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TablePagination,
  TablePaginationProps,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { File } from '../../../types';
import { useJwtClaims } from '../../../hooks';
import { Roles } from '../../../../constants';

type FileListProps = TablePaginationProps & {
  files: File[];
  onDelete: (id: string) => void;
};

type FileListItemProps = {
  file: File;
  onDelete: (id: string) => void;
};

const FileListItem = ({ file, onDelete }: FileListItemProps) => {
  const claims = useJwtClaims();

  return (
    <ListItem sx={{ borderBottom: '1px solid #ecf0f1' }}>
      <ListItemIcon sx={{ width: '1.5em' }}>
        <DescriptionIcon />
      </ListItemIcon>
      <ListItemText
        primary={file.name}
        primaryTypographyProps={{
          sx: {
            fontSize: '0.875em',
            width: '400px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        }}
      />
      <Stack direction="row" spacing="1.625em">
        <Link
          href={file.location}
          sx={{ cursor: 'pointer', fontFamily: 'Cairo', fontSize: '0.875em' }}
        >
          Download
        </Link>
        {claims.role === Roles.GLOBAL_ADMIN && (
          <Link
            sx={{ cursor: 'pointer', fontFamily: 'Cairo', fontSize: '0.875em' }}
            onClick={() => {
              onDelete(file.identifier);
            }}
          >
            Delete
          </Link>
        )}
      </Stack>
    </ListItem>
  );
};

const FileList = ({ files, onDelete, ...paginationProps }: FileListProps) => {
  return (
    <List>
      {files.map((file) => (
        <FileListItem key={file.id} onDelete={onDelete} file={file} />
      ))}
      <ListItem sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TablePagination component="div" {...paginationProps} />
      </ListItem>
    </List>
  );
};

export default FileList;
