import {
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  Stack,
  Button,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  Typography,
  Box,
} from '@mui/material';
import { useRef, useState } from 'react';
import { Nullable } from '../types';
import { useDimensions } from '../hooks';

export type CustomSelectProps<T> = {
  placeholder?: string;
  options: T[];
  value: string[];
  getOptionValue: (option: T) => string;
  getOptionLabel: (option: T) => string;
  onChange: (value: string[]) => void;
};

// Created a custom select field since MUI autocomplete does not satisfy our requirements
const CustomSelect = <T,>(props: CustomSelectProps<T>) => {
  const [search, setSearch] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);
  const [menuAnchor, setMenuAnchor] = useState<Nullable<HTMLElement>>(null);
  const textRef = useRef<Nullable<HTMLInputElement>>(null);
  const { width } = useDimensions(textRef);
  // only displays options that are not yet selected at default
  const baseRows = props.options.filter(
    (option) => !props.value.includes(props.getOptionValue(option)),
  );
  // for search filter
  const filteredRows = baseRows.filter((option) =>
    props.getOptionLabel(option).toLowerCase().includes(search.toLowerCase()),
  );

  // setting internal state for selected options
  const handleChange = (option: string) => {
    const options = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(options);
  };

  // trigger onChange handler and reset internal state
  const handleAddSelected = () => {
    if (selectedOptions.length > 0) {
      props.onChange(selectedOptions);
    }
    setMenuAnchor(null);
    setSelectedOptions([]);
  };

  return (
    <ClickAwayListener onClickAway={() => setMenuAnchor(null)}>
      <Box sx={{ width: '100%' }}>
        <TextField
          fullWidth
          ref={textRef}
          size="small"
          placeholder={props.placeholder}
          value={search}
          onClick={(e) => {
            if (!menuAnchor) {
              setMenuAnchor(e.currentTarget);
            }
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Popper
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          sx={{
            zIndex: 10000,
            width: `${width}px`,
          }}
        >
          <Paper
            elevation={4}
            sx={{
              maxHeight: '430px',
              overflowY: 'auto',
            }}
          >
            <MenuList
              sx={{
                maxHeight: '300px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {filteredRows.map((option) => (
                <MenuItem
                  key={props.getOptionValue(option)}
                  value={props.getOptionValue(option)}
                  onClick={() => handleChange(props.getOptionValue(option))}
                >
                  <Checkbox
                    checked={selectedOptions.includes(
                      props.getOptionValue(option),
                    )}
                  />
                  <ListItemText primary={props.getOptionLabel(option)} />
                </MenuItem>
              ))}
              <Stack
                justifyContent="center"
                direction="row"
                width="100%"
                mb={filteredRows.length > 0 ? '1.3125em' : 0}
                alignSelf="flex-end"
                position="sticky"
                bottom="1em"
              >
                {filteredRows.length > 0 ? (
                  <Button
                    variant="contained"
                    sx={{ width: '90%' }}
                    onClick={handleAddSelected}
                  >
                    ADD SELECTED ({`${selectedOptions.length}`})
                  </Button>
                ) : (
                  <Typography variant="body1">No results found</Typography>
                )}
              </Stack>
            </MenuList>
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default CustomSelect;
