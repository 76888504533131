import { Box, Link, Stack, Typography } from '@mui/material';
import YoutubeEmbed, { YoutubeEmbedProps } from './YoutubeEmbed';
import { useJwtClaims } from '../../../hooks';
import { Roles } from '../../../../constants';

type VideoItemProps = {
  title: string;
  onDelete: () => void;
} & YoutubeEmbedProps;

const VideoItem = (props: VideoItemProps) => {
  const claims = useJwtClaims();

  return (
    <Box width="25%">
      <YoutubeEmbed {...props} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing="1em"
      >
        <Typography variant="h3" fontSize="1em" fontWeight="bold">
          {props.title}
        </Typography>
        {claims.role === Roles.GLOBAL_ADMIN && (
          <Link
            component="button"
            variant="body1"
            sx={{ cursor: 'pointer' }}
            onClick={props.onDelete}
          >
            Remove
          </Link>
        )}
      </Stack>
    </Box>
  );
};

export default VideoItem;
