import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import axios, { AxiosError } from 'axios';
import * as constants from '../../../../../constants';
import { getAuthHeaders } from '../../../../../authentication';
import { FormValues } from '.';
import { Nullable, HttpException } from '../../../../types';

export type ContextT = FormValues & {
  errObj: Nullable<HttpException>;
};

type AddPartnerT = {
  type: 'ADD_COMPANY';
  data: FormValues;
};
type AddPartnerErrorT = DoneInvokeEvent<HttpException>;

type EventT = AddPartnerT | AddPartnerErrorT;

export const addPartnerMachine = createMachine<ContextT, EventT>(
  {
    predictableActionArguments: true,
    initial: 'main',
    states: {
      main: {
        on: {
          ADD_COMPANY: {
            target: 'start',
          },
        },
      },
      start: {
        invoke: {
          src: 'addPartner',
          onDone: {
            target: 'done',
          },
          onError: {
            target: 'main',
            actions: 'setError',
          },
        },
      },
      done: {
        after: {
          0: { target: 'main' },
        },
      },
    },
  },
  {
    services: {
      addPartner: async (_: ContextT, e: EventT) => {
        try {
          const formData = (e as AddPartnerT).data;
          const result = await axios.post(
            constants.apiPaths.partners.CREATE(),
            {
              name: formData.name,
              user: {
                firstName: formData.firstName,
                lastName: formData.lastName,
                emailAddress: formData.emailAddress,
                phoneNumber: formData.phoneNumber,
              },
              products: formData.products,
            },
            getAuthHeaders(),
          );

          return result.data;
        } catch (error) {
          throw (error as AxiosError).response?.data;
        }
      },
    },
    actions: {
      setError: assign((_, e: EventT) => ({
        errObj: (e as AddPartnerErrorT).data,
      })),
    },
  },
);
