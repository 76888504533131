import * as F from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import React from 'react';
import { AuthenticationContext } from '../index';
import { useActor } from '@xstate/react';
import AuthenticationWrapper from './AuthenticationWrapper';
import FormContainer from './components/FormContainer';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link as MUILink,
  Alert,
} from '@mui/material';

const LoginForm = () => {
  const services = React.useContext(AuthenticationContext);
  const [current, send] = useActor(services.authenticationService);

  const _email = F.pipe(
    current.context.emailAddress,
    O.fold(F.constant(''), F.identity),
  );
  const _password = F.pipe(
    current.context.password,
    O.fold(F.constant(''), F.identity),
  );

  return (
    <AuthenticationWrapper>
      <FormContainer>
        <Typography
          variant="h1"
          fontSize="2em"
          fontWeight="bold"
          align="center"
          mt="0.5625em"
        >
          Login to HSI
        </Typography>
        <TextField
          label="Email Address"
          type="email"
          value={_email}
          onChange={(e) =>
            send({
              type: 'ENTER_DATA',
              field: 'emailAddress',
              data: e.currentTarget.value,
            })
          }
          sx={{
            marginTop: '2.1875em',
          }}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="Password"
          type="password"
          value={_password}
          onChange={(e) =>
            send({
              type: 'ENTER_DATA',
              field: 'password',
              data: e.currentTarget.value,
            })
          }
          sx={{ marginTop: '2.1875em' }}
          fullWidth
          variant="outlined"
        />
        <Button
          sx={{ marginTop: '2.1875em' }}
          fullWidth
          variant="contained"
          type="submit"
          onClick={(e) => send({ type: 'SUBMIT' })}
        >
          LOGIN
        </Button>
        <Box width="100%" display="flex" justifyContent="center">
          <MUILink
            sx={{ cursor: 'pointer', marginTop: '1.5em' }}
            align="center"
            underline="none"
            onClick={() => send({ type: 'RECOVER_PASSWORD' })}
          >
            I'VE FORGOTTEN MY PASSWORD
          </MUILink>
        </Box>
        <Typography mt="2.125em" textAlign="center" fontSize="1em">
          Don't have an account
        </Typography>
        <Box width="100%" display="flex" justifyContent="center">
          <MUILink
            onClick={(e) => send({ type: 'REGISTER' })}
            sx={{ cursor: 'pointer', marginTop: '0.5em' }}
            align="center"
            underline="none"
          >
            Click here to create an account
          </MUILink>
        </Box>
      </FormContainer>
    </AuthenticationWrapper>
  );
};

export default LoginForm;
