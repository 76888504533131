import { DoneInvokeEvent, assign, createMachine } from 'xstate';
import axios, { AxiosError } from 'axios';
import * as constants from '../../../../constants';
import { getAuthHeaders } from '../../../../authentication';
import { Company, HttpException, Nullable } from '../../../types';
import { FormValues } from '.';

export type ContextT = {
  company: Company;
} & {
  errObj: Nullable<HttpException>;
};

type UpdateCompanyT = {
  type: 'UPDATE_COMPANY';
  data: FormValues;
};
type UpdateCompanyErrorT = DoneInvokeEvent<HttpException>;
type EventT = UpdateCompanyT | UpdateCompanyErrorT;

export const editCompanyMachine = (company: Company) =>
  createMachine<ContextT, EventT>(
    {
      predictableActionArguments: true,
      id: `editMachine-${company.identifier}`,
      context: {
        company: company,
        errObj: null,
      },
      initial: 'main',
      states: {
        main: {
          on: {
            UPDATE_COMPANY: {
              target: 'start',
            },
          },
        },
        start: {
          invoke: {
            src: 'updateCompany',
            onDone: {
              target: 'done',
            },
            onError: {
              target: 'main',
              actions: 'setError',
            },
          },
        },
        done: {
          after: {
            0: { target: 'main' },
          },
        },
      },
    },
    {
      services: {
        updateCompany: async (c: ContextT, e: EventT) => {
          try {
            const formData = (e as UpdateCompanyT).data;
            const result = await axios.put(
              constants.apiPaths.companies.COMPANY(c.company.identifier),
              {
                name: formData.name,
                primaryContact: {
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                  emailAddress: formData.emailAddress,
                  phoneNumber: formData.phoneNumber,
                },
                products: formData.products,
              },
              getAuthHeaders(),
            );

            return result.data;
          } catch (error) {
            throw (error as AxiosError).response?.data;
          }
        },
      },
      actions: {
        setError: assign((_, e: EventT) => ({
          errObj: (e as UpdateCompanyErrorT).data,
        })),
      },
    },
  );
