import * as t from 'io-ts';

export const LoginRequest = t.type({
  access_token: t.string,
  first_name: t.string,
  role: t.string,
});
export type LoginRequest = t.TypeOf<typeof LoginRequest>;

export interface ChildComponentProps {
  children: JSX.Element[] | JSX.Element;
}
