import { Box, Button, TextField, Typography } from '@mui/material';
import { Modal } from '../../../Components';
import { ProductOwner } from '../../../types';

type ResetPasswordModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  productOwner: ProductOwner;
  onConfirm: () => void;
};

const ResetPasswordModal = (props: ResetPasswordModalProps) => {
  const { isOpen, closeModal, productOwner, onConfirm } = props;

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box width="600px">
        <Typography variant="h2" fontSize="1.5em" fontWeight="bold">
          Reset Password
        </Typography>
        <Typography variant="body1" mt="1.8125em">
          Send a reset link to the email address nominated below to allow the
          user to reset their password.
        </Typography>
        <TextField
          value={productOwner?.User?.emailAddress}
          disabled
          fullWidth
          sx={{ my: '2.25em' }}
        />
        <Box display="flex" justifyContent="right">
          <Button onClick={closeModal} sx={{ mt: '1.5em', mr: '0.625em' }}>
            CANCEL
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              closeModal();
            }}
            sx={{ mt: '1.5em' }}
            variant="contained"
          >
            SEND RECOVERY LINK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResetPasswordModal;
