import { Modal } from '../../../Components';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import YoutubeEmbed from './YoutubeEmbed';

const schema = z.object({
  title: z.string().min(5),
  url: z
    .string()
    .refine(
      (val) =>
        /^https:\/\/www\.youtube\.com(\/embed)*\/[A-Za-z0-9&?=_-]+$/.test(val),
      { message: 'The URL must be a valid Youtube Embed URL' },
    ),
});

type FormValues = z.infer<typeof schema>;

type AddTutorialProps = {
  isOpen: boolean;
  closeModal: () => void;
  onAdd: (data: FormValues) => void;
};

const AddTutorialModal = ({ isOpen, closeModal, onAdd }: AddTutorialProps) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box
        width={600}
        component="form"
        onSubmit={handleSubmit((data) => {
          onAdd(data);
        })}
      >
        <Typography variant="h2" fontSize="1.5em" fontWeight="bold">
          Add new video URL
        </Typography>
        <Stack spacing="1.5em" my="1.75em">
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <TextField
                label="Video title"
                {...field}
                error={Boolean(errors.title)}
                helperText={errors.title?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="url"
            render={({ field }) => (
              <TextField
                label="Enter Youtube URL"
                {...field}
                error={Boolean(errors.url)}
                helperText={errors.url?.message}
              />
            )}
          />
        </Stack>
        {isValid && <YoutubeEmbed url={watch('url')} height="295px" />}
        <Box display="flex" justifyContent="right">
          <Button onClick={closeModal} sx={{ mt: '1.5em', mr: '0.625em' }}>
            CANCEL
          </Button>
          <Button sx={{ mt: '1.5em' }} variant="contained" type="submit">
            ADD VIDEO
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddTutorialModal;
