import { Box, Button, TextField, Typography } from '@mui/material';
import { Company } from '../../../../types';
import { Modal } from '../../../../Components';

type ResetPasswordModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  company: Company;
  onConfirm: () => void;
};

const ResetPasswordModal = (props: ResetPasswordModalProps) => {
  return (
    <Modal open={props.isOpen} onClose={props.closeModal}>
      <Box width="600px">
        <Typography variant="h2" fontSize="1.5em" fontWeight="bold">
          Reset Password
        </Typography>
        <Typography variant="body1" mt="1.8125em">
          Send a reset link to the email address nominated below to allow the
          user to reset their password.
        </Typography>
        <TextField
          value={props.company.primaryContact.emailAddress}
          disabled
          fullWidth
          sx={{ my: '2.25em' }}
        />
        <Box display="flex" justifyContent="right">
          <Button
            onClick={props.closeModal}
            sx={{ mt: '1.5em', mr: '0.625em' }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              props.onConfirm();
              props.closeModal();
            }}
            sx={{ mt: '1.5em' }}
            variant="contained"
          >
            SEND RECOVERY LINK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResetPasswordModal;
