import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { Company } from '../../../types';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { Roles, routePaths } from '../../../../constants';
import { useState } from 'react';
import { useJwtClaims } from '../../../hooks';

const StyledTableCell = styled(TableCell)<TableCellProps>(() => ({
  maxWidth: '150px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

type LinkedCompaniesModalProps = {
  open: boolean;
  onClose: () => void;
  companies: Company[];
  surveyName?: string;
};

const LinkedCompaniesModal = ({
  open,
  onClose,
  companies,
  surveyName,
}: LinkedCompaniesModalProps) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const claims = useJwtClaims();

  const rows = search
    ? companies.filter((company) =>
        company.name.toLowerCase().includes(search.toLowerCase()),
      )
    : companies;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      scroll="paper"
      fullWidth
      maxWidth={'md'}
    >
      <Box minHeight="420px">
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: '1.25em',
            top: '1.0625em',
            height: '1.1em',
            width: '1.1em',
            fontSize: '1em',
          }}
        >
          <CloseIcon sx={{ height: '1em', width: '1em', fontSize: '1em' }} />
        </IconButton>
        <DialogTitle
          sx={{ fontSize: '2em', fontWeight: 700, px: '50px', pt: '47px' }}
        >
          {claims.role === Roles.PARTNER
            ? 'Connected companies'
            : 'Companies Using This Survey'}
        </DialogTitle>
        <DialogContent sx={{ px: '50px', pb: '47px' }}>
          {claims.role === Roles.PARTNER && (
            <Typography
              data-testid="survey-count"
              variant="h3"
              fontSize="1.5em"
              fontWeight="bold"
              mb={3}
            >
              {surveyName}
            </Typography>
          )}
          <TextField
            size="small"
            name="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            fullWidth
            placeholder="Company Name"
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Company Name</StyledTableCell>
                  <StyledTableCell>Email Address</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((company) => (
                  <TableRow key={company.id}>
                    <StyledTableCell>{company.name}</StyledTableCell>
                    <StyledTableCell>
                      {company.primaryContact.emailAddress}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Link
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate(
                            routePaths.companies.TO_PROFILE(company.identifier),
                          );
                        }}
                      >
                        View Profile
                      </Link>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default LinkedCompaniesModal;
