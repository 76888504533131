import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import React from 'react';
import { ActorRefFrom } from 'xstate';
import { companyMachine } from './machine';
import { useInterpret } from '@xstate/react';

export type CompanyContext = {
  companyService: ActorRefFrom<typeof companyMachine>;
};
export const CompanyContext = React.createContext({} as CompanyContext);

const CompanyPage = () => {
  const companyService = useInterpret(companyMachine);

  return (
    <CompanyContext.Provider value={{ companyService }}>
      <Box>
        <Outlet />
      </Box>
    </CompanyContext.Provider>
  );
};

export default CompanyPage;
