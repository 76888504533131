import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useActor, useMachine } from '@xstate/react';
import { manageCompaniesMachine } from './machine';
import { ProductOwner } from '../../../../types';
import { useContext, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ProductOwnerPageContext } from '../..';
import { CustomSelect } from '../../../../common';

const StyledTableCell = styled(TableCell)<TableCellProps>(() => ({
  fontSize: '0.875em',
  maxWidth: '150px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

type ManageCompaniesModalProps = {
  open: boolean;
  onClose: () => void;
  productOwner: ProductOwner;
  onDone: () => void;
};

const ManageCompaniesModal = (props: ManageCompaniesModalProps) => {
  const productOwnerService = useContext(ProductOwnerPageContext);
  const [productOwnerCurrent] = useActor(productOwnerService);
  const [state, send, service] = useMachine(
    manageCompaniesMachine(props.productOwner),
  );

  useEffect(() => {
    send({
      type: 'SET_DISPLAY_LIST',
      data: productOwnerCurrent.context.companies.filter((company) =>
        state.context.selectedCompanies.includes(company.identifier),
      ),
    });
    send({
      type: 'LOAD_COMPANIES',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOwnerCurrent.context.companies, send]);

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      if (state.matches('done')) {
        props.onDone();
        props.onClose();
      }
    });

    return subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  const handleRemove = (id: string) => {
    send({
      type: 'SET_SELECTED_COMPANIES',
      data: state.context.selectedCompanies.filter((item) => item !== id),
    });
    send({
      type: 'SET_DISPLAY_LIST',
      data: state.context.displayList.filter(
        (company) => company.identifier !== id,
      ),
    });
  };

  const handleChange = (data: string[]) => {
    const toBeDisplayed = productOwnerCurrent.context.companies.filter(
      (option) =>
        [...data, ...state.context.selectedCompanies].includes(
          option.identifier,
        ),
    );
    send({
      type: 'SET_SELECTED_COMPANIES',
      data: [...data, ...state.context.selectedCompanies],
    });
    send({ type: 'SET_DISPLAY_LIST', data: toBeDisplayed });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <IconButton
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: '1.25em',
          top: '1.0625em',
          height: '1.1em',
          width: '1.1em',
          fontSize: '1em',
        }}
      >
        <CloseIcon sx={{ height: '1em', width: '1em', fontSize: '1em' }} />
      </IconButton>
      <Box
        sx={{
          py: ['33px', '35px', '37px'],
          px: ['35px', '37px', '39px'],
          borderRadius: '0.25rem',
          fontSize: ['12px', '14px', '16px'],
          outline: 'none',
        }}
      >
        <DialogTitle
          variant="h2"
          fontSize="1.625em"
          fontWeight="bold"
          sx={{ p: 0, mb: '21px' }}
        >
          Connected Companies
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <FormControl sx={{ width: '100%', mb: '16px', mt: '10px' }}>
            <CustomSelect
              placeholder="Start typing company name to search"
              options={state.context.poCompanies}
              value={state.context.selectedCompanies}
              getOptionLabel={(company) => company.name}
              getOptionValue={(company) => company.identifier}
              onChange={handleChange}
            />
          </FormControl>
          {state.context.displayList.length > 0 && (
            <TableContainer sx={{ maxHeight: '430px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Company Name</StyledTableCell>
                    <StyledTableCell>Email Address</StyledTableCell>
                    <StyledTableCell>Remove?</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.context.displayList.map((company) => (
                    <TableRow key={company.identifier}>
                      <StyledTableCell>{company.name}</StyledTableCell>
                      <StyledTableCell>
                        {company.primaryContact.emailAddress}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          sx={{
                            zIndex: 1,
                          }}
                          onClick={() => handleRemove(company.identifier)}
                        >
                          <DeleteIcon color="primary" />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="right">
            <Button onClick={props.onClose} sx={{ mt: '1.5em', mr: '0.625em' }}>
              CANCEL
            </Button>
            <Button
              sx={{ mt: '1.5em' }}
              variant="contained"
              onClick={() => {
                send({ type: 'UPDATE_PRODUCT' });
              }}
            >
              SAVE CHANGES
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ManageCompaniesModal;
