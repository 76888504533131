import { Modal } from '../../../Components';
import { Box, Button, Typography } from '@mui/material';

type DeleteTutorialProps = {
  isOpen: boolean;
  closeModal: () => void;
  onDelete: () => void;
};

const DeleteTutorialModal = ({
  isOpen,
  closeModal,
  onDelete,
}: DeleteTutorialProps) => {
  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box maxWidth="600px">
        <Typography variant="h2" fontSize="1.625em" fontWeight="bold" mb="29px">
          Delete Video Link
        </Typography>
        <Typography variant="body1" fontSize="1em">
          Are you sure you want to remove this video link? This will only remove
          the video from this list of tutorials, and not from the video channel
          itself.
        </Typography>

        <Box display="flex" justifyContent="right">
          <Button
            onClick={closeModal}
            color="error"
            sx={{ mt: '1.5em', mr: '19px' }}
          >
            NO, KEEP VIDEO
          </Button>
          <Button
            onClick={onDelete}
            color="error"
            sx={{ mt: '1.5em' }}
            variant="contained"
            type="submit"
          >
            YES, REMOVE VIDEO
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteTutorialModal;
