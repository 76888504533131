import * as React from 'react';
import { useActor } from '@xstate/react';
import { AuthenticationContext } from '..';
import { ReactNoop } from '../_react';
import LoginForm from './LoginForm';
import LoginFormCall from './LoginFormCall';
import LoginFormError from './LoginFormError';
import PasswordRecoveryForm from './PasswordRecoveryForm';
import RegisterUserForm from './RegisterUserForm';
import UserRegistered from './RegisterUserForm/UserRegistered';
import Authenticated from '../Authenticated';

const AdminRoute = () => {
  const authenticationContext = React.useContext(AuthenticationContext);
  const [current] = useActor(authenticationContext.authenticationService);

  return (
    <>
      {current.matches('is_authenticated') ? <Authenticated /> : <ReactNoop />}
      {current.matches('not_authenticated') ? <LoginForm /> : <ReactNoop />}
      {current.matches('login_call') ? <LoginFormCall /> : <ReactNoop />}
      {current.matches('error_with_login') ? <LoginFormError /> : <ReactNoop />}
      {current.matches('reset_password') ? (
        <PasswordRecoveryForm />
      ) : (
        <ReactNoop />
      )}
      {current.matches('reset_password_start') ? (
        <PasswordRecoveryForm />
      ) : (
        <ReactNoop />
      )}
      {current.matches('reset_password_success') ? (
        <PasswordRecoveryForm />
      ) : (
        <ReactNoop />
      )}
      {current.matches('reset_password_error') ? (
        <PasswordRecoveryForm />
      ) : (
        <ReactNoop />
      )}
      {current.matches('register_user') ? <RegisterUserForm /> : <ReactNoop />}
      {current.matches('register_user_start') ? (
        <RegisterUserForm />
      ) : (
        <ReactNoop />
      )}
      {current.matches('register_user_success') ? (
        <UserRegistered />
      ) : (
        <ReactNoop />
      )}
      {current.matches('register_user_error') ? (
        <RegisterUserForm />
      ) : (
        <ReactNoop />
      )}
    </>
  );
};

export default AdminRoute;
