import v from 'validator';

export const isEmail = (email: string) => {
  return v.isEmail(email);
};

export const isSecurePassword = (password: string) => {
  const options: v.StrongPasswordOptions & { returnScore?: false } = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  };

  return v.isStrongPassword(password, options);
};
