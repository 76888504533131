import { SurveyC } from '../codecs';

import CompanySurvey from './CompanySurvey';
import IndividualSurvey from './IndividualSurvey';

export type Props = {
  survey: SurveyC;
};

const SurveyType = (props: Props) => {
  return (
    <>
      {props.survey.isAuthRequired === true ? (
        <IndividualSurvey survey={props.survey} />
      ) : (
        <CompanySurvey survey={props.survey} />
      )}
    </>
  );
};

export default SurveyType;
