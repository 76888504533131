import {
  FormControl,
  Link,
  LinkProps,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { ReactNode, useCallback, useContext, useState } from 'react';
import { PartnerPageContext } from '..';
import { useActor, useSelector } from '@xstate/react';
import { Company, Partner, Product } from '../../../types';
import { useJwtClaims } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { Roles, routePaths } from '../../../../constants';
import LinkedCompaniesModal from './LinkedCompaniesModal';
import DeletePartnerModal from './DeletePartnerModal';
import EditPartnerModal from './EditPartnerModal';
import { Select } from '../../../common';
import SearchField from '../../../common/SearchField';

const tableHeadColumns = [
  'Partner',
  'Email Address',
  'Connected Products',
  'Connected Companies',
  'Actions',
];

type CustomTableRowProps = {
  partner: Partner;
  send: (event: any) => void;
};

type CustomTableCellProps = {
  children: ReactNode;
  sx?: SxProps;
};

type FilterHandler = (
  prop: 'term' | 'productId' | 'companyId',
  data: string,
) => void;

type ProductSelectProps = {
  products: Product[];
  onChange: FilterHandler;
};

const ProductSelect = ({ products, onChange }: ProductSelectProps) => {
  const service = useContext(PartnerPageContext);
  const productId = useSelector(service, (state) => state.context.productId);

  return (
    <FormControl
      fullWidth
      sx={{
        '& .MuiInputBase-root': {
          height: '2.375em',
        },
      }}
    >
      <Select
        defaultMenuItemLabel="Any connected product"
        selectedValue={productId}
        values={products}
        onChange={(data) => {
          onChange('productId', data as string);
        }}
      />
    </FormControl>
  );
};

type CompanySelectProps = {
  companies: Company[];
  onChange: FilterHandler;
};

const CompanySelect = ({ companies, onChange }: CompanySelectProps) => {
  const service = useContext(PartnerPageContext);
  const companyId = useSelector(service, (state) => state.context.companyId);

  return (
    <FormControl
      fullWidth
      sx={{
        '& .MuiInputBase-root': {
          height: '2.375em',
        },
      }}
    >
      <Select
        defaultMenuItemLabel="Any company"
        selectedValue={companyId}
        values={companies}
        onChange={(data) => {
          onChange('companyId', data as string);
        }}
      />
    </FormControl>
  );
};

const CustomTableCell = ({ children, sx }: CustomTableCellProps) => (
  <TableCell sx={{ verticalAlign: 'text-top', ...sx }}>{children}</TableCell>
);

const StyledLink = styled(Link)<LinkProps>(() => ({
  cursor: 'pointer',
}));

const CustomTableRow = ({ partner, send }: CustomTableRowProps) => {
  const navigate = useNavigate();
  const claims = useJwtClaims();

  const [linkedCompaniesOpen, setLinkedCompaniesOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <TableRow key={partner.id}>
      {linkedCompaniesOpen && (
        <LinkedCompaniesModal
          partner={partner}
          open={linkedCompaniesOpen}
          onClose={() => setLinkedCompaniesOpen(false)}
        />
      )}
      {claims.role === Roles.GLOBAL_ADMIN && (
        <DeletePartnerModal
          isOpen={deleteModalOpen}
          closeModal={() => setDeleteModalOpen(false)}
          partner={partner}
          onDone={() => send({ type: 'RELOAD' })}
        />
      )}
      {claims.role === Roles.GLOBAL_ADMIN && editModalOpen && (
        <EditPartnerModal
          isOpen={editModalOpen}
          closeModal={() => setEditModalOpen(false)}
          partner={partner}
          onDone={() => send({ type: 'RELOAD' })}
          onDelete={() => setDeleteModalOpen(true)}
        />
      )}
      <CustomTableCell>{partner.name}</CustomTableCell>
      <CustomTableCell>{partner.user.emailAddress}</CustomTableCell>
      <CustomTableCell>
        <Stack>
          {partner.products.map((product) => (
            <Typography key={product.identifier} sx={{ fontSize: '14px' }}>
              {product.name}
            </Typography>
          ))}
        </Stack>
      </CustomTableCell>
      <CustomTableCell>
        <StyledLink onClick={() => setLinkedCompaniesOpen(true)}>
          {partner.companies.length} companies
        </StyledLink>
      </CustomTableCell>
      <CustomTableCell>
        <Stack direction="row" spacing={2}>
          <StyledLink
            onClick={() =>
              navigate(routePaths.PARTNERS.TO_PROFILE(partner.identifier))
            }
          >
            View Profile
          </StyledLink>
          {claims.role === Roles.GLOBAL_ADMIN && (
            <>
              <StyledLink onClick={() => setEditModalOpen(true)}>
                Edit
              </StyledLink>
              <StyledLink onClick={() => setDeleteModalOpen(true)}>
                Delete
              </StyledLink>
            </>
          )}
        </Stack>
      </CustomTableCell>
    </TableRow>
  );
};

const PartnerTable = () => {
  const service = useContext(PartnerPageContext);
  const [state, send] = useActor(service);
  const handleFilter: FilterHandler = useCallback(
    (prop, data) => {
      send({
        type: 'FILTER',
        prop,
        data,
      });
    },
    [send],
  );

  const handleSearchFieldChange: (data: string) => void = useCallback(
    (data) => {
      handleFilter('term', data);
    },
    [handleFilter],
  );

  return (
    <Paper sx={{ px: '3em', py: '1.9375em' }}>
      <Stack direction="row" spacing="14px">
        <SearchField
          onChange={handleSearchFieldChange}
          label={'Search name of partner'}
        />
        <ProductSelect
          products={state.context.products}
          onChange={handleFilter}
        />
        <CompanySelect
          companies={state.context.companies}
          onChange={handleFilter}
        />
      </Stack>
      <TableContainer>
        <Table sx={{ fontSize: '0.875em' }}>
          <TableHead>
            <TableRow>
              {tableHeadColumns.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.context.partners.map((partner) => (
              <CustomTableRow
                key={partner.identifier}
                partner={partner}
                send={send}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          fontSize: '0.75em',
          [`& .MuiTablePagination-selectLabel, 
              .MuiTablePagination-displayedRows`]: { fontSize: '12px' },
        }}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={state.context.count}
        rowsPerPage={state.context.rowsPerPage}
        onRowsPerPageChange={(e) => {
          send({
            type: 'ROWS_PER_PAGE_CHANGE',
            data: parseInt(e.target.value),
          });
        }}
        page={state.context.currentPage}
        onPageChange={(_, v) => {
          send({
            type: 'PAGE_CHANGE',
            data: v,
          });
        }}
      />
    </Paper>
  );
};

export default PartnerTable;
