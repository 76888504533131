import axios from 'axios';
import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import * as constants from '../../../../../../constants';
import {
  AddSurveysToCompany,
  SurveyWithCompanies,
  SurveyWithProduct,
} from '../../../../../types';
import { getAuthHeaders } from '../../../../../../authentication';

export type ContextT = {
  identifier: string;
  surveys?: (SurveyWithProduct & SurveyWithCompanies)[];
};
type LoadCompanySurveysT = DoneInvokeEvent<
  (SurveyWithProduct & SurveyWithCompanies)[]
>;
type LinkSurveysT = {
  type: 'LINK_SURVEY';
  data: { id: string }[];
};

type ReloadCompanyT = {
  type: 'RELOAD_COMPANY';
};

type EventT = LoadCompanySurveysT | ReloadCompanyT | LinkSurveysT;

export const addSurveyMachine = (identifier: string) =>
  createMachine<ContextT, EventT>(
    {
      id: identifier,
      predictableActionArguments: true,
      preserveActionOrder: true,
      context: {
        identifier: identifier,
      },
      initial: 'load_company_surveys_start',
      states: {
        load_company_surveys_start: {
          invoke: {
            src: 'loadCompanySurveys',
            onDone: {
              target: 'load_company_surveys_success',
              actions: 'setCompanySurveys',
            },
            onError: {
              target: 'load_company_surveys_error',
            },
          },
        },
        load_company_surveys_success: {
          on: {
            RELOAD_COMPANY: {
              target: 'load_company_surveys_start',
            },
            LINK_SURVEY: {
              target: 'link_survey',
            },
          },
        },
        load_company_surveys_error: {},
        link_survey: {
          invoke: {
            src: 'linkSurveys',
            onDone: {
              target: 'link_done',
            },
          },
        },
        link_done: {
          after: {
            0: {
              target: 'load_company_surveys_start',
            },
          },
        },
      },
    },
    {
      services: {
        loadCompanySurveys: async (context: ContextT, event: EventT) => {
          const response = await axios.get<AddSurveysToCompany[]>(
            constants.apiPaths.products.GET_COMPANY_PRODUCT_SURVEYS(
              context.identifier,
            ),
            getAuthHeaders(),
          );

          const result = response.data.flatMap((product) =>
            product.surveys.map((survey) => ({
              ...survey,
              product: { id: product.id, name: product.name },
            })),
          );

          return result;
        },
        linkSurveys: async (context: ContextT, event: EventT) => {
          await axios.put(
            constants.apiPaths.companies.SURVEYS(context.identifier),
            (event as LinkSurveysT).data,
            getAuthHeaders(),
          );
        },
      },
      actions: {
        setCompanySurveys: assign((_, event: EventT) => {
          return {
            surveys: (event as LoadCompanySurveysT).data,
          };
        }),
      },
    },
  );
