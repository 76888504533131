import { createMachine } from 'xstate';
import axios from 'axios';
import * as constants from '../../../../../../../constants';
import { getAuthHeaders } from '../../../../../../../authentication';
import { Company } from '../../../../../../types';

export type ContextT = {
  company: Company;
};

type ConfirmDeleteCompanyE = {
  type: 'CONFIRM_DELETE_COMPANY';
};
type EventE = ConfirmDeleteCompanyE;

const delete_company = (c: ContextT, e: EventE) =>
  axios.delete(
    constants.apiPaths.companies.COMPANY(c.company.identifier),
    getAuthHeaders(),
  );

export const deleteCompanyMachine = (company: Company) =>
  createMachine<ContextT, EventE>({
    predictableActionArguments: true,
    id: `deleteMachine-${company.identifier}`,
    context: {
      company: company,
    },
    initial: 'main',
    states: {
      main: {
        on: {
          CONFIRM_DELETE_COMPANY: {
            target: 'start',
          },
        },
      },
      start: {
        invoke: {
          src: delete_company,
          onDone: {
            target: 'done',
          },
          onError: {
            target: 'error',
          },
        },
      },
      done: {
        type: 'final',
      },
      error: {},
    },
  });
