import { Modal } from '../../../../../../Components';
import { Box, Button, Typography } from '@mui/material';
import { useMachine } from '@xstate/react';
import { deleteCompanyMachine } from './machine';
import { Company } from '../../../../../../types';

type DeleteCompanyModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  company: Company;
  onDone: () => void;
};

const DeleteCompanyModal = (props: DeleteCompanyModalProps) => {
  const [, send, service] = useMachine(deleteCompanyMachine(props.company));

  const exitModal = () => {
    props.closeModal();
  };

  service.onDone(() => {
    props.onDone();
    service.stop();
  });

  return (
    <Modal open={props.isOpen} onClose={exitModal}>
      <Box width="600px">
        <Typography variant="h2" fontSize="1.5em" fontWeight="bold">
          Delete Company
        </Typography>
        <Typography variant="body1" mt="1.8125em">
          Are you sure you want to delete this company? The company
          representative will lose access to their survey data, and you will not
          be able to recover the account if so.
        </Typography>
        <Box display="flex" justifyContent="right">
          <Button
            onClick={exitModal}
            sx={{ mt: '1.5em', mr: '0.625em' }}
            color="error"
          >
            NO, KEEP COMPANY
          </Button>
          <Button
            onClick={() => {
              send({ type: 'CONFIRM_DELETE_COMPANY' });
              props.closeModal();
            }}
            sx={{ mt: '1.5em' }}
            variant="contained"
            color="error"
          >
            YES, DELETE COMPANY
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteCompanyModal;
