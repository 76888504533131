import {
  Link as MuiLink,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Typography,
  Stack,
  FormControl,
} from '@mui/material';
import React, { createContext, useCallback, useState } from 'react';
import { useActor, useInterpret } from '@xstate/react';
import { ReactNoop } from '../../../../../_react';
import { Roles, routePaths } from '../../../../../../constants';
import { useNavigate } from 'react-router-dom';
import { Company, Partner, Product } from '../../../../../types';
import { useJwtClaims } from '../../../../../hooks';
import { companyMachine } from './machine';
import { InterpreterFrom } from 'xstate';
import EditCompanyModal from './EditCompanyModal';
import DeleteCompanyModal from './DeleteCompanyModal';
import ManageCompaniesModal from '../../ManageCompaniesModal';
import { Select } from '../../../../../common';
import SearchField from '../../../../../common/SearchField';

type ProductSelectProps = {
  selectedProductIdentifier: string;
  products: Product[];
  onChange: (data: string) => void;
};

const ProductSelect = ({
  products,
  selectedProductIdentifier,
  onChange,
}: ProductSelectProps) => {
  return (
    <FormControl
      fullWidth
      sx={{
        '& .MuiInputBase-root': {
          height: '2.375em',
        },
        width: { lg: '30%', md: '100%' },
      }}
    >
      <Select
        defaultMenuItemLabel="Any connected product"
        selectedValue={selectedProductIdentifier}
        values={products}
        onChange={(data) => {
          onChange(data as string);
        }}
      />
    </FormControl>
  );
};

const StyledTableCell = styled(TableCell)<TableCellProps>(() => ({
  borderBottom: '1px solid rgba(0,0,0,0.12)',
  fontSize: '0.875em',
}));

type CompanyTableRowProps = {
  company: Company;
  send: (event: any) => void;
};

const CompanyTableRow = (props: CompanyTableRowProps): JSX.Element => {
  const navigate = useNavigate();
  const claims = useJwtClaims();

  const [editModalOpen, setModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteOpen] = useState<boolean>(false);

  const _email =
    props.company.primaryContact.emailAddress.length >= 25
      ? `${props.company.primaryContact.emailAddress.substring(0, 22)}...`
      : props.company.primaryContact.emailAddress.substring(0, 25);

  return (
    <>
      {editModalOpen ? (
        <EditCompanyModal
          isOpen={editModalOpen}
          closeModal={() => setModalOpen(false)}
          company={props.company}
          onDone={() => {
            props.send({ type: 'REFRESH_COMPANIES_TABLE' });
          }}
        />
      ) : (
        <ReactNoop />
      )}
      <DeleteCompanyModal
        isOpen={deleteModalOpen}
        closeModal={() => setDeleteOpen(false)}
        company={props.company}
        onDone={() => props.send({ type: 'REFRESH_COMPANIES_TABLE' })}
      />
      <TableRow key={props.company.identifier}>
        <StyledTableCell>{props.company.name}</StyledTableCell>
        <StyledTableCell
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {_email}
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            {props.company.products.map((product) => (
              <Typography key={product.id} sx={{ fontSize: '14px' }}>
                {product.name}
              </Typography>
            ))}
          </Stack>
        </StyledTableCell>
        <StyledTableCell>{props.company.surveys.length}</StyledTableCell>
        <StyledTableCell>
          {props.company.surveys.filter((s) => s.isActive === true).length}
        </StyledTableCell>
        <StyledTableCell>
          <Stack direction="row" spacing={2}>
            <MuiLink
              onClick={() =>
                navigate(
                  routePaths.companies.TO_PROFILE(props.company.identifier),
                )
              }
              sx={{ cursor: 'pointer' }}
            >
              View Profile
            </MuiLink>
            {claims.role === Roles.GLOBAL_ADMIN && (
              <>
                <MuiLink
                  onClick={() => setModalOpen(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  Edit
                </MuiLink>
                <MuiLink
                  onClick={() => setDeleteOpen(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  Delete
                </MuiLink>
              </>
            )}
          </Stack>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

type CompanyTableProps = {
  partner: Partner;
  service: any;
};

export const ConnectedCompaniesContext = createContext(
  {} as InterpreterFrom<typeof companyMachine>,
);

const CompanyTable = ({ partner, service }: CompanyTableProps) => {
  const companyService = useInterpret(companyMachine(partner.identifier));
  const [current, send] = useActor(companyService);
  const [manageOpen, setManageOpen] = useState(false);
  const claims = useJwtClaims();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) =>
    send({ type: 'CHANGE_ROWS_PER_PAGE', data: parseInt(event.target.value) });

  const handleFilter: (data: string) => void = useCallback(
    (data) => {
      send({ type: 'ENTER_SEARCH', data });
    },
    [send],
  );

  return (
    <ConnectedCompaniesContext.Provider value={companyService}>
      <Paper sx={{ mt: '1.25rem', p: '2.125em' }}>
        <Stack direction="row" justifyContent="space-between" mb="20px">
          {claims.role === Roles.GLOBAL_ADMIN && manageOpen && (
            <ManageCompaniesModal
              isOpen={manageOpen}
              closeModal={() => setManageOpen(false)}
              partner={partner}
              allowedCompanies={current.context.partnerCompanies ?? []}
              onDone={() => {
                send({ type: 'REFRESH_COMPANIES_TABLE' });
                service.send({ type: 'RELOAD_PARTNER' });
              }}
            />
          )}
          <Typography variant="h2" fontSize="1.625em" fontWeight="bold">
            Connected Companies
          </Typography>
          {claims.role === Roles.GLOBAL_ADMIN && (
            <Button variant="contained" onClick={() => setManageOpen(true)}>
              MANAGE CONNECTED COMPANIES
            </Button>
          )}
        </Stack>
        <Stack direction="row" spacing="14px" mb="1em">
          <SearchField onChange={handleFilter} label="Search name of company" />
          <ProductSelect
            selectedProductIdentifier={
              current.context.productIdentifier as string
            }
            products={partner.products}
            onChange={(data) => {
              send({ type: 'SELECT_PRODUCT', data });
            }}
          />
        </Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Company Name</StyledTableCell>
                <StyledTableCell sx={{ maxWidth: '100px' }}>
                  Email Address
                </StyledTableCell>
                <StyledTableCell>Connected Products</StyledTableCell>
                <StyledTableCell>Total Surveys</StyledTableCell>
                <StyledTableCell>Active Surveys</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {current.context.companies.length > 0 ? (
                current.context.companies.map((company) => (
                  <CompanyTableRow
                    company={company}
                    key={company.identifier}
                    send={send}
                  />
                ))
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={6}>
                    <Typography variant="body2" textAlign="center">
                      No companies are currently connected to this partner.
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {current.context.companies.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={current.context.count}
            rowsPerPage={current.context.rowsPerPage}
            page={current.context.currentPage}
            onPageChange={(_, v) =>
              send({ type: 'CHANGE_CURRENT_PAGE', data: v })
            }
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </ConnectedCompaniesContext.Provider>
  );
};

export default CompanyTable;
