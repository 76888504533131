import { useInterpret, useSelector } from '@xstate/react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { loadPartnerMachine } from './machine';
import { createContext, useEffect, useState } from 'react';
import { InterpreterFrom } from 'xstate';
import { Breadcrumbs as MuiBreadcrumbs, Link as MuiLink } from '@mui/material';
import { routePaths } from '../../../../../constants';

type BreadcrumbState = Array<{
  name: string;
  path: string;
}>;

export const PartnerProfileContext = createContext<{
  partnerProfileService: InterpreterFrom<typeof loadPartnerMachine>;
  setBreadcrumbs: (
    item: BreadcrumbState | ((item: BreadcrumbState) => BreadcrumbState),
  ) => void;
}>({
  partnerProfileService: {} as InterpreterFrom<typeof loadPartnerMachine>,
  setBreadcrumbs: () => {},
});

export const PartnerProfile = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const partnerProfileService = useInterpret(
    loadPartnerMachine(identifier as string),
  );
  const currentPartner = useSelector(
    partnerProfileService,
    (state) => state.context.partner,
  );
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbState>([
    { name: 'Partners', path: routePaths.PARTNERS.INDEX },
  ]);

  useEffect(() => {
    if (currentPartner) {
      setBreadcrumbs((prev) => {
        if (!prev.some((item) => item.name === currentPartner.name)) {
          return [
            ...prev,
            {
              name: currentPartner.name,
              path: routePaths.PARTNERS.TO_PROFILE(identifier as string),
            },
          ];
        }
        return prev;
      });
    }
  }, [currentPartner, identifier]);

  return (
    <PartnerProfileContext.Provider
      value={{ partnerProfileService, setBreadcrumbs }}
    >
      <MuiBreadcrumbs>
        {breadcrumbs.map((item, index) => (
          <MuiLink
            key={item.name}
            onClick={() => {
              if (index === 1) {
                setBreadcrumbs((prev) => {
                  const newArray = [...prev];
                  newArray.pop();
                  return newArray;
                });
              }
              navigate(item.path);
            }}
            textTransform="capitalize"
            underline={index === breadcrumbs.length - 1 ? 'none' : 'hover'}
            color={index === breadcrumbs.length - 1 ? 'inherit' : 'primary'}
            sx={{
              pointerEvents: index === breadcrumbs.length - 1 ? 'none' : 'auto',
            }}
          >
            {item.name}
          </MuiLink>
        ))}
      </MuiBreadcrumbs>
      <Outlet />
    </PartnerProfileContext.Provider>
  );
};

export default PartnerProfile;
